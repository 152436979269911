<template lang="pug">
.c-footer-download
  bl-text-av.mb-4(variation="subheading-18") Download aplikasinya sekarang!
  a.c-footer-download-link(
    :href="link"
    target="_blank"
    rel="noopener"
    :class="`c-footer-download-link--align-${forDesktop ? 'right' : 'center'}`"
  )
    BLBPicture(
      src="/images/3rd-parties/google-play-badge.png"
      :imgStyle="{ width: '190px' }"
      title="Download aplikasi via Google Play Store"
    )
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';

import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'FooterDownloadDesktop',
  components: {
    BLBPicture,
    BlTextAv,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    forDesktop: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-footer-download {
  &-link {
    display: block;
    &--align-center {
      text-align: center;
    }

    &--align-right {
      text-align: right;
    }
  }

}
</style>
