const MAX_BUYER_NOTE_LENGTH = 255;
const WAREHOUSE_MAX_HOUR = 1;
const WAREHOUSE_MAINTENANCE = false;

const DISABLED_KEYWORD = [
  'rokok',
  'mild',
  'surya',
  'kretek',
  'djarum',
  'sampoerna',
  'dji',
  'sam',
  'soe',
  'dji sam soe',
  'lucky',
  'strike',
  'dunhill',
  'vape',
  'tembakau',
  'filter',
];

const setTypeImage = (type, images) => {
  switch (type) {
    case 'thumbnail':
      return images.small_urls;
    case 'modal':
      return images.original_urls;
    default:
      return images.large_urls;
  }
};

const getProductImage = (product, type = 'thumbnail') => {
  let imageUrl;
  if (product && product.images) {
    imageUrl = setTypeImage(type, product.images);
    if (imageUrl && imageUrl.length) {
      return imageUrl[0];
    }
  }
  return '/images/gt/broken-image@2x-min.png';
};

const getProductPct = (product, round = false) => {
  try {
    const sellingPricePct = product.selling_price_percentage;
    const pct = sellingPricePct > 0 ? sellingPricePct : 0;
    return round ? Math.round(pct) : pct;
  } catch (e) {
    return 0;
  }
};

export {
  DISABLED_KEYWORD,
  MAX_BUYER_NOTE_LENGTH,
  WAREHOUSE_MAX_HOUR,
  WAREHOUSE_MAINTENANCE,
  getProductImage,
  getProductPct,
};
