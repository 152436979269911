<template>
  <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4316 7.00778C20.2885 7.00778 19.2336 6.62911 18.3867 5.99022C17.4154 5.25778 16.7174 4.18356 16.4709 2.94533C16.4096 2.63933 16.3769 2.32356 16.3736 2H13.108V10.9231L13.104 15.8107C13.104 17.1173 12.2532 18.2253 11.0736 18.6149C10.7314 18.728 10.3616 18.7816 9.97649 18.7604C9.48515 18.7336 9.02471 18.5851 8.62449 18.3458C7.77271 17.8364 7.19538 16.9122 7.1796 15.8551C7.15493 14.2029 8.49071 12.856 10.1416 12.856C10.4676 12.856 10.7805 12.9091 11.0736 13.0058V10.5671V9.69044C10.7645 9.64467 10.45 9.62089 10.1318 9.62089C8.32471 9.62089 6.63471 10.372 5.42649 11.7253C4.51338 12.748 3.9656 14.0527 3.88115 15.4209C3.77049 17.2182 4.42804 18.9267 5.7036 20.1873C5.89093 20.3724 6.08782 20.5442 6.2936 20.7027C7.38715 21.5438 8.72404 22 10.132 22C10.45 22 10.7647 21.9764 11.0738 21.9307C12.3892 21.7358 13.6027 21.1338 14.5605 20.1869C15.7374 19.0238 16.3876 17.4796 16.3945 15.836L16.3776 8.53733C16.9389 8.97044 17.5529 9.32889 18.2116 9.60689C19.2363 10.0391 20.3227 10.2582 21.4407 10.258V7.88689V7.00711C21.4416 7.00778 21.4323 7.00778 21.4316 7.00778Z" fill="#141414"/>
</svg>

</template>

<script>
export default {
  name: 'IconTiktok'
}
</script>
