<template lang="pug">
  .tentang-kami-page-visi-misi
    .tentang-kami-page-visi-misi__content.container-visi-misi 
      BlFlexContainerMv.flex-container-visi-misi.mt-3
        .tentang-kami-page-visi-misi-item-container
          BlCardMv.visi-misi-card(:shadow="false" )
            BLBPicture(
              :lazy="false"
              :webp="false"
              img-class="visi-misi-img__pic"
              src="/images/tentang-kami/visi.png"
              title="Visi Mitra Bukalapak"
              :imgStyle="{ 'border-radius':'12px 12px 0 0', width: '100%' }"
            )
            BlCardMv.visi-misi-title-card.pv-4.ph-12(:shadow="false")
              BlTextAv.visi-title(weight="bold" color="inverse" variation="body-16") Visi
            BlTextAv.visi-misi-description.pb-20(variation="heading-24" weight="bold" color="inverse") Melalui Mitra Bukalapak, kami ingin mentransformasi warung dan kios konvensional melalui digitalisasi, sesuai dengan motto #WarungNaikKelas di seluruh Indonesia
        .tentang-kami-page-visi-misi-item-container
          BlCardMv.visi-misi-card-other(:shadow="false")
            BLBPicture(
              :lazy="false"
              :webp="false"
              img-class="visi-misi-img__pic"
              src="/images/tentang-kami/misi.png"
              title="Misi Mitra Bukalapak"
              :imgStyle="{ 'border-radius':'12px 12px 0 0', width: '100%' }"
            )
            BlCardMv.visi-misi-title-card-other.pv-4.ph-12(:shadow="false")
              BlTextAv.misi-title(weight="bold" color="inverse" variation="body-16") Misi
            BlTextAv.visi-misi-description.pb-20(variation="heading-24" weight="bold" color="inverse") Menjangkau area yang belum terjangkau oleh digitalisasi serta memberdayakan lebih dari 17 juta warung dan agen individu di seluruh Indonesia
            
</template>

<script>
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BlCardMv from '@bukalapak/bazaar-mweb/dist/components/BlCardMv';
import BLBPicture from "~/components/shared/base/BLBPicture.vue";
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';

export default {
  name: "TentangKamiPageVisiMisi",
  components: {
    BlFlexItemAv,
    BlFlexContainerMv,
    BlCardMv,
    BLBPicture,
    BlTextAv,
  },
}

</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$visi-misi-title-card-width: 56px;
$visi-misi-title-card-top: -14px;
$visi-misi-title-card-border-radius: 100px;

.flex-container-visi-misi {
  gap: $space-12;
  flex-direction: row;
  justify-content: space-between;
}

.visi-misi-card {
  border-radius: $space-12;
  background-color: $background-high;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visi-misi-card-other {
  border-radius: $space-12;
  background-color: $background-playful-01;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visi-misi-description {
  padding-left: $space-40;
  padding-right: $space-40;
  line-height: $line-height-28;
  letter-spacing: $space-0;
  text-align: left;
}

.visi-title {
  line-height: $line-height-20;
  letter-spacing: $space-0;
  text-align: left;
}

.visi-misi-title-card {
  width: $visi-misi-title-card-width;
  height: $font-size-28;
  left: $space-40;
  border-radius: $visi-misi-title-card-border-radius;
  top: $visi-misi-title-card-top;
  background-color: $background-playful-01
}

.visi-misi-title-card-other {
  width: $visi-misi-title-card-width;
  height: $font-size-28;
  left: $space-40;
  border-radius: $visi-misi-title-card-border-radius;
  top: $visi-misi-title-card-top;
  background-color: $background-high;
}

.misi-title {
  background-color: $background-high;
  line-height: $line-height-20;
  letter-spacing: $space-0;
  text-align: left;
}

.tentang-kami-page-visi-misi-item-container {
  width: 100%;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .flex-container-visi-misi {
    flex-direction: column;
  }

  .visi-misi-description {
    font-size: $font-size-16;
  }

  .visi-title {
    font-size: $font-size-12;
    line-height: $space-16;
  }

  .misi-title {
    font-size: $font-size-12;
    line-height: $space-16;
  }

  .visi-misi-title-card {
    width: $space-48;
    height: $space-24;
  }

  .visi-misi-title-card-other {
    width: $space-48;
    height: $space-24;
  }
}
</style>
