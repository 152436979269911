import axios from '../axios-instance';

export const retrieveCategories = parentId => {
  const params = {};
  if (parentId) {
    params.parent_id = parentId;
  }
  return axios.$get('/api/general-trade/categories', { params });
};

export const retrieveProducts = (options = {}) => axios.$get('/api/general-trade/products', { params: options });
