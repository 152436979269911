<template lang="pug">
nuxt
</template>

<script>
import canonical from '~/mixins/canonical';
import tracking from '~/mixins/middleware/tracking';

export default {
  mixins: [canonical, tracking],
  head() {
    return {
      htmlAttrs: {
        lang: 'id',
      },
      link: [this.linkCanonical],
    };
  },
  mounted() {
    if (process.server) return;
    this.$store.dispatch('applyUserAgent');
  },
};
</script>

<style lang="scss">
html, body, #__nuxt, #__layout {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
}
</style>
