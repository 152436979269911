export default {
  article: {
    article_header: { selector: 'article_header', type: 'id' },
    link_facebook: { selector: 'link_facebook', type: 'id' },
    link_instagram: { selector: 'link_instagram', type: 'id' },
    link_gplay: { selector: 'link_google_play', type: 'id' },
    link_category_akademi_mitra: { selector: 'category_akademi-mitra', type: 'id' },
    article_header_logo: { selector: 'header_logo', type: 'data-test-id' }
  },
  landing: { landing_header: { selector: 'landing_header', type: 'id' } }
};
