export const state = () => ({
  landing: false,
  faq: false,
  homepage: false,
  article: false,
});

export const getters = {
  isLandingClosed: s => s.landing,
  isFAQClosed: s => s.faq,
  isTermsClosed: s => s.terms,
  isHomepageClosed: s => s.homepage,
  isArticleClosed: s => s.article,
};

export const mutations = {
  setCloseState(s, { page, isClosed }) {
    s[page] = isClosed;
  },
};

export const actions = {
  setCloseState({ commit }, { page, isClosed }) {
    commit('setCloseState', { page, isClosed });
  },
};
