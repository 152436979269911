<template lang="pug">
.section-bottom
  .footer-download.mv-24
    BlFlexContainerMv(direction="column" justify-content="center" align-items="center")
      BlFlexItemAv
        BlTextAv.mv-12(variation="body-16") Download aplikasinya sekarang
      BlFlexItemAv
        a.u-txt--center(
          :href="downloadLink"
          target="_blank"
          @click="trackEventClick('download_playstore_aplikasi_mitra')"
          rel="noopener"
        )
          BLBPicture.mv-12(
            src="/images/3rd-parties/google-play-button.png"
            :imgStyle="{ width: '135px' }"
            title="Tombol download aplikasi via Google Play Store"
          )
      BlFlexItemAv.mt-24
        a.ph-8(
          :href="`${findUsLink.facebook}?utm_source=homepage`"
          target="_blank"
          rel="noopener"
          aria-label="Tombol Facebook Mitra Bukalapak"
        )
          BlIconAv(name="ico_facebook")
        a.ph-8(
          :href="`${findUsLink.instagram}?utm_source=homepage`"
          target="_blank"
          rel="noopener"
          aria-label="Tombol Instagram Mitra Bukalapak"
        )
          BlIconAv(name="ico_instagram")

  FooterSeo#footer-links(variant="mobile" :content="pageDescription")

</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';
import { BlIconAv } from '@bukalapak/bazaar-visual';
import { mapGetters, mapState } from 'vuex';
import BLBPicture from '~/components/shared/base/BLBPicture';
import FooterSeo from '~/components/FooterSeo';
import tracker from '~/mixins/tracker';

export default {
  name: 'MainFooter',
  components: {
    BlFlexItemAv,
    BlTextAv,
    BlFlexContainerMv,
    BlIconAv,
    BLBPicture,
    FooterSeo,
  },
  mixins: [tracker],
  computed: {
    ...mapState('seo-props', ['seoProps']),
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
      findUsLink: 'global/findUsLink',
    }),
    downloadLink() {
      const params = {
        campaign: 'beranda-footer',
        label: 'landing',
        deeplink: process.env.WEB_HOST,
      };
      const urlData = new URL(this.downloadAppLink);
      Object.entries(params).forEach(([key, value]) => {
        urlData.searchParams.append(key, value);
      });
      return urlData.href;
    },
    pageDescription() {
      return this.seoProps?.page_description || '';
    }
  },
};
</script>

<style lang="scss" scoped></style>
