import r from '~/utils/render-utils';

const PageGuideSaldoMitra = () => import(/* webpackChunkName: 'guide-saldo-mitra' */ '~/pages/Guide/SaldoMitra');

const guide = [
  {
    path: '/guide/saldo-mitra',
    name: 'guide_saldo_mitra',
    component: r(PageGuideSaldoMitra),
  },
];

export default guide;
