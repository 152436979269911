<template lang="pug">
  .c-copyright.u-align-center.u-pad-v--4
    .u-txt--tiny.u-fg--charcoal
      span.c-copyright__links
        a.u-txt--tiny.u-fg--charcoal(href='/terms') Syarat dan Ketentuan
        span &nbsp;
        span • &nbsp;
        a.u-txt--tiny.u-fg--charcoal(href='/terms?tab=kebijakan-privasi') Kebijakan Privasi
      span &copy; {{ copyrightYear }} Hak Cipta Terpelihara PT Bukalapak.com.
</template>

<script>
export default {
  name: 'FooterCopyright',
  computed: {
    copyrightYear() {
      return (new Date()).getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.c-copyright {
  background-color: #eff1f9;

  &__links {
    margin-right: 5px;
    @media (max-width: 983px) {
      margin-right: 0;
      display: block;
    }
    & a {
      text-decoration: underline;
    }
  }


}
</style>
