<template lang="pug">
.c-footer__cta
  h2.c-landing-footer__title.u-txt--xxxlarge.u-txt--light.u-mrgn-bottom--8 {{ title }}
  nuxt-link.c-btn.c-btn--block.c-btn--large.c-btn--red(
    ref="startButton",
    :to="buttonLink"
    @click.native="handleClickCTA($event, 'Mulai', buttonLink)"
  ) Mulai
</template>

<script>
import { mapGetters } from 'vuex';

// mixins
import tracker from '~/mixins/tracker';

export default {
  name: 'FooterCTADesktop',
  mixins: [tracker],
  props: {
    utmSource: {
      type: String,
      default: 'desktop_footer',
    },
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
    }),
    title() {
      return this.isUserLoggedIn ? 'Ayo jadi Mitra Bukalapak dan nikmati keuntungannya!' : 'Biar yakin jadi Mitra Bukalapak, coba dulu keuntungannya!';
    },
    buttonLink() {
      return this.isUserLoggedIn ? '/' : '/beranda';
    },
  },
  methods: {
    handleClickCTA(e, label, url) {
      const btnName = label.toLowerCase().replace(/\s+/g, '_');
      this.trackGoToHomepage(e, `button_${btnName}_${this.utmSource}`, url);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-btn--block {
  max-width: 320px;
}
</style>
