<template lang="pug">
footer(:class="footerClass")
  .footer-seo-content.o-container
    LazyHydrate(never)
      nav.footer-seo-content__backlinks(v-html="htmlToShow")
</template>

<script>
import { parse, stringify } from 'himalaya';
import LazyHydrate from 'vue-lazy-hydration';
import tracker from '~/mixins/landing-page-tracker';

export default {
  name: 'FooterSeo',
  props: {
    variant: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
  mixins: [tracker],
  data() {
    return {
      footerClass: ''
    };
  },
  components: {
    LazyHydrate
  },
  computed: {
    htmlToShow() {
      const parsedHtml = parse(this.content);

      // Update title in each section into h3
      parsedHtml.forEach((el, index) => {
        if (el.tagName === 'ul') {
          parsedHtml[index].children.forEach((element2, childIndex) => {
            if (element2.tagName === 'li') {
              const previousLiText = parsedHtml[index].children[childIndex].children[0];
              parsedHtml[index].children[childIndex].children[0] = {
                type: 'element',
                tagName: 'h3',
                attributes: [],
                children: [previousLiText]
              };
            };
          });
        };
      });

      return `<nav class="footer-seo-content__backlinks_section">${stringify(parsedHtml)}</nav>`;
    },
  }, 
  mounted() {
    if (this.variant === 'responsive') {
      this.footerClass = 'footer-seo';
    } else if (this.variant === 'mobile') {
      this.footerClass = 'm-footer-seo';
    }
    this.$el.addEventListener('click', this.handleClickAnchor, false);
  },
  beforeDestroy() {
    this.$el.removeEventListener('click', this.handleClickAnchor, false);
  },
  methods: {
    handleClickAnchor(e) {
      if (e?.target?.tagName === 'A') {
        this.trackEventLandingPage('link_footer', e.target.textContent);
      }
    }
  }

};
</script>

<style lang="scss">
.footer-seo, .m-footer-seo {
  background-color: #f8f9fd;
  margin-top: 40px;
  padding: 30px 0;

  .footer-seo-content {
    position: relative;
    z-index: 2;

    &__backlinks {
      p, h1, h2, h3, h4 {
        font-size: 12px;
        color: #333;
        line-height: 1.4;

        strong {
          font-weight: bold;
        }
      }

      h1, h2, h3, h4 {
        line-height: 1;
        margin-bottom: 10px;
      }

      ul {
        margin-left: 0;
        list-style-type: none;

        li {
          padding: 0;
          margin-bottom: 20px;

          &:first-child {
            h1, h2, h3, h4 {
              margin-top: 25px;
            }
          }

          h1, h2, h3, h4 {
            margin-bottom: 7px;

            a {
              text-decoration: none;
              color: #333;
            }
          }

          ul {
            li {
              display: inline-block;
              margin-right: 5px;
              margin-bottom: 7px;
              font-size: 12px;
              text-decoration: underline;

              a {
                text-decoration: underline;
                color: #333;
              }

              &::after {
                content: "\2022";
                margin-left: 5px;
              }

              &:last-child::after {
                content: "";
              }
            }
          }
        }
      }

    }
  }
}

@media only screen and (min-width: $global-breakpoint) {
  .footer-seo {
    position: relative;
    margin-top: 250px;
    padding: 50px 0;

    &::before {
      position: absolute;
      width: 100%;
      height: 250px;
      top: -65px;
      left: 0;
      content: '';
      background-color: #f8f9fd;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transform: skewY(-5deg);
      transition: all 200ms ease-in-out;
      z-index: 0;
    }

    .footer-seo-content {
      width: 90%;
      max-width: 1300px;
    }
  }
}
</style>

