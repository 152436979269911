import { generateRequestContext } from '~/server/helpers/request-context-helper';
import { setClient } from '~/api/axios-instance';

export default ({ $axios, store, env, req }) => {
  $axios.onRequest(async config => {
    // If Config Already Has Headers Authorization 
    if (config.headers.Authorization) return config;
    
    env.API_LIST.forEach(val => {
      if (!env.proxy) {
        config.url = config.url.replace(val.url, val.base);
      }
    });

    let accessToken = store.getters['auth/getAccessToken'];
    if (store.getters['auth/isTokenExpired']) {
      await store.dispatch('auth/requestToken', req || { headers: { ...config.headers.common } });
      accessToken = store.getters['auth/getAccessToken'];
    }
    if (accessToken) {
      config.headers.common.authorization = `Bearer ${accessToken}`;
    }
    config.headers.common = {
      ...config.headers.common,
      ...generateRequestContext(req || { headers: { ...config.headers.common } }),
    };

    return config;
  });
  setClient($axios);
};
