import urljoin from 'url-join';
import { generateJsonLdScript, getDefaultMetaAttributes, getSocialMediaMeta } from '~/utils/meta-utils';

const generateHead = ({ label, path, imageDweb, imageMweb, seoProps }, webHost = process.env.WEB_HOST) => {
  const meta = getDefaultMetaAttributes(label);

  const title = seoProps?.meta_title || meta.title;
  const description = seoProps?.meta_description || meta.description;
  const script = generateJsonLdScript(seoProps?.schema || '[]');

  const url = urljoin(webHost, path);
  const socialMediaMeta = getSocialMediaMeta({ url, title, description });

  return {
    title,
    meta: [{ hid: 'description', name: 'description', content: description }, ...socialMediaMeta],
    __dangerouslyDisableSanitizers: ['script'],
    script,
    link: [
      { href: `android-app://com.bukalapak.mitra.android/${url}`, rel: 'alternate' },
      {
        href: url,
        rel: 'alternate',
        media: 'only screen and (max-width: 640px)',
      },
      {
        rel: 'preload',
        href: `${process.env.ASSETS_URL ?? ''}${imageDweb}.webp`,
        as: 'image',
        type: 'image/webp',
      },
      {
        rel: 'preload',
        href: `${process.env.ASSETS_URL ?? ''}${imageMweb}.webp`,
        as: 'image',
        type: 'image/webp',
      },
    ],
  };
};

export default generateHead;
