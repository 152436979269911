<template lang="pug">
.main-board.mobile-fixed-container
  Header
  Navigation(:pageList="pageList" @onTabChanged="syncTabMenu" v-if="showNav")
  Menu(:class="{ 'pressed-viewport': hideMenu }")
  nuxt(:key="$route.name" :class="{ 'pressed-viewport': !hideMenu }")
  Footer
  Copyright
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Header from '~/components/Main/Header';
import Navigation from '~/components/Main/Navigation';
import Footer from '~/components/Main/Footer';
import Menu from '~/components/Main/Menu';
import Copyright from '~/components/footer/Copyright';

import canonical from '~/mixins/canonical';
import tracking from '~/mixins/middleware/tracking';

export default {
  name: 'MainLayout',
  components: {
    Header,
    Navigation,
    Footer,
    Menu,
    Copyright,
  },
  mixins: [canonical, tracking],
  scrollToTop: true,
  head() {
    return {
      htmlAttrs: {
        lang: 'id',
      },
      link: [this.linkCanonical],
    };
  },
  data: () => ({
    hideMenu: true,
    showNav: true,
  }),
  computed: {
    ...mapGetters({ getAccessToken: 'auth/getAccessToken' }),
    ...mapState({ identity: state => state.auth.identity }),
    pageList() {
      return {
        home: {
          title: 'Home',
          icon: 'ico_home',
          target: 'beranda',
          fixed: true,
        },
        grosir: {
          title: 'Grosir',
          target: 'grosir_home',
        },
        transaksi: {
          title: 'Transaksi',
          target: `${process.env.WEB_HOST}/register`,
        },
        bantuan: {
          title: 'Bantuan',
          target: `${process.env.WEB_HOST}/faq?token=${this.getAccessToken}&deviceId=${this.identity}`,
        },
        menu: {
          title: 'Menu',
          icon: 'ico_menu',
          target: '__menu',
          fixed: true,
        },
      };
    },
  },
  watch: {
    $route() {
      this.showNav = this.$route.params.nav ?? true;
      if (process.client) window.scrollTo(0, 0);
    },
  },
  methods: {
    syncTabMenu(tabName) {
      this.hideMenu = tabName !== 'menu';
    },
  },
  mounted() {
    this.$nuxt.$on('toggleNav', toggle => {
      this.showNav = toggle;
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile-fixed-container {
  max-width: 540px;
  margin: auto;
}
</style>
