import selectors from './selector.js';
import Vue from 'vue';
import camelcase from 'camelcase';

Vue.directive('tid', function (el, { value }) {
  if (value?.type === 'id' && !el.id) {
    el.id = value.selector;
  } else if (['data-test-id', 'data-testid'].includes(value?.type)) {
    const label = camelcase(value.type.replace('data-', ''));
    el.dataset[label] = value.selector;
  }
});

export default function (context, inject) {
  inject('id', selectors);
}
