<template lang="pug">
  a.u-align-center(@click.stop="clickTab($event)", :class="`${gridClass} ${activeTab}`")
    p.u-pad-v--3.u-mrgn-bottom--0.u-txt--fair(:class="activeTabText") {{ tabText }}
</template>

<script>
export default {
  name: 'TabItem',
  props: {
    tabText: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    grid: {
      type: String,
      default: '2of4',
    },
  },
  computed: {
    activeTab() {
      return this.isActive ? 'u-border-bottom--2--red-brand' : '';
    },
    activeTabText() {
      return `u-fg--${this.isActive ? 'red-brand u-txt--bold' : 'ash-dark'}`;
    },
    gridClass() {
      return `u-${this.grid}`;
    },
  },
  methods: {
    clickTab(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
