<template lang="pug">
mixin productBenefits
  td
    BlFlexContainerMv(alignItems="center")
      BlFlexItemAv
        BLBPicture.mr-12(
          :datatest-id="`benefit-table-icon-${benefit.key}`"
          :webp="false"
          imgClass="benefit-icon"
          :src="benefit.icon"
        )
      BlFlexItemAv(basis="1")
        BlTextAv.text-left(
          @click="$router.push(`${benefit.route}`)"
          :variation="`${ forDesktop ? 'body-14' : 'caption-12' }`"
          :weight="`${ forDesktop ? 'bold' : 'regular' }`"
        ) {{ benefit.product }}
  td
    BlTextAv(
      :variation="`${ forDesktop ? 'body-14' : 'caption-12' }`"
      weight="bold"
    ) {{ benefit.benefit }}

div
  table.c-table(
    :class="{ 'u-pad-top--10': !isMobileWidth }"
    datatest-id="benefit-table"
  )
    thead
      tr
        th Produk
        th Keuntungan
    tbody
      tr(
        v-for="benefit in landingBenefits.slice(0, benefitTableVisibleLimit)"
        :key="benefit.product"
        :class="isExpanded ? 'no-mask' : ''"
      )
        +productBenefits

  .see-more-link(v-if="!isExpanded")
    a(@click="isMobileWidth ? toggleBenefitTableModal('show') : expandTable()") Lihat Selengkapnya
  //- TODO: update this using proper BlSheetMv if bazaar updated
  BLCRemodal.benefit-table-modal(
    name="full-benefit-table-modal"
    title="Keuntungan jadi Mitra"
    :showCloseBtn="true"
    :overlayClickToClose="toggleBenefitTableModal('hide')"
  )
    table.c-table
      thead
        tr
          th Produk
          th Keuntungan
      tbody
        tr.no-mask(v-for="benefit in landingBenefits" :key="benefit.product")
          +productBenefits(benefit)
</template>

<script>
import { mapGetters } from 'vuex';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';

import BLBPicture from '~/components/shared/base/BLBPicture';
import BLCRemodal from '~/components/shared/compound/BLCRemodal';

import responsive from '~/mixins/responsive';

export default {
  name: 'BenefitTable',
  components: {
    BLBPicture,
    BLCRemodal,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  mixins: [responsive],
  data: () => ({
    benefitTableVisibleLimit: 5,
    isExpanded: false,
    mobileWidthLimit: 983,
  }),
  computed: {
    ...mapGetters({
      landingBenefits: 'landing/landingBenefits',
    }),
    isMobileWidth() {
      return process.client ? window.innerWidth <= this.mobileWidthLimit : false;
    },
  },
  methods: {
    toggleBenefitTableModal(flag) {
      this.$BLRemodal[flag]('full-benefit-table-modal');
    },
    expandTable() {
      this.benefitTableVisibleLimit = this.landingBenefits.length;
      this.isExpanded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-left {
  text-align: left;
}

.see-more-link {
  width: 100%;
  text-align: center;
  height: 20px;
  margin-top: 10px;
  > a {
    color: #2b4ac7;
    font-weight: 700;
  }
}
.c-table {
  width: 100%;
  thead > tr {
    background-color: #d71149;
    > th:last-of-type {
      border-left: 1px solid #fff;
    }
    > th {
      color: white;
      text-transform: capitalize;
      text-align: center;
      font-weight: 500;
    }
  }
  tbody > tr:last-of-type {
    > td {
      mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    }
  }
  tr.no-mask:last-of-type {
    > td {
      mask-image: none;
      border-bottom: 1px solid #ddd;
    }
  }
  td {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    text-align: center;
  }
  td:last-of-type {
    font-weight: 500;
  }
}

@media only screen and (max-width: $global-breakpoint) {
  .c-table {
    thead > tr {
      > th {
        width: 50%;
        padding: 16px 12px;
        font-size: 14px;
        line-height: 1.57;
      }
    }
    td {
      padding: 12px;
      font-size: 12px;
      line-height: 1.33;
    }
    td:first-of-type {
      border-left-width: 0;
    }
  }

  ::v-deep .benefit-icon {
    width: 32px;
    height: 32px;
  }

  .benefit-table-modal {
    ::v-deep .c-remodal__panel__body {
      padding: 0;
    }
  }
}
@media only screen and (min-width: $global-breakpoint) {
  .c-table {
    border-collapse: initial;
    border-radius: 8px;
    max-width: 743px;
    margin: auto;
    thead > tr {
      > th:first-of-type {
        width: 35%;
        border-top-left-radius: 8px;
      }
      > th:last-of-type {
        width: 65%;
        border-top-right-radius: 8px;
      }
      > th {
        padding: 16px 30px;
        font-size: 18px;
        line-height: 1.22;
      }
    }
    tbody > tr:last-of-type {
      > td:first-of-type {
        border-bottom-left-radius: 8px;
      }
      > td:last-of-type {
        border-bottom-right-radius: 8px;
      }
    }
    td {
      padding: 22px 30px;
      font-size: 16px;
      line-height: 1.25;
    }
    th:first-of-type,
    td:first-of-type {
      text-align: left;
    }
    td:last-of-type {
      border-right: 1px solid #ddd;
    }
  }

  ::v-deep .benefit-icon {
    width: 42px;
    height: 42px;
  }
}
</style>
