<template lang="pug">
  .c-footer__content.o-container.o-flag
    .c-footer__left.o-flag__body.u-align-left.u-valign-top.u-2of4
      CTA.u-mrgn-bottom--8(:utmSource="utmSource")
      FindUs(:utmSource="utmSource")

    .c-footer__right.o-flag__body.u-align-right.u-valign-top.u-2of4(style="white-space: nowrap;")
      Download.u-mrgn-bottom--6(
        :link="googlePlayUrl"
        data-testid="google-play-button-bottom"
      )
      ScanQR.u-mrgn-bottom--6
</template>

<script>
import { mapGetters } from 'vuex';
import CTA from './CTA';
import Download from './Download';
import FindUs from './FindUs';
import ScanQR from './ScanQR';

export default {
  name: 'FooterDesktop',
  components: {
    CTA,
    Download,
    FindUs,
    ScanQR,
  },
  props: {
    utmSource: {
      type: String,
      default: 'desktop_footer',
    },
    downloadCampaign: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    referrer: '',
  }),
  computed: {
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
      homeDownloadLink: 'global/homeDownloadLink',
    }),
    googlePlayUrl() {
      if (this.$route.name === 'agenlite_landing_root') {
        return `${this.homeDownloadLink}?campaign=mitra-web-landingpage&adgroup=${this.downloadCampaign}&creative=${this.referrer}`;
      }

      return `${this.downloadAppLink}?campaign=${this.downloadCampaign}`;
    },
  },
  mounted() {
    this.setReferrer();
  },
  methods: {
    setReferrer() {
      // Somehow, we can't get the referrer when it's called directly via computed
      this.referrer = (!process.server && document && document.referrer) || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.c-footer__content {
  width: 90%;
  max-width: 1300px;
}
</style>
