import { generateRequestContext } from '~/server/helpers/request-context-helper';

import { requestToken } from '~/api/oauth';
import { setToken } from '~/server/token';

export const state = () => ({
  token: {},
  loginState: false,
  identity: '',
});

export const getters = {
  isUserLoggedIn(localState) {
    return localState.loginState;
  },
  getAccessToken: localState => localState.token.access_token,
  getUserId: localState => localState.token.userId,
  getCurrentUserId36(localState) {
    const blToken = JSON.parse(window.localStorage.getItem('bl_token'));
    const userId = localState.token.userId || blToken?.userId || 0;
    return Number(userId).toString(36);
  },
  getToken: localState => localState.token,
  isTokenExpired: localState => {
    const { token } = localState;
    if (token) {
      if (!token.created_at) {
        return true;
      }
      const DEFAULT_TOKEN_LIFESPAN = 7200;
      const tokenLifespan = token.expires_in || DEFAULT_TOKEN_LIFESPAN;
      const expiredAt = (token.created_at + tokenLifespan) * 1000;
      const now = Date.now();
      return expiredAt < now;
    }
    return true;
  },
  getIdentity: localState => {
    return localState.identity;
  }
};

export const mutations = {
  setToken(localState, token) {
    if (!token.authorization) {
      token.authorization = localState.token.authorization;
    }
    localState.token = token;
  },
  setCredentials(localState, { login, identity }) {
    localState.loginState = login;
    localState.identity = identity;
  }
};

export const actions = {
  assignCredentials({ commit }, { login, identity }) {
    commit('setCredentials', { login, identity });
  },
  async requestToken({ commit }, req) {
    try {
      let token = {};
      if (process.server) {
        token = await setToken(req);
      } else {
        const response = await requestToken({
          ...req.headers,
          ...generateRequestContext(req),
        });
        token = response.data;
      }
      if (req?.headers?.authorization) {
        token.authorization = req.headers.authorization;
      }
      commit('setToken', token);
      return token;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[ERROR REQUEST TOKEN]', error.message);
      return {};
    }
  },
};
