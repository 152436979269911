<template lang="pug">
  footer.c-mitra-new-footer
    NewFooterDesktop(
      :downloadLink="downloadLink"
      data-testid="new-footer-dekstop"
    )
</template>

<script>
import { mapGetters } from 'vuex';

import NewFooterDesktop from './desktop.vue';

export default {
  name: 'NewFooterDesktopIndex',
  components: {
    NewFooterDesktop,
  },
  props: {
    downloadCampaign: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
      homeDownloadLink: 'global/homeDownloadLink',
    }),
    downloadLink() {
      const referrer = (!process.server && document && document.referrer) || '';
      if (this.downloadCampaign) {
        return `${this.homeDownloadLink}?campaign=${this.downloadCampaign}&adgroup=tentang-mitra-footer&creative=${referrer}`;
      }

      return `${this.downloadAppLink}?campaign=tentang-mitra-footer`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
