<template lang="pug">
  .tentang-kami-page-juwara 
    .tentang-kami-page-juwara__content.container-juwara
      JuwaraModal(
        modalName="juwara-modal"
        :source="modalSource"
        from="juwara"
        :overlayClickToClose="false"
      )
      .juwara-text
        ul.ul-juwara-title
          li(style='list-style-type:none') 
            BlTextAv.juwara-title(variation="heading-42" weight="medium") Jadi Sang Juara Bersama 
          li(style='list-style-type:none') 
            BlFlexContainerMv.juwara-title-content-container
              BlTextAv.juwara-title(variation="heading-42" weight="medium") Komunitas
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/logo_juwara.png"
                title="Logo Juwara"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
          li.li-juwara-title(style='list-style-type:none')
            BlTextAv.juwara-subtitle(variation="body-16" weight="regular") Juwara (Juragan Warung Mitra Bukalapak) adalah komunitas mitra warung terbesar di Indonesia untuk membantu para juragan yang merupakan pemilik warung dan agen individu untuk meningkatkan kualitas prestasi bisnis agar menjadi #warungnaikkelas 
      BlFlexContainerMv.juwara-flex-container
        .juwara-item-container
          .juwara-img-container.whatsapp-juwara-container
            .juwara-img-open-modal(@click="openModal('whatsapp_juwara')") 
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/whatsapp_juwara.png"
                title="Whatsapp Group Komunitas Juwara"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Whatsapp Group Komunitas Juwara

          .juwara-img-container.skj-container
            .juwara-img-open-modal(@click="openModal('skj')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/skj.png"
                title="Spesial Kumpul Juwara (SKJ)"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Spesial Kumpul Juwara (SKJ)

          .juwara-img-container.perpus-juwara-container
            .juwara-img-open-modal(@click="openModal('perpus_juwara')") 
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/perpus_juwara.png"
                title="Perpus Juwara"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Perpus Juwara 

        .juwara-item-container.juwara-container-mobile(:style="`display: ${displayJuwaraContainer}`")
          .juwara-img-container.program-juwara-container
            .juwara-img-open-modal(@click="openModal('program_juwara')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/program_juwara.png"
                title="Program Sultan Juwara dan Mentor Juwara"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Program Sultan Juwara dan Mentor Juwara

          .juwara-img-container.spekta-container
            .juwara-img-open-modal(@click="openModal('spekta')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/spekta.png"
                title="SPEKTA (Spesial Kumpul Akhir Tahun Juwara)"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") SPEKTA (Spesial Kumpul Akhir Tahun Juwara)
          .juwara-img-container.semarak-container
            .juwara-img-open-modal(@click="openModal('semarak')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/semarak.png"
                title="Spesial Kumpul Juwara Kemerdekaan (SEMARAK)"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Spesial Kumpul Juwara Kemerdekaan (SEMARAK)

        .juwara-item-container.juwara-container-mobile(:style="`display: ${displayJuwaraContainer}`")
          .juwara-img-container.halalbihalal-container
            .juwara-img-open-modal(@click="openModal('halalbihalal')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/halalbihalal.png"
                title="Halalbihalal"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Halalbihalal

          .juwara-img-container.juwita-container
            .juwara-img-open-modal(@click="openModal('juwita')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/juwita.png"
                title="Juwara dalam Ingatan (JUWITA)"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Juwara dalam Ingatan (JUWITA)
          .juwara-img-container.empty-container
            .juwara-img-open-modal(@click="openModal('halalbihalal')")
              BLBPicture.juwara-img(
                :lazy="false"
                :webp="false"
                img-class="juwara-img__pic"
                src="/images/tentang-kami/halalbihalal.png"
                title="Halalbihalal"
                :imgStyle="{ width: '100%', height: '100%' }"
              )
            BlTextAv.juwara-img-text.pt-12(variation="body-16" weight="bold") Halalbihalal


        BlButtonAv.juwara-container-button-mobile(:style="`display: ${displayMobileButton}`" variation="outline" @click="showJuwaraMobileContainer") Lihat lebih banyak
</template>


<script>
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BLBPicture from "~/components/shared/base/BLBPicture.vue";
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';

import JuwaraModal from '~/components/TentangKamiPage/JuwaraSection/JuwaraModal.vue';

export default {
  name: "TentangKamiPageJuwara",
  components: {
    BlFlexItemAv,
    BlTextAv,
    BlFlexContainerMv,
    BLBPicture,
    JuwaraModal,
    BlButtonAv,
  },
  props: {
    modalSource: {
      type: String,
    },
  },
  data() {
    return {
      displayMobileButton: "",
      displayJuwaraContainer: "",
    };
  },
  methods: {
    openModal(source) {
      this.setModalAttr(source);
      this.$BLRemodal.show('juwara-modal');
    },
    setModalAttr(source) {
      this.modalSource = source;
    },
    showJuwaraMobileContainer() {
      this.displayMobileButton = "none";
      this.displayJuwaraContainer = "flex";
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.tentang-kami-page-hero {
  overflow: hidden !important;
}

.container-juwara {
  width: 100%;
}

.juwara-title {
  line-height: $line-height-48;
  letter-spacing: $space-0;
}

.juwara-subtitle {
  line-height: $line-height-24;
  letter-spacing: $space-0;
  color: $background-medium;
}

.juwara-flex-container {
  padding-top: $space-40;
  justify-content: space-between;
  flex-direction: column;
  gap: $space-24;
}

.juwara-img-text {
  line-height: $line-height-20;
  letter-spacing: $space-0;
  text-align: left;
}

.juwara-item-container {
  justify-content: center;
  flex-direction: row;
  align-content: space-around;
  display: flex;
  gap: $space-24;
  width: 100%;
}

.juwara-title-content-container {
  flex-direction: row;
  justify-content: center;
  gap: $space-8;
}

.ul-juwara-title {
  text-align: center;
  margin-left: 0;
}

.li-juwara-title {
  padding-left: 0;
}

.juwara-container-mobile {
  display: flex;
}

.juwara-container-button-mobile {
  display: none;
}

.empty-container{
  opacity: 0;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .juwara-title {
    font-size: $font-size-28;
    line-height: $line-height-34;
    font-weight: $font-weight-bold;
  }

  .juwara-img {
    width: 104px;
    height: 34px;
  }

  .juwara-subtitle {
    line-height: $line-height-22;
    font-size: $font-size-14;
  }

  .juwara-flex-container {
    justify-content: center;
  }

  .juwara-item-container {
    flex-direction: column;
  }

  .juwara-container-mobile {
    display: none;
  }

  .juwara-container-button-mobile {
    display: inline-flex;
  }
  .empty-container{
    display: none;
  }
}

@media only screen and (min-width: 681px) {
  .juwara-container-mobile {
    display: flex;
  }

  .juwara-container-button-mobile {
    display: none;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .juwara-title {
    font-size: $font-size-32;
  }

  .juwara-img {
    width: 144px;
    height: 44px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .juwara-title {
    font-size: $font-size-42;
  }

  .juwara-img {
    width: 144px;
    height: 44px;
  }
}

</style>
