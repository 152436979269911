<template lang="pug">
  .tentang-kami-page-produk-kami
    <products />
</template>

<script>
import Products from "~/components/TentangKamiPage/ProdukKamiSection/Products.vue";

export default {
  name: "TentangKamiPageProdukKami",
  components: {
    Products,
  },
};
</script>
