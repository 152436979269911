import { v4 as uuidv4 } from 'uuid';
import responsive from './responsive';

const PREFIX = 'agenlite_product_landing_page_';
const CLICK_ID_KEY = 'hermes_click_id';

const trackerMixin = {
  mixins: [responsive],
  watch: {
    $route() {
      // reset click_id cache if client redirect
      window[CLICK_ID_KEY] = null;
    },
  },
  computed: {
    platform() {
      return this.device === 'desktop' ? 'desktop-web' : 'mobile-web';
    },
    currentPath() {
      return this.$route?.path || window?.location?.pathname || '';
    },
    userID() {
      return this.$store.getters['auth/getCurrentUserId36'] || '';
    },
    clickID() {
      if (window[CLICK_ID_KEY]) return escape(window[CLICK_ID_KEY]);
      const generatedClickId = uuidv4();
      window[CLICK_ID_KEY] = generatedClickId;

      return generatedClickId;
    }
  },
  methods: {
    async trackLandingPageVisit() {
      const payload = {
        evn: `${PREFIX}visit`,
        path: this.currentPath,
        click_id: this.clickID,
        ui: this.userID,
        web_platfrom: this.platform,
      };
      this.$tracker.event(payload);
    },
    async trackEventLandingPage(action, text) {
      const payload = {
        evn: `${PREFIX}action`,
        click_id: this.clickID,
        action,
        path: this.currentPath,
        web_platfrom: this.platform,
        ui: this.userID,
      };
      if (text) payload.text = text;
      this.$tracker.event(payload);
    },
  },
};

export default trackerMixin;
