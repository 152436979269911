/**
 * @module helpers/number-helper
 */

import createNumberMask from 'text-mask-addons/dist/createNumberMask'; // https://github.com/text-mask/text-mask/tree/master/addons/#readme

/**
 * Return random integer in between min and max
 *
 * @example
 * getRandomInt(0, 5)
 * // => 0, 1, 2, 3, 4 or 5
 *
 * @param  {Number} min minimum possible value
 * @param  {Number} max maximum possible value
 *
 * @return {Number}
 */
export function getRandomInt(min, max) {
  if (max < min) {
    throw new Error('Max must be greater or equal than min');
  }

  // eslint-disable-next-line no-mixed-operators
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Return the positive of a number
 *
 * @example
 * toPositiveInt(-4)
 * // => 4
 *
 * @param  {Number} val
 * @return {Number}
 */
export function toPositiveInt(val) {
  if (typeof val !== 'number') {
    throw new Error('toPositiveInt must receive number as input');
  }
  return Math.abs(val);
}

/**
 * Delimit number
 *
 * @example
 * delimiter(1000000)
 * // => '1.000.000'
 *
 * @param  {Number|String} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(val) {
  const str = `${val}`; // convert to string
  const delimited = str.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${val < 0 ? '-' : ''}${delimited}`;
}

/**
 * Convert number to money
 *
 * @example
 * money(1000000)
 * // => 'Rp1.000.000'
 *
 * @param  {Number|String} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val) {
  const delimited = delimiter(val);
  if (delimited.charAt(0) === '-') {
    return delimited.replace(/^-/g, '-Rp');
  }
  return `Rp${delimited}`;
}

/**
 * Strip 'Rp' and '.' from money format
 *
 * @example
 * stripMoney('Rp50.000')
 * // => '50000'
 *
 * @param  {String} val String to convert
 * @return {String} Stripped amount
 */
export function stripMoney(val) {
  let nominal = val;
  nominal = nominal.replace('Rp', '').replace(/\./g, '');
  return nominal;
}

/**
 * Transform number to simple currency format
 * can't satisfy these cases below
 * 15.750 ---> 15,7rb
 * 13.456.000 ---> 13,4jt
 *
 * @example
 * simpleMoney(500000)
 * // => 'Rp500rb'
 *
 * @param  {Number} Amount required e.g. 10000
 * @param  {String} Symbol optional e.g. 'Rp'
 * @return {String} Transformed number
 */
export function simpleMoney(amount, symbol = 'Rp') {
  let absAmount = amount;
  if (amount < 0) absAmount = Math.abs(Number(amount));

  let parsedNumber = absAmount || 0;
  const units = ['', 'rb', 'jt', 'M', 'T'];

  let unitIndex = 0;
  while (parsedNumber >= 1000) {
    parsedNumber /= 1000;
    unitIndex += 1;
  }

  parsedNumber = parseFloat(Math.floor(parsedNumber * 10) / 10);
  if (parsedNumber === Math.floor(parsedNumber)) parsedNumber = Math.floor(parsedNumber);

  const simpleFormat = `${symbol}${parsedNumber.toString().replace('.', ',')}${units[unitIndex]}`;
  return `${amount < 0 ? '-' : ''}${simpleFormat}`;
}

/**
 * Return Currency Mask for 'Vue Text Mask' Component
 *
 * @example
 * https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js
 * Used in this component -> https://github.com/text-mask/text-mask/tree/master/vue#readme
 * Documentation          -> https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md
 * Live demo              -> https://text-mask.github.io/text-mask/
 *
 * @return {Array} Currency format in array form
 */
export function currencyMask(prefix = '') {
  return createNumberMask({ prefix, thousandsSeparatorSymbol: '.', decimalLimit: 0 });
}

/**
 * Return Integer Mask for 'Vue Text Mask' Component
 *
 * @example
 * https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js
 * Used in this component -> https://github.com/text-mask/text-mask/tree/master/vue#readme
 * Documentation          -> https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md
 * Live demo              -> https://text-mask.github.io/text-mask/
 *
 * @param  {Boolean} AllowNegative whether can input negative value or not
 * @param  {Number|null} IntegerLimit define number of digit that can be inputted (excl. '-' sign)
 * @return {Array} Integer format in array form
 */
export function integerMask(allowNegative = false, integerLimit = null) {
  return createNumberMask({ prefix: '', thousandsSeparatorSymbol: '', decimalLimit: 0, allowNegative, integerLimit });
}

export default {
  getRandomInt,
  toPositiveInt,
  delimiter,
  money,
  currencyMask,
  integerMask,
};
