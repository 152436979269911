import { v4 as uuidv4 } from 'uuid';

export function generateRequestContext(req) {
  const headerAuthorization = req?.headers?.authorization ? req.headers.authorization.replace('Bearer ', '') : '';
  const headerUserToken = req?.headers?.['user-token'] ? req.headers['user-token'] : '';
  const userToken = headerAuthorization || headerUserToken || '';
  return {
    'x-request-id': req?.headers?.['x-request-id'] ? req.headers['x-request-id'] : uuidv4(),
    'BL-Service': 'hermes',
    'bl-user-context': userToken,
  };
}

export default {
  generateRequestContext,
};
