<template lang="pug">
li.c-category-item(:class="{ 'c-category-item--active': isActive }")
  nuxt-link(:to="url" id="cta-category-tab" v-slot="{ href }" custom)
    a(:href="href")
      bl-text-av.c-category-item__text(
        :variaton="forDesktop ? 'body-16' : 'caption-12'"
        :color="isActive ? 'highlight-01' : 'primary'"
        :weight="isActive ? 'bold' : 'regular'"
      ) {{ text }}
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';

import responsive from '~/mixins/responsive';

export default {
  name: 'CategoryItem',
  components: {
    BlTextAv,
  },
  mixins: [responsive],
  props: {
    text: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      return this.slug ? `/artikel/${this.slug}` : '/artikel';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/@bukalapak/bazaar-token/dist/tokens.scss';

.c-category-item {
  padding: $space-20 0;
  margin: 0 $space-20;
  flex: 0 0 auto;
  text-align: center;
  width: auto;
  list-style: none;

  &__text {
    text-transform: uppercase;
  }

  &--active {
    border-bottom: 3px solid $background-playful-01;
  }
}

@media only screen and (max-width: $global-breakpoint) {
  .c-category-item {
    padding: $space-16 0;
    margin: 0 10px;
  }
}
</style>
