/**
 * @module helpers/metrics-helper
 */

import axios from 'axios';
import { FormData } from 'formdata-node';
import { timeDiffSeconds } from './date-helper';

export const API_TIMEOUT_DURATION = 30000;

export function logStdout(name = '', params = {}) {
  const form = new FormData();
  form.append('message', name);
  form.append('data', JSON.stringify(params));
  axios.post('/hermes/api-report', form);
}

export function promLatency(startTime, action, path = '/', status = 'ok') {
  const elapsedTime = timeDiffSeconds(startTime, new Date().getTime());
  const form = new FormData();
  form.append('histogramName', 'service_latency_seconds');
  form.append('time', elapsedTime);
  form.append('action', action);
  form.append('path', path);
  form.append('status', status);
  axios.post('/hermes/prom', form);
}

export function promCounter(metricLabel, status = 'ok') {
  const form = new FormData();
  form.append('name', metricLabel);
  form.append('status', status);
  axios.post('/hermes/promc', form);
}

export function promCounterEx(metricLabel, metricDataset = { status: 'ok' }) {
  const postData = { ...metricDataset, name: metricLabel };
  axios.post('/hermes/promcex', postData);
}

export default {
  API_TIMEOUT_DURATION,
  logStdout,
  promLatency,
  promCounter,
};
