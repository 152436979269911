<template lang="pug">
  .tentang-kami-page-cta
    .tentang-kami-page-cta__content.container-cta
      BlFlexContainerMv.cta-flex-container(alignItems="center")
        .cta-item-container-left
          ul.cta-text-ul
            li(style='list-style-type: none')
              BlTextAv.cta-text(weight="medium") Yuk, download
            li(style='list-style-type: none')
              BlTextAv.cta-text(weight="medium") sekarang!
            li.cta-img(style='list-style-type: none')
              .cta-download-google-play-img-container.mt-20
                a(:href="downloadLink", target="_blank", @click="trackEventClick('download_playstore_aplikasi_mitra')")
                  BLBPicture.cta-download-google-play-img(
                    :lazy="false"
                    :webp="false"
                    img-class="cta-download-google-play-img__pic"
                    src="/images/footer/google_play.png"
                    title="Cta download google play"

                  )
        .cta-item-container-right
          BLBPicture.cta-phone-img(
            :lazy="false"
            :webp="false"
            img-class="cta-phone-img__pic"
            src="/images/tentang-kami/cta_phone.png"
            title="Cta phone"
            :style="{width: '100%'}"
          )
</template>

<script>
import { mapGetters } from 'vuex';
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BLBPicture from "~/components/shared/base/BLBPicture.vue";
import tracker from '~/mixins/tracker';

export default {
  name: "TentangKamiPageCta",
  components: {
    BlFlexItemAv,
    BlTextAv,
    BlFlexContainerMv,
    BLBPicture,
  },
  mixins: [tracker],
  computed: {
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
    }),
    downloadLink() {
      const params = {
        campaign: 'landing-hero-banner',
        label: 'landing-hero-banner',
        deeplink: process.env.WEB_HOST,
      };
      const query = Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
      return `${this.downloadAppLink}?${query}`;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$container-cta-padding-right: 62px;
$cta-text-ul-margin-left: 32px;
$cta-text-line-height: 48px;
$cta-text-font-size: 42px;

.cta-item-container-right {
  margin-top: -$space-32;
  width: 100%;
}

.container-cta {
  background: $background-playful-02;
  border-radius: $space-12;
  padding-right: $container-cta-padding-right;
}

.cta-text {
  line-height: $cta-text-line-height;
  font-size: $cta-text-font-size;
}

.cta-item-container-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  width: 100%;
}

.cta-flex-container {
  flex-direction: row;
  justify-content: space-around;
  gap: $space-20;
}

.cta-text-ul {
  margin-left: $cta-text-ul-margin-left;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .cta-flex-container {
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }

  .cta-item-container-right {
    margin-top: unset;
  }

  .container-cta {
    padding-right: $space-20;
    padding-left: $space-20;
  }

  .cta-img {
    display: inline-block;
  }

  .cta-item-container-left {
    text-align: center;
    margin-top: $space-40;
    margin-left: $space-24;
    margin-right: $space-24;
  }

  .cta-text {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: $line-height-34;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .cta-text {
    font-size: $font-size-32;
    line-height: $line-height-34;
  }

  .cta-download-google-play-img-container {
    margin-top: $space-8 !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .cta-text {
    font-size: $font-size-42;
    line-height: $line-height-34;
  }

  .cta-download-google-play-img-container {
    margin-top: $space-8 !important;
  }
}

</style>
