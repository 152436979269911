<template lang="pug">
.apponboard(@mouseenter="stopStepper" @mouseleave="startStepper(currentStep)")
  .apponboard_images
    BLBPicture.apponboard_image(
      v-for="(step, index) in steps"
      :key="index"
      :class="{ active: index === currentStep }"
      :src="step.image"
      :title="step.title"
      :lazy="true"
      :imgStyle="{ borderRadius: '20px', height: '100%' }"
    )
    BLBPicture.apponboard_device(src="/images/landing/howtouse/device.png" :lazy="true" title="Handphone Mitra")
  .apponboard_captions
    .apponboard_caption(
      v-for="(step, index) in steps"
      :key="index"
      :class="{ active: index === currentStep }"
      @click="startStepper(index)"
    )
      .apponboard_caption-number {{ index + 1 }}
      .apponboard_caption-text
        .apponboard_caption-title(v-html="step.title")
        .apponboard_caption-desc(v-html="step.desc")
</template>

<script>
import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'AppOnboardingDesktop',
  components: {
    BLBPicture,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    init: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 0,
      stepIntervalTime: 1000 * 4, // 4 secs
      stepTimer: false,
    };
  },
  mounted() {
    if (this.init.index === this.init.tab) {
      this.startStepper(0);
    }
    this.$eventHub.$on(this.init.on, value => {
      if (this.init.index === value) {
        this.startStepper(0);
      } else {
        this.stopStepper();
      }
    });
  },
  destroyed() {
    this.$eventHub.$off(this.init.on);
    this.stopStepper();
  },
  methods: {
    startStepper(index) {
      this.stopStepper();
      this.currentStep = index;
      setTimeout(this.rotateStepper, this.stepIntervalTime);
    },
    stopStepper() {
      clearInterval(this.stepTimer);
      this.stepTimer = false;
    },
    rotateStepper() {
      this.stepTimer = setInterval(() => {
        this.currentStep = (this.currentStep + 1) % this.steps.length;
      }, this.stepIntervalTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.apponboard {
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  &_images {
    position: relative;
    box-sizing: border-box;
    width: 334px;
    height: 682px;
    margin-right: 44px;
  }
  &_image {
    position: absolute;
    opacity: 0;
    transition: all 250ms ease-in;
    width: 302px;
    height: 601px;
    margin: calc((681px - 600px) / 2) calc((334px - 302px) / 2);
    &.active {
      opacity: 1;
    }
  }
  &_device {
    width: 334px;
    height: 682px;
  }
  &_captions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 378px;
  }
  &_caption {
    cursor: pointer;
    padding: 1em;
    border: 1px solid #e4e5e9;
    border-radius: 12px;
    display: flex;
    padding: 24px;
    &-number {
      color: #333;
      background-color: #e8e9ed;
      border-radius: 36px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 24px;
      font-size: 14px;
    }
    &-text {
      flex: 1;
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    &-desc {
      font-size: 16px;
    }
    &.active {
      border: 1px solid #fff;
      background-color: #fff;
      box-shadow: 0 10px 42px 0 rgba(204, 204, 204, 0.5);
      .apponboard_caption-number {
        color: #fff;
        background-color: #d71149;
      }
    }
  }
}
</style>
