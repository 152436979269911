// landing page builder
import r from '~/utils/render-utils';

const PageLandingPB = () => import(/* webpackChunkName: 'landing-pb' */ '~/pages/LandingPB');

const landingPB = [
  {
    path: '/agen*', 
    component: r(PageLandingPB),
    name: 'agenlite_landing_pb_agen',
  },
  {
    path: '/produk*', 
    component: r(PageLandingPB),
    name: 'agenlite_landing_pb_produk',
  },
];

export default landingPB;
