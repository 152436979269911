// vp page
import r from '~/utils/render-utils';

const PriceListPage = () => import(/* webpackChunkName: 'vp' */ '~/pages/VP/PriceList');
const ProductGenericPage = () => import(/* webpackChunkName: 'vp' */ '~/pages/VP/Generic');

const vp = [
  {
    path: '/:vp/pricelist',
    component: r(PriceListPage),
    name: 'vp_pricelist',
  },
  {
    path: '/daftar-harga-:vp',
    component: r(PriceListPage),
    name: 'product_pricelist',
  },
  {
    path: '/produk',
    component: r(ProductGenericPage),
    name: 'product_generic',
  },
];

export default vp;
