// promo page
import r from '~/utils/render-utils';

const PagePromo = () => import(/* webpackChunkName: 'promo' */ '~/pages/PromoPage');
const PagePromoDetail = () => import(/* webpackChunkName: 'promo-detail' */ '~/pages/Promo/detail');

const promo = [
  {
    path: '/promo/',
    component: r(PagePromo),
    children: [
      { path: ':id(\\d+)/:slug', component: r(PagePromoDetail), name: 'promo_detail' },
    ],
  },
];

export default promo;
