<template lang="pug">
nav.c-category-bar
  ul.c-category-list(ref="categoryList")
    CategoryItem(
      v-for="item in categories"
      :key="item.id"
      :text="item.name"
      :slug="item.slug"
      :isActive="$route.params.slug === item.slug"
    )
  .c-category-nav.c-category-nav--left(v-if="showNavLeft" @click="sideScroll('left')")
    SFIcon.c-category-nav__icon(icon="ico_chevron_left_minor")
  .c-category-nav.c-category-nav--right(v-if="showNavRight" @click="sideScroll('right')")
    SFIcon.c-category-nav__icon(icon="ico_chevron_right_minor")
</template>

<script>
import { mapGetters } from 'vuex';

import SFIcon from '@bukalapak/storefront-icon';
import CategoryItem from './CategoryItem';

export default {
  name: 'CategoryBar',
  components: {
    CategoryItem,
    SFIcon,
  },
  data() {
    return {
      showNavRight: false,
      showNavLeft: false,
    };
  },
  computed: {
    ...mapGetters({
      categories: 'article/getArticleCategories',
    }),
  },
  async mounted() {
    await this.$store.dispatch('article/retrieveCategories');
    this.handleScrollVisibility();
    this.$refs.categoryList.addEventListener('scroll', this.handleScrollVisibility.bind(this));
  },
  beforeDestroy() {
    this.$refs.categoryList.removeEventListener('scroll', this.handleScrollVisibility.bind(this));
  },
  methods: {
    sideScroll(direction) {
      const { categoryList } = this.$refs;
      const distance = 250;
      const modifier = direction === 'right' ? 1 : -1;

      categoryList.scrollBy({
        left: distance * modifier,
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScrollVisibility() {
      const { categoryList } = this.$refs;
      if (categoryList.scrollLeft > 0) {
        this.showNavLeft = true;
      } else {
        this.showNavLeft = false;
      }
      if (categoryList.scrollLeft < categoryList.scrollWidth - categoryList.offsetWidth) {
        this.showNavRight = true;
      } else {
        this.showNavRight = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.c-category {
  &-bar {
    position: relative;
    background-color: $background-washedout;
    min-height: 56px;
    padding: 0 1.42857rem;
  }
  &-list {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    max-width: $desktop-container-width;
    overflow-x: scroll;
    padding: 0;
    transition: all 0.3s ease-out;
    &::-webkit-scrollbar {
      display: none;
    }

    .c-category-item:first-child {
      text-align: justify;
      margin-left: 0;
    }
  }
  &-item {
    &:first-child {
      text-align: justify;
    }
  }
  &-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-4%, -55%);
    transform: translateY(-50%);
    background-color: #fff;
    padding: 4px;
    border-radius: 100%;
    box-shadow: 0 2px 9px 0 rgba(202, 202, 202, 0.5);
    width: 30px;
    height: 30px;
    &--left {
      left: calc(10% - 24px);
    }
    &--right {
      right: calc(10% - 24px);
    }
    &__icon {
      vertical-align: middle;
      fill: #d71149;
    }
  }
}

@media only screen and (max-width: $global-breakpoint) {
  .c-category {
    &-list {
      width: 90%;
      padding: 0;
    }
    &-bar {
      min-height: 50px;
      padding: 0;
    }
    &-nav {
      width: 24px;
      height: 24px;
      &__icon {
        width: 16px;
        height: 16px;
        vertical-align: baseline;
      }
    }
    &-item {
      &:first-child {
        padding-left: 0;
      }
    }
  }
}

// in between
@media (min-width: #{$global-breakpoint + 1}) and (max-width: #{$desktop-container-width - 1}) {
  .c-category {
    &-list {
      max-width: $global-breakpoint;
    }
  }
}

// in between
@media (min-width: #{$global-breakpoint + 1}) and (max-width: #{$desktop-container-width - 1}) {
  .c-category {
    &-list {
      max-width: $global-breakpoint;
    }
  }
}
</style>
