import { retrieveCategories, retrieveProducts } from '~/api/gt/products';
import { DISABLED_KEYWORD } from '~/utils/grosir-utils';
import { similarity } from '~/utils/string-utils';

export const state = () => ({
  categories: [],
  products: [],
  searchResultMeta: null,
});

export const getters = {
  getCategories(s) {
    return s.categories;
  },
  getProducts(s) {
    return s.products;
  },
  getSearchResultMeta(s) {
    return s.searchResultMeta;
  },
};

export const mutations = {
  setCategories(s, categories) {
    s.categories = categories;
  },
  setProducts(s, products) {
    s.products = products;
  },
  setSearchResultMeta(s, searchResultMeta) {
    s.searchResultMeta = searchResultMeta;
  },
  setChildrenCategory(s, { id, categories } = {}) {
    const category = s.categories.find(c => c.id === id);
    if (category && category.has_children) {
      category.children = categories;
      const index = s.categories.findIndex(c => c.id === category.id);
      s.categories = [
        ...s.categories.filter((c, i) => i < index),
        category,
        ...s.categories.filter((c, i) => i > index),
      ];
    }
    return s.categories;
  },
};

export const actions = {
  async retrieveCategories({ commit }, parentId) {
    try {
      const response = await retrieveCategories(parentId);
      const categories = response.data.filter(cat => cat.slug !== 'rokok');
      if (parentId) {
        commit('setChildrenCategory', { id: parentId, categories });
      } else {
        commit('setCategories', categories);
      }
    } catch (e) {
      //
    }
  },
  async retrieveProducts({ commit }, options) {
    const keyword = options.slug || options.keywords || '';
    const isDisabled = DISABLED_KEYWORD.some(key => similarity(key, keyword.toLowerCase()) > 0.55);
    if (isDisabled) {
      commit('setProducts', []);
      return;
    }
    try {
      const response = await retrieveProducts(options);
      commit('setProducts', response.data);
      commit('setSearchResultMeta', response.meta);
    } catch (e) {
      //
    }
  },
};
