<template lang="pug">
.navigation
  navigation-status(
    :message='notification'
    v-if='notification && notification.enabled'
  )
  navigation-mobile.mobile(
    :pageId='pageId'
    :pageName='pageName'
    :sectionMenu='sectionMenu'
    :useCompactLogo='useCompactLogo'
    :ctaId="ctaId"
    :downloadButtonId="downloadButtonId"
    :downloadCampaign="downloadCampaign"
    :ctaUrl="ctaUrl"
  )
  navigation-desktop.desktop(
    :pageId='pageId'
    :pageName='pageName'
    :sectionMenu='sectionMenu'
    :hasNotification='notification && notification.enabled'
  )
</template>

<script>
import NavigationStatus from './NavigationStatus.vue';
import NavigationMobile from './NavigationMobile.vue';
import NavigationDesktop from './NavigationDesktop.vue';

export default {
  name: 'Navigation',
  components: {
    NavigationStatus,
    NavigationMobile,
    NavigationDesktop
  },
  props: {
    sectionMenu: {
      type: Array,
      default: [],
    },
    pageId: {
      type: String,
      default: '',
    },
    pageName: {
      type: String,
      default: '',
    },
    notification: {
      type: Object,
      default: () => ({
        cta: '',
        url: '',
        icon: '',
        text: '',
        iconColor: '',
        enabled: false,
        backgroundColor: '',
      })
    },
    useCompactLogo: {
      type: Boolean,
      default: false
    },
    ctaId: {
      type: String,
      default: 'landing-nav-header',
    },
    ctaUrl: {
      type: String,
      default: 'https://app.adjust.com/hi0izwq',
    },
    downloadButtonId: {
      type: String,
      default: 'landing-nav-footer'
    },
    downloadCampaign: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .navigation {
    top: 0;
    width: 100%;
    z-index: 10;
    position: fixed;
  }
</style>
