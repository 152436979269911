<template lang="pug">
.c-footer__download
  p.u-txt--caption.u-txt--lighter.u-mrgn-bottom--2 Download aplikasinya sekarang
  a(
    :href="link"
    target="_blank"
    rel="noopener"
    data-testid="google-play-button-bottom"
  )
    BLBPicture(
      src="/images/3rd-parties/google-play-download.png"
      :imgStyle="{ width: '108px', height: '36px' }"
      title="Download via Google Play Store"
    )
</template>

<script>
import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'FooterDownloadMobile',
  components: {
    BLBPicture,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
