<template lang="pug">
.apponboard
  .apponboard_images
    BLBPicture.apponboard_image(
      v-for="(step, index) in steps"
      :key="index"
      :class="{ active: index === currentStep }"
      :src="step.image"
      :title="step.title"
      :lazy="true"
      :imgStyle="{ borderRadius: '10px' }"
    )
    BLBPicture.apponboard_device(src="/images/landing/howtouse/device.png" :lazy="true" title="Handphone Mitra")
  .apponboard_navs
    .apponboard_nav.prev(v-show="currentStep > 0" @click="startStepper(currentStep - 1)")
      SFIcon(icon="ico_chevron_left_minor")
    .apponboard_nav.next(v-show="currentStep < steps.length - 1" @click="startStepper(currentStep + 1)")
      SFIcon(icon="ico_chevron_right_minor")
  .apponboard_captions
    .apponboard_caption(
      v-for="(step, index) in steps"
      :key="index"
      :class="{ active: index === currentStep }"
      @click="startStepper(index)"
    )
      .apponboard_caption-text
        .apponboard_caption-title {{ index + 1 }}. {{ step.title }}
        .apponboard_caption-desc(v-html="step.desc")
  .apponboard_dots
    .apponboard_dot(
      v-for="(step, index) in steps"
      :key="index"
      :class="{ active: index === currentStep }"
      @click="startStepper(index)"
    )
</template>

<script>
import SFIcon from '@bukalapak/storefront-icon';
import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'AppOnboardingMobile',
  components: {
    BLBPicture,
    SFIcon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    init: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 0,
      stepIntervalTime: 1000 * 4, // 4 secs
      stepTimer: false,
      touchPointStart: 0,
      touchStarted: false,
    };
  },
  mounted() {
    if (this.init.index === this.init.tab) {
      this.startStepper(0);
    }
    this.$eventHub.$on(this.init.on, value => {
      if (this.init.index === value) {
        this.startStepper(0);
      } else {
        this.stopStepper();
      }
    });
    this.$nextTick(function () {
      this.$el.addEventListener('touchstart', this.onTouch, { passive: true });
      this.$el.addEventListener('touchend', this.onUntouch, { passive: true });
      this.$el.addEventListener('touchmove', this.onSwipe, { passive: true });
    });
  },
  destroyed() {
    this.$eventHub.$off(this.init.on);
    this.stopStepper();
    this.$el.removeEventListener('touchstart', this.onTouch, { passive: true });
    this.$el.removeEventListener('touchend', this.onUntouch, { passive: true });
    this.$el.removeEventListener('touchmove', this.onSwipe, { passive: true });
  },
  methods: {
    onTouch($event) {
      this.touchPointStart = $event.touches[0].clientX;
      // wait a quarter of a second to qualify that this is a real touch and not user scrolling
      setTimeout(() => {
        this.stopStepper();
      }, 250);
    },
    onUntouch() {
      this.touchPointStart = 0;
      this.touchStarted = false;
      this.startStepper(this.currentStep);
    },
    onSwipe($event) {
      if (!this.touchStarted) {
        this.touchStarted = true;
        const touchPointCurrent = $event.touches[0].clientX;
        let touchDirection = 0;
        // swipe right
        if (touchPointCurrent > this.touchPointStart) {
          touchDirection = 1;
          if (this.currentStep < this.steps.length - 1) {
            this.currentStep += 1;
          }
        }
        // swipe left
        if (touchPointCurrent < this.touchPointStart) {
          touchDirection = -1;
          if (this.currentStep > 0) {
            this.currentStep -= 1;
          }
        }
      }
    },
    startStepper(index) {
      this.stopStepper();
      this.currentStep = index;
      setTimeout(this.rotateStepper, this.stepIntervalTime);
    },
    stopStepper() {
      clearInterval(this.stepTimer);
      this.stepTimer = false;
    },
    rotateStepper() {
      this.stepTimer = setInterval(() => {
        this.currentStep = (this.currentStep + 1) % this.steps.length;
      }, this.stepIntervalTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.apponboard {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  &_images {
    position: relative;
    box-sizing: border-box;
    margin: 24px 80px 32px 80px;
    width: auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  &_image {
    z-index: 2;
    position: absolute;
    opacity: 0;
    transition: opacity 250ms ease-in;
    width: calc(100vw - (2 * 76px) - (2 * 3.7vw));
    height: auto;
    &.active {
      z-index: 3;
      opacity: 1;
    }
  }
  &_device {
    z-index: 1;
    width: 100%;
    height: auto;
  }
  &_captions {
    text-align: center;
    margin: 0 48px;
  }
  &_caption {
    display: none;
    &-number {
      color: #333;
      margin-right: 24px;
      font-size: 14px;
    }
    &-text {
      flex: 1;
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    &-desc {
      font-size: 16px;
    }
    &.active {
      display: block;
    }
  }
  &_dots {
    margin: 0 auto;
    margin-top: 16px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 135px;
    height: 8px;
  }
  &_dot {
    margin: 0 4px;
    background-color: #d71449;
    font-size: 8px;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    opacity: 0.2;
    transform: scale(0.8);
    transition: all 100ms ease-in;
    &.active {
      opacity: 1;
      transform: scale(1);
    }
  }
  &_navs {
    position: relative;
    top: 24px;
  }
  &_nav {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    color: #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.prev {
      left: 0;
      border-radius: 0 4px 4px 0;
    }
    &.next {
      right: 0;
      border-radius: 4px 0 0 4px;
    }
  }
}
</style>
