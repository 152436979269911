import r from '~/utils/render-utils';

const LoginPage = () => import(/* webpackChunkName: 'development' */ '~/pages/_dev/Login');

const routes = [
  {
    path: '/login',
    name: 'page_login',
    component: r(LoginPage),
  },
];

export default routes;
