import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const generateJourneyId = commit => {
  let journeyId = Cookies.get('journey_id');
  if (!journeyId) {
    journeyId = uuidv4();
    Cookies.set('journey_id', journeyId, {
      expires: 1 / 48, // 1day/48hours = 30minutes
    });
  }
  commit('setJourneyId', journeyId);
};

export const state = () => ({
  journeyId: '',
  userAgent: '',
  canonicalUrl: '',
});

export const getters = {
  getJourneyId: s => s.journeyId,
  getUserAgent: s => s.userAgent,
  getCanonicalUrl: s => s.canonicalUrl,
};

export const mutations = {
  setJourneyId(s, data) {
    s.journeyId = data;
  },
  setUserAgent(s, data) {
    s.userAgent = data;
  },
  setCanonicalUrl(s, data) {
    s.canonicalUrl = data;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch }, { req }) {
    dispatch('applyUserAgent', req);
    await dispatch('auth/assignCredentials', {
      login: !!req.cookies.user_credentials,
      identity: req.cookies.identity || req.session.identity,
    });
    await dispatch('auth/requestToken', req);
  },
  generateJourneyId({ commit }) {
    generateJourneyId(commit);
  },
  applyUserAgent({ commit }, req) {
    const userAgent = process.server ? req?.headers['user-agent'] : navigator.userAgent;
    commit('setUserAgent', userAgent);
  },
};
