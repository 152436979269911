import { mapGetters } from 'vuex';

const wrapper = {
  computed: {
    ...mapGetters({
      userAgent: 'getUserAgent',
    }),
    isBLMarketplaceWebView() {
      return /blandroid|blios/i.test(this.userAgent);
    },
    isBLMitraWebView() {
      return /BLMitraAndroid/i.test(this.userAgent);
    },
    isMitraLite() {
      return /BLMitraWrapper/i.test(this.userAgent);
    },
    isBLWebView() {
      return this.isBLMarketplaceWebView || this.isBLMitraWebView;
    },
    isiOSDevice() {
      return /iphone|ipad|ipod|blios/i.test(this.userAgent);
    },
  },
};

export default wrapper;
