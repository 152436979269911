import {
 retrieveArticle, retrieveHighlightsAndPopular, retrieveArticles, retrieveCategories, retrieveArticleAnchors
} from '~/api/article';

export const state = () => ({
  highlights: null,
  popular: null,
  articlesHome: null,
  articles: [],
  articlesCount: -1,
  articlesMeta: null,
  articlesLimit: 9,
  searchResult: [],
  searchResultCount: -1,
  isLoading: false,
  categories: null,
  anchors: []
});

export const getters = {
  getHighlights: localState => localState.highlights || [],
  getPopular: localState => localState.popular || [],
  getArticlesHome: localState => localState.articlesHome || [],
  getArticles: localState => localState.articles,
  getArticlesCount: localState => localState.articlesCount,
  getArticlesMeta: localState => localState.articlesMeta,
  getArticlesLimit: localState => localState.articlesLimit,
  isLoading: localState => localState.isLoading,
  getArticleCategories: localState => localState.categories,
  getArticleAnchors: localState => localState.anchors,
};

export const mutations = {
  setHighlights(localState, data) {
    localState.highlights = data;
  },
  setPopular(localState, data) {
    localState.popular = data;
  },
  setArticlesHome(localState, data) {
    localState.articlesHome = data;
  },
  setArticleAnchors(localState, data) {
    localState.anchors = data;
  },
  setArticles(localState, { data, count }) {
    localState.articles = data;
    localState.articlesCount = count;
  },
  setArticlesMeta(localState, newMeta) {
    localState.articlesMeta = newMeta;
  },
  setLoading(localState, status) {
    localState.isLoading = status;
  },
  setCategories(localState, data) {
    localState.categories = data;
  },
};

export const actions = {
  async retrieveHighlightsAndPopular({ commit }) {
    commit('setLoading', true);
    let highlights = [];
    let popular = [];
    try {
      const response = await retrieveHighlightsAndPopular();

      // retrieve each sections data
      const bannerArticlesComps = response.data.sections[0].components;
      const recommendedArticleComps = response.data.sections[1].components;

      // remove first array because it only contains section title
      bannerArticlesComps.shift();
      recommendedArticleComps.shift();

      // remap section component attributes into articles id
      const bannerArticleIds = bannerArticlesComps.map(comps => comps.attributes[0].value);
      const recommendedArticleIds = recommendedArticleComps.map(comps => comps.attributes[0].value);
      // fetch each article id
      const [bannerArticles, recommendedArticles] = await Promise.all([
        Promise.all(bannerArticleIds.map(articleId => {
          return retrieveArticle(articleId);
        })),
        Promise.all(recommendedArticleIds.map(articleId => {
          return retrieveArticle(articleId);
        }))
      ]);
      highlights = bannerArticles.map(item => ({ ...item, author: item.author.name })) || [];
      popular = recommendedArticles.map(item => ({ ...item, author: item.author.name })) || [];
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
    } finally {
      commit('setLoading', false);
    }
    commit('setHighlights', highlights);
    commit('setPopular', popular);
    return { highlights, popular };
  },
  async retrieveArticlesHome({ commit }) {
    commit('setLoading', true);
    let response = { data: [] };
    try {
      response = await retrieveArticles();
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
    } finally {
      commit('setLoading', false);
    }
    commit('setArticlesHome', response.data);
    return response.data;
  },
  async retrieveArticleAnchors({ commit }) {
    try {
      const response = await retrieveArticleAnchors();
      const refinedResponse = { data: response[0].data.anchors };
      const refinedMeta = { ...response.meta };
      delete refinedMeta.http_status;
      commit('setArticleAnchors', refinedResponse.data);
      return { ...refinedResponse, meta: refinedMeta };
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
      return {};
    }
  },
  async retrieveArticles({ commit }, params) {
    try {
      const response = await retrieveArticles(params);
      const refinedResponse = { data: response.data, count: response.meta.total };
      const refinedMeta = { ...response.meta };
      delete refinedMeta.http_status;
      commit('setArticles', refinedResponse);
      commit('setArticlesMeta', refinedMeta);
      return { ...refinedResponse, meta: refinedMeta };
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
      return {};
    }
  },
  async retrieveCategories({ commit, getters: thisGetters }) {
    if (thisGetters.getArticleCategories?.length >= 0) {
      return thisGetters.getArticleCategories;
    }
    commit('setLoading', true);
    try {
      const response = await retrieveCategories();
      // Filter empty category for now
      const categories = response.data.filter(i => !(['mitra', 'kisah-mitra'].includes(i.slug)));
      commit('setCategories', [
        {
          name: 'Semua Artikel',
          slug: ''
        },
        ...categories
      ]);
      return categories;
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
      return {};
    } finally {
      commit('setLoading', false);
    }
  },
};
