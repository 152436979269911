<template lang="pug">
  BLCRemodal(name="privacy-policy-modal", title="Kebijakan Privasi")
    p.u-align-justify
      | Kebijakan privasi yang dimaksud di Bukalapak adalah acuan yang mengatur dan melindungi penggunaan data dan informasi penting para Pengguna Bukalapak. Data dan informasi yang telah dikumpulkan pada saat mendaftar, mengakses, dan menggunakan layanan di Bukalapak, seperti alamat, nomor kontak, alamat e-mail, foto, gambar, dan lain-lain.
    p
      strong Kebijakan-kebijakan tersebut di antaranya:
    ul.u-align-justify
      li
        | Bukalapak tunduk terhadap kebijakan perlindungan data pribadi Pengguna sebagaimana yang diatur dalam  Peraturan Menteri Komunikasi dan Informatika Nomor 20 Tahun 2016 Tentang Perlindungan Data Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan data dan informasi penting para Pengguna.
      li
        | Bukalapak melindungi segala informasi yang diberikan Pengguna pada saat pendaftaran, mengakses, dan menggunakan seluruh layanan Bukalapak.
      li
        | Bukalapak melindungi segala hak pribadi yang muncul atas informasi mengenai suatu produk yang ditampilkan oleh pengguna layanan Bukalapak, baik berupa foto, username, logo, dan lain-lain.
      li
        | Bukalapak berhak menggunakan data dan informasi para Pengguna demi meningkatkan mutu dan pelayanan di Bukalapak.
      li
        | Bukalapak tidak bertanggung jawab atas pertukaran data yang dilakukan sendiri di antara Pengguna.
      li
        | Bukalapak hanya dapat memberitahukan data dan informasi yang dimiliki oleh para Pengguna bila diwajibkan dan/atau diminta oleh institusi yang berwenang berdasarkan ketentuan hukum yang berlaku, perintah resmi dari Pengadilan, dan/atau perintah resmi dari instansi atau aparat yang bersangkutan.
      li
        | Pengguna situs Bukalapak dapat berhenti berlangganan (
        em unsubscribe
        | ) beragam informasi promo terbaru dan penawaran eksklusif jika tidak ingin menerima informasi tersebut.

</template>

<script>
export default {
  name: 'PrivacyPolicyModal',
};
</script>

<style lang="scss" scoped>
</style>
