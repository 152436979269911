import r from '~/utils/render-utils';

const PageB2b = () => import(/* webpackChunkName: 'terms' */ '~/pages/B2bPage');
const PageB2bTerms = () => import(/* webpackChunkName: 'terms-b2b-mitra-desktop' */ '~/pages/B2b/Terms');

const b2b = [
  {
    path: '/b2b/',
    component: r(PageB2b),
    children: [
      { path: '/', redirect: 'terms' },
      { path: 'terms', component: r(PageB2bTerms), name: 'b2b_terms_page' },
    ],
  },
];

export default b2b;
