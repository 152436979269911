import r from '~/utils/render-utils';

const PageTerms = () => import(/* webpackChunkName: 'terms' */ '~/pages/Terms');
const PageTermsMitraDana = () => import(/* webpackChunkName: 'terms-mitra-dana' */ '~/pages/Terms/MitraDana');
const PageTermsDesktop = () => import(/* webpackChunkName: 'terms-mitra-desktop' */ '~/pages/Terms/TermsDesktop');

const terms = [
  {
    path: '/terms/',
    component: r(PageTerms),
    children: [
      { path: '/', component: r(PageTermsDesktop), name: 'terms_desktop' },
      { path: 'activation-mitra', component: r(PageTermsMitraDana), name: 'terms_mitra_dana' },
    ],
  },
];

export default terms;
