import Vue from 'vue';
import SFIcon from '@bukalapak/storefront-icon';

const component = {
  install(Vue, options) {
    Vue.component('SFIcon', SFIcon);
  },
};

Vue.use(component);
export default component;
