import { mapGetters } from 'vuex';

const canonical = {
  computed: {
    ...mapGetters({
      canonicalUrl: 'getCanonicalUrl',
    }),
    linkCanonical() {
      return this.canonicalUrl ? { href: this.canonicalUrl, rel: 'canonical' } : {};
    },
  },
};

export default canonical;
