<template lang="pug">
  BLCRemodal(name="juwara-modal", ref="juwara-modal", :showCloseBtn="false", size="medium", :overlayClickToClose="true")
    .o-layout
      .o-layout__item.u-align-left.mb-24
        .juwara-modal-flex-container 
          BlTextAv.u-txt--bold.juwara-modal-title(variation="subheading-18") {{ title }} 
          button.c-remodal__close_custom(ref="juwaraModalBtn" @click="closeModal" variation="secondary") X
        .juwara-modal-divider.pb-12
        .juwara-modal-img-container.pt-24
          BLBPicture(:src="imageUrl" :imgStyle="{ width: '100%' }" :webp="false")
        BlTextAv.u-txt--light.u-mrgn-top--8.u-mrgn-bottom--6.juwara-modal-content-text {{ desc }}
      .juwara-btn-bottom-container
        .u-align-right
          BlButtonAv.c-remodal__close.close-juwara-modal-btn(ref="juwaraModalTutupBtn" @click="closeModal" variation="outline") Tutup
          BlButtonAv.c-btn--red.handbook-modal-btn.ml-16(v-if="isPerpusJuwara" :href="checkHandbookUrl" target="_blank") Cek Handbook
</template>

<script>
import BLBPicture from '~/components/shared/base/BLBPicture';
import BLCRemodal from '~/components/shared/compound/BLCRemodal';
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';

export default {
  components: {
    BLBPicture,
    BLCRemodal,
    BlTextAv,
    BlFlexItemAv,
    BlFlexContainerMv,
    BlButtonAv,
  },
  props: {
    source: {
      type: String,
    },
    from: {
      type: String,
      required: true,
    },
    checkHandbookUrl: {
      type: String,
      default: '/promo-campaign/perpustakaan-juwara'
    }
  },
  methods: {
    closeModal() {
      this.$BLRemodal.hide('juwara-modal');
    },
  },
  computed: {
    title() {
      switch (this.source) {
        case 'whatsapp_juwara':
          return 'Whatsapp Group Komunitas Juwara';
        case 'program_juwara':
          return "Program Sultan Juwara dan Mentor Juwara";
        case 'halalbihalal':
          return 'Halalbihalal';
        case 'perpus_juwara':
          return 'Perpus Juwara';
        case 'skj':
          return 'Spesial Kumpul Juwara (SKJ)';
        case 'spekta':
          return 'SPEKTA (Spesial Kumpul Akhir Tahun Juwara)';
        case "juwita":
          return 'Juwara dalam Ingatan (JUWITA)';
        case 'semarak':
          return 'Spesial Kumpul Juwara Kemerdekaan (SEMARAK)';
      }
    },
    desc() {
      switch (this.source) {
        case 'whatsapp_juwara':
          return 'Grup Whatsapp menjadi media berinteraksi dan diskusi para Juragan yang digolongkan berdasarkan sebaran wilayah mitra dengan jumlah 150-300 mitra/kota.';
        case 'program_juwara':
          return 'Platform yang bisa Juragan gunakan untuk informasi terkait fitur, belajar bisnis (handbook), dan mengakses informasi event terbaru sebagai panduan untuk menjadi #warungnaikkelas.';
        case 'halalbihalal':
          return 'Ruang para Juragan bersilaturahmi setelah bulan Ramadhan dengan berbagi pengalaman dan kajian keagamaan secara online serentak di seluruh Indonesia';
        case 'perpus_juwara':
          return 'Platform yang bisa Juragan gunakan untuk informasi terkait fitur, belajar bisnis (handbook), dan mengakses informasi event terbaru sebagai panduan untuk menjadi #warungnaikkelas.';
        case 'skj':
          return 'Sebuah program offline gathering untuk meningkatkan kemampuan anggota Komunitas Juwara dengan tiga pilar utama Education, Empower, dan Engaging. SKJ berhasil dilaksanakan di 10 kota besar di seluruh Indonesia (2023).';
        case 'spekta':
          return 'Acara kumpul tahunan untuk mengapresiasi Juragan Warung Mitra se-Indonesia dan menjadi semangat baru menjalani awal tahun sebagai partner bisnis.';
        case 'juwita':
          return 'Perayaaan ulang tahun ke-5 komunitas Juwara dengan memberikan ruang bagi pemilik warung untuk berbagi pengalaman kepada sesama anggota Komunitas Juwara. Mitra berkesempatan untuk terlibat menjadi panitia acara.';
        case 'semarak':
          return 'Perayaan Hari Kemerdekaan yang melibatkan para mitra dengan kompetisi kostum dan video inspirasional sebagai sarana kreativitas para mitra.';
      }
    },
    imageUrl() {
      switch (this.source) {
        case "whatsapp_juwara":
          return '/images/tentang-kami/whatsapp_juwara.png';
        case 'program_juwara':
          return '/images/tentang-kami/program_juwara.png';
        case 'halalbihalal':
          return '/images/tentang-kami/halalbihalal.png';
        case 'perpus_juwara':
          return '/images/tentang-kami/perpus_juwara.png';
        case 'skj':
          return '/images/tentang-kami/skj.png';
        case 'spekta':
          return '/images/tentang-kami/spekta.png';
        case 'juwita':
          return '/images/tentang-kami/juwita.png';
        case 'semarak':
          return '/images/tentang-kami/semarak.png';
      }
    },
    isPerpusJuwara() {
      switch (this.source) {
        case "whatsapp_juwara":
        case 'program_juwara':
        case 'halalbihalal':
        case 'skj':
        case 'spekta':
        case 'juwita':
        case 'semarak':
          return false;
        case 'perpus_juwara':
          return true;
      }
    },
  }
};

</script>

<style lang="scss" scoped>
$modal-container-juwara: 600px;
$juwara-modal-img-container-padding-left: 92.5px;
$juwara-modal-img-container-padding-right: 92.5px;

@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.juwara-modal-title {
  line-height: $line-height-22;
}

.modal-container {
  max-width: $modal-container-juwara !important;
}

.juwara-modal-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.juwara-modal-content-text {
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: $line-height-24;
  letter-spacing: $space-0;
  text-align: left;
}

.c-remodal__close_custom {
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  opacity: 0.4;
  font-size: $font-size-24;
}

.close-juwara-modal-btn {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-20;
  letter-spacing: $space-0;
  text-align: left;
  background-color: $border-inverse !important;
  border: 1px solid $border-medium !important;
}

.handbook-modal-btn {
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $line-height-20;
  letter-spacing: $space-0;
  text-align: left;
}
</style>
