// grosir page
import r from '~/utils/render-utils';

const pageHome = () => import(/* webpackChunkName: 'grosir' */ '~/pages/Grosir/Home');
const pageProductList = () => import(/* webpackChunkName: 'grosir-product-list' */ '~/pages/Grosir/ProductList');
const pageSearch = () => import(/* webpackChunkName: 'grosir-search' */ '~/pages/Grosir/Search');

const grosir = [
  {
    path: '/grosir',
    component: r(pageHome),
    name: 'grosir_home',
    children: [
      {
        path: ':slug',
        component: r(pageProductList),
        name: 'grosir_category',
      },
      {
        path: ':parentslug/:slug',
        component: r(pageProductList),
        name: 'grosir_sub_category',
      },
    ],
  },
  {
    path: '/search',
    component: r(pageSearch),
    name: 'grosir_search',
  },
];

export default grosir;
