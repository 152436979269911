<template lang="pug">
  .tentang-kami-page-hero.u-position-relative
    navigation(:sectionMenu="tentangKamiSection" pageId="tentangKami")
    .tentang-kami-page-hero-section.hero-banner-section.flex
      hero-banner
    .tentang-kami-page-hero-padding
      .tentang-kami-page-video-section.video-pilihan-section.flex 
        video-pilihan
      .tentang-kami-page-produk-kami-section.produk-kami-section.flex 
        produk-kami
      .tentang-kami-page-visi-misi-section.visi-misi-section.flex 
        visi-misi
    .tentang-kami-page-perjalanan-mitra-section.perjalanan-mitra-section.flex 
      perjalanan-mitra
    .tentang-kami-page-hero-padding
      .tentang-kami-page-pencapaian-mitra-section.pencapaian-mitra-section.flex 
        pencapaian-mitra
      .tentang-kami-page-map-mitra-section.map-mitra-section.flex 
        map-mitra
      .tentang-kami-page-juwara-section.juwara-section.flex 
        juwara
      .tentang-kami-page-cta-section.cta-section.flex 
        cta
    .tentang-kami-page-footer
      NewFooter
</template>

<script>
// components
import Navigation from '~/components/Navigation/index.vue';
import Breadcrumb from "~/components/TentangKamiPage/Breadcrumb.vue";
import HeroBanner from "~/components/TentangKamiPage/HeroBanner.vue";
import VideoPilihan from "~/components/TentangKamiPage/VideoPilihan.vue";
import VisiMisi from "~/components/TentangKamiPage/VisiMisi.vue";
import ProdukKami from "~/components/TentangKamiPage/ProdukKamiSection/ProdukKami.vue";
import PerjalananMitra from "~/components/TentangKamiPage/PerjalananMitra.vue";
import PencapaianMitra from "~/components/TentangKamiPage/PencapaianMitra.vue";
import MapMitra from "~/components/TentangKamiPage/MapMitra.vue";
import Juwara from "~/components/TentangKamiPage/JuwaraSection/Juwara.vue";
import Cta from "~/components/TentangKamiPage/Cta.vue";
import NewFooter from "~/components/NewFooter/index.vue";

export default {
  name: 'TentangKamiPage',
  layout({ route }) {
    const layoutList = {
      agenlite_tentang_kami: 'default',
    };
    return layoutList[route.name] ?? 'default';
  },
  components: {
    Navigation,
    Breadcrumb,
    HeroBanner,
    VideoPilihan,
    ProdukKami,
    NewFooter,
    VisiMisi,
    PerjalananMitra,
    PencapaianMitra,
    MapMitra,
    Juwara,
    Cta,
  },
  computed: {
    tentangKamiSection() {
      return [
        {
          title: 'Keuntungan',
          url: '#benefit',
        },
        {
          title: 'Cara Penggunaan Aplikasi',
          url: '#howtouse',
        },
        {
          title: 'Testimonial',
          url: '#testimonies',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$produk-kami-section-height: 388px;
$produk-kami-section-height-mobile: 360px;
$video-section-padding-top: 60px;
$cta-section-margin-top: 100px;
$hero-padding-top: 65px;
$hero-section-content-margin-top: 65px;
$juwara-section-margin-top: 60px;


.tentang-kami-page-hero {
  padding-top: $hero-padding-top;
  overflow: hidden !important;

  &-section {
    &__content {
      margin-top: $hero-section-content-margin-top;
    }
  }
}

.tentang-kami-page-video-section {
  padding-top: $video-section-padding-top;
}

.produk-kami-section {
  height: $produk-kami-section-height;
}

.perjalanan-mitra-section {
  margin-top: $space-60;
}

.pencapaian-mitra-section {
  padding-top: $space-60;
}

.cta-section {
  margin-top: $cta-section-margin-top;
}

.visi-misi-section {
  margin-bottom: $space-60;
}

.juwara-section {
  margin-top: $space-60;
}

.map-mitra-section {
  margin-top: $space-60;
}

.tentang-kami-page-hero-padding {
  padding-left: 82px;
  padding-right: 82px;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .tentang-kami-page-hero-padding {
    padding-left: $space-20;
    padding-right: $space-20;
  }

  .produk-kami-section {
    margin-bottom: $space-20;
  }

  .tentang-kami-page-video-section {
    padding-top: 80px;
  }

  .produk-kami-section {
    height: $produk-kami-section-height-mobile;
  }

}
</style>
