<template lang="pug">
bl-flex-container-mv.navigation__status#navigation-status(
  alignItems="center"
  justifyContent="center"
  :style="`background-color: ${message.backgroundColor}`"
)
  bl-flex-item-av(width="16px")
    bl-icon-av.mt-4(
      :name="message.icon"
      :color="message.iconColor"
    )
  bl-text-av.ml-12(v-html="message.text")
  bl-button-av.desktop.ml-8(variation="link" target="_blank" size="small" :href="message.url") {{ message.cta }}
  bl-button-av.mobile.ml-16(variation="link" target="_blank" size="small" :href="message.url")
    bl-icon-av(name="ico_chevron_right_minor" color="#2E2E2E")
</template>

<script>
import { BlIconAv } from "@bukalapak/bazaar-visual";
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlButtonAv from "@bukalapak/bazaar-mweb/dist/components/BlButtonAv";
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";

export default {
  name: "NavigationStatus",
  props: {
    message: {
      type: Object,
      default: () => ({
        cta: "",
        url: "",
        icon: "",
        text: "",
        iconColor: "",
        enabled: false,
        backgroundColor: "", 
      }),
    },
  },
  components: {
    BlIconAv,
    BlTextAv,
    BlButtonAv,
    BlFlexItemAv,
    BlFlexContainerMv,
  },
};
</script>

<style lang="scss" scoped>
.navigation__status {
  z-index: 10;
  padding: 8px;
  @media only screen and (max-width: $global-breakpoint) {
    padding: 16px;
  }
}
</style>
