<template lang="pug">
client-only
  .banner-fixed(:class="{ 'banner-fixed__closed': isClosed }")
    SFIcon.banner-fixed__close(icon="ico_cross_minor" @click.native="closeBanner")
    .banner-fixed__image
      slot
    .banner-fixed__background
</template>

<script>
import SFIcon from '@bukalapak/storefront-icon';

export default {
  name: 'BannerFixed',
  components: {
    SFIcon,
  },
  data() {
    return {
      isClosed: false,
    };
  },
  methods: {
    closeBanner($event) {
      this.isClosed = true;
      this.$emit('onClose', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
$bg-margin: -10px;

.banner-fixed {
  position: fixed;
  z-index: 3;
  bottom: 0;
  background-color: #fff;
  max-height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 250ms ease-in;
  &__closed {
    bottom: -72px;
  }
  &__image,
  a {
    z-index: 2;
    display: block;
    max-width: 360px;
    max-height: 72px;
  }
  &__close {
    position: absolute;
    left: 6px;
    z-index: 3;
  }
  &__background {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #ebcfd0;
  }
}
</style>
