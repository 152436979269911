const urlHelper = {
  methods: {
    getUTMVariable(string) {
      if (process.server) return false;
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=');
        if (pair[0] === string) { return pair[1]; }
      }
      return false;
    },
  },
};

export default urlHelper;
