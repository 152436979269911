<template lang="pug">
.c-testimony__content.o-container.u-align-center
  .testimony-title.u-pad-bottom--10
    h2.u-txt--xxxlarge.u-txt--lighter Apa kata mereka tentang Mitra Bukalapak?
  .o-layout.u-mrgn-v--10.u-mrgn-h--0
    .o-layout__item.u-1of3.u-mrgn-v--6.u-pad-h--3(v-for="testimony in landingTestimonies", :key="testimony.name")
      .testimony-card
        .testimony-card__image
          BLBPicture(:src="testimony.image", :lazy="true", :title="trimHTML(`Foto ${testimony.name}`)")
        .testimony-card__content
          p.u-txt--caption &ldquo;{{ testimony.content }}&rdquo;
          .testimony-card__content-identity
            h6.u-txt--light.u-mrgn-bottom--1(v-html="testimony.name")
            .u-txt--lighter.u-fg--ash-dark &plusmn; {{ testimony.revenue | monefy }}/bulan
  .user-mapping.u-pad-top--10
    h2.user-mapping__title.u-txt--xxxlarge.u-txt--lighter.u-mrgn-bottom--10 Jutaan pengguna telah bergabung menjadi Mitra Bukalapak
    .user-mapping__maps.u-pad-top--5
      BLBPicture(
        src="/images/landing/mapsid.png",
        :imgStyle="{ width: '100%' }",
        :lazy="true"
        title="Peta sebaran pengguna Mitra Bukalapak"
      )
</template>

<script>
import { mapGetters } from 'vuex';
import BLBPicture from '~/components/shared/base/BLBPicture';
import { trimHTML } from '~/utils/string-utils';

// mixins
import moneyFilter from '~/mixins/money-filter';

export default {
  name: 'TestimonySectionDesktop',
  components: {
    BLBPicture,
  },
  mixins: [moneyFilter],
  computed: {
    ...mapGetters({
      landingTestimonies: 'landing/landingTestimonies',
    }),
  },
  methods: {
    trimHTML,
  },
};
</script>

<style lang="scss" scoped>
.c-testimony__content {
  width: 90%;
  max-width: 1300px;
}
.testimony-card {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 20px 60px 0 rgba(177, 187, 203, .2);
  position: relative;
  padding: 24px;
  height: 244px;

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60px;
  }

  &__content {
    margin-top: 50px;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.user-mapping {
  &__title {
    margin: auto;
  }
}
</style>
