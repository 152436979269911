<template lang="pug">
.c-footer__cta
  h2.c-landing-footer__title.u-txt--lighter.u-txt--center.u-mrgn-bottom--5 {{ title }}
  nuxt-link.c-btn.c-btn--block.c-btn--large.c-btn--red(
    ref="startButton",
    :to="buttonLink"
    @click.native="handleClickCTA($event, 'Mulai', buttonLink)"
  ) Coba Sekarang
</template>

<script>
import { mapGetters } from 'vuex';

// mixins
import tracker from '~/mixins/tracker';

export default {
  name: 'FooterCTAMobile',
  mixins: [tracker],
  props: {
    utmSource: {
      type: String,
      default: 'mobile_footer',
    },
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
    }),
    title() {
      return this.isUserLoggedIn ? 'Ayo jadi Mitra Bukalapak dan nikmati keuntungannya!' : 'Biar yakin jadi Mitra Bukalapak, coba dulu keuntungannya!';
    },
    buttonLink() {
      return '/beranda';
    },
  },
  methods: {
    handleClickCTA(e, label, url) {
      const btnName = label.toLowerCase().replace(/\s+/g, '_');
      this.trackGoToHomepage(e, `button_${btnName}_${this.utmSource}`, url);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-landing-footer__title {
  font-size: 20px;
}
</style>
