import { money, simpleMoney, delimiter, stripMoney, currencyMask } from '~/server/helpers/number-helper';

const moneyFilter = {
  filters: {
    monefy(number) {
      if (Number.isNaN(number)) return 0;
      return money(number);
    },
    simpleMonefy(number, options) {
      if (Number.isNaN(number)) return 0;

      if (options && options.whenMoreThan && !Number.isNaN(options.whenMoreThan)) {
        return number < options.whenMoreThan ? money(number) : simpleMoney(number);
      }

      return simpleMoney(number);
    },
    unMonefy(moneyText) {
      return stripMoney(moneyText);
    },
  },
  methods: {
    currencyMask,
    stripMoney,
    money,
    simpleMoney,
    delimiter,
  },
};

export default moneyFilter;
