import {
  retrieveReferralMessages
} from '~/api/referral';

const defaultReferralMessageValue = {
  cta: "",
  url: "",
  icon: "",
  text: "",
  iconColor: "",
  enabled: false,
  backgroundColor: "",
};

export const state = () => ({
  message: { ...defaultReferralMessageValue }
});

export const getters = {
  getReferralMessage: localState => localState.message || { ...defaultReferralMessageValue },
};

export const mutations = {
  setReferralMessage(localState, data) {
    localState.message = data;
  },
};

export const actions = {
  async retrieveReferralMessages({ commit }) {
    commit('setLoading', true);
    let referralMessage = { ...defaultReferralMessageValue };
    try {
      const response = await retrieveReferralMessages();
      referralMessage = response[0].data;
    } catch (error) {
      this.dispatch('error-handler/catchError', { error });
    } finally {
      commit('setReferralMessage', referralMessage);
      commit('setLoading', false);
    }
    return referralMessage;
  },
};
