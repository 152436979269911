export const HOMEPAGE = 'homepage';
export const FAQ = 'faq';
export const AGENT_GROSIR = 'agen-grosir';
export const AGENT_PPOB = 'agen-ppob';
export const AGENT_TABUNGAN_EMAS = 'agen-emas';
export const AGENT_DROPSHIP = 'agen-dropship';
export const AGENT_PENGIRIMAN = 'agen-ekspedisi';
export const BERANDA = 'beranda';
export const DAFTAR_HARGA_PULSA = 'daftar-harga-pulsa';
export const DAFTAR_HARGA_PAKET_DATA = 'daftar-harga-paket-data';
export const DAFTAR_HARGA_TOKEN_LISTRIK = 'daftar-harga-token-listrik';
export const PRODUK = 'produk';
