<template lang="pug">
  .c-landing-page
    LandingPageHeroDesktop.desktop
    LandingPageHeroMobile.mobile
</template>

<script>
import LandingPageHeroDesktop from './desktop';
import LandingPageHeroMobile from './mobile';

export default {
  name: 'LandingPageHero',
  components: {
    LandingPageHeroDesktop,
    LandingPageHeroMobile,
  },
};
</script>
