import { retrieveMitraTerms } from '~/api/terms';

export const actions = {
  async fetchMitraTerms(_, { slug }) {
    let response = null;
    try {
      response = await retrieveMitraTerms(slug);
    } catch (error) {
      this.dispatch('error-handler/catchError', { error, message: '', hideAppLoading: true, noFlashAlert: true });
    }
    return response;
  },
};
