<template lang="pug">
  .c-flipcards
    ul.c-flipcards__nav(ref="nav")
      li(@click="flipBy(1, $event)")
        SFIcon(icon="ico_chevron_left_minor")
      li(@click="flipBy(-1, $event)")
        SFIcon(icon="ico_chevron_right_minor")
    .c-flipcards__wrap(:style="{ transform: rotation}")
      slot
    ul.c-flipcards__marker
      li(v-for="index in cardLength" :class="{ active: index === currentCard }" @click="flipTo(index)")
</template>

<script>
import SFIcon from '@bukalapak/storefront-icon';

export default {
  name: 'BLFlipCards',
  components: {
    SFIcon,
  },
  props: {
    dir: {
      type: String,
      default: 'horizontal',
    },
  },
  data: () => ({
    currentFlip: 0,
    currentCard: 1,
    cardLength: 0,
    rotateDegree: 0,
    rotation: {},
  }),
  mounted() {
    const flipcards = this.$slots.default;
    this.cardLength = flipcards.length;
    this.rotateDegree = 360 / this.cardLength;
    flipcards.forEach((card, i) => {
      card.elm.classList.add('c-flipcard');
      card.elm.style.zIndex = this.cardLength - i;
      if (this.dir === 'vertical') {
        card.elm.style.transform = `rotateX(${this.rotateDegree * i}deg)`;
      } else {
        card.elm.style.transform = `rotateY(${this.rotateDegree * i}deg)`;
      }
    });
  },
  methods: {
    flip() {
      this.currentCard = Math.abs(this.currentFlip % this.cardLength) + 1;
      if (this.dir === 'vertical') {
        this.rotation = `rotateX( ${this.currentFlip * this.rotateDegree}deg)`;
      } else {
        this.rotation = `rotateY( ${this.currentFlip * this.rotateDegree}deg)`;
      }
      this.$emit('onFlip');
      this.flipNav();
    },
    flipNav() {
      const nav = this.$refs.nav.classList;
      nav.add('onFlip');
      setTimeout(() => {
        nav.remove('onFlip');
      }, 250);
    },
    flipBy(index) {
      this.currentFlip += index;
      this.flip();
    },
    flipTo(index) {
      if (this.currentCard > index) {
        this.currentFlip += (this.currentCard - index);
      } else if (this.currentCard < index) {
        this.currentFlip -= (index - this.currentCard);
      }
      this.flip();
    },
    flipToExact(index) {
      this.currentFlip = index * -1;
      this.flip();
    },
  },
};
</script>

<style lang="scss" scoped>
$nav-size: 32px;
$flip-time: 150ms;

.c-flipcards {
  width: 100%;
  height: 0;
  padding-top: calc(360 / 288 * 100%);
  perspective: 1000vw;
  position: relative;
  &__wrap {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: $flip-time;
    transition-timing-function: ease-out;
    transform-style: preserve-3d;
    & > div {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      background-color: #fff;
      box-sizing: border-box;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      border-radius: 16px;
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__nav {
    z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transition: opacity $flip-time ease-out;
    transform: translateZ(1px);
    &.onFlip {
      transition: none;
      opacity: 0;
    }
    li {
      width: $nav-size;
      height: $nav-size;
      margin: 0;
      padding: 0;
      color: #fff;
      background-color: #ddd;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__marker {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 30px;
    display: flex;
    z-index: 2;
    transform: translateZ(1px);
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: all $flip-time ease-out;
    li {
      padding: 0;
      margin: 0 6px;
      width: 6px;
      height: 6px;
      line-height: 6px;
      border-radius: 6px;
      background-color: #d71449;
      opacity: 0.2;
      &.active {
        width: 8px;
        height: 8px;
        opacity: 1;
      }
    }
  }
}
</style>
