const defaultRoute = {
  name: 'beranda',
  params: {},
};

export const state = () => ({
  features: [
    { name: 'Pulsa', icon: 'phone_balance' },
    { name: 'Paket Data', icon: 'phone_data' },
    { name: 'Token Listrik', icon: 'token-listrik' },
    { name: 'Tagihan Listrik', icon: 'tagihan-listrik' },
    { name: 'BPJS', icon: 'bpjs-kesehatan' },
    { name: 'PDAM', icon: 'pdam' },
    { name: 'Grosir', icon: 'cart' },
    { name: 'Kirim Uang', icon: 'redeem' },
  ],
  testimonies: [
    {
      image: '/images/landing/neni.png',
      content:
        'Saya buka warung belum lama dan langsung gabung dengan Mitra Bukalapak. Alhamdulillaah omset saya ikut naik.',
      name: 'Ibu <b>Neni Suryani</b>',
      revenue: 16500000,
    },
    {
      image: '/images/landing/zaelani.png',
      content:
        'Alhamdulillaah, warung biasa jadi luar biasa, ada bayar BPJS, listrik, dll. Mudah, praktis, untungnya lumayan besar.',
      name: 'Bapak <b>Zaelani</b>',
      revenue: 125000000,
    },
    {
      image: '/images/landing/lia.png',
      content: 'Beli stok warung praktis. Tinggal klik dan nunggu barangnya sampai di warung.',
      name: 'Ibu <b>Lia Subono</b>',
      revenue: 10000000,
    },
  ],
  benefits: [
    {
      key: 'grosir',
      product: 'Grosir',
      benefit: 'Lengkap, termurah, bisa COD, area jangkauan luas',
      route: { name: 'grosir_home' },
      icon: '/images/benefit-dope/ic_mitragrosir_dope_regular.png',
    },
    {
      key: 'pulsa',
      product: 'Pulsa',
      benefit: 'Harga ekonomis, jaminan uang kembali',
      route: { name: 'product_pricelist', params: { vp: 'pulsa' } },
      icon: '/images/benefit-dope/ic_pulsaprabayar_dope_regular.png',
    },
    {
      key: 'paket_data',
      product: 'Paket Data',
      benefit: 'Voucher langsung atau fisik termurah',
      route: { name: 'product_pricelist', params: { vp: 'paket-data' } },
      icon: '/images/benefit-dope/ic_paketdata_dope_regular.png',
    },
    {
      key: 'token_listrik',
      product: 'Token Listrik',
      benefit: 'Potongan harga langsung',
      route: { name: 'product_pricelist', params: { vp: 'token-listrik' } },
      icon: '/images/benefit-dope/ic_tokenlistrik_dope_regular.png',
    },
    {
      product: 'Top Up Digital',
      benefit: 'Bisa ke DANA, GoPay, LinkAja, OVO, ShopeePay',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_topupdigital_dope_regular.png',
    },
    {
      key: 'game',
      product: 'Voucher Game',
      benefit: 'Semudah jualan pulsa, bisa bagikan daftar harga',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_games_dope_regular.png',
    },
    {
      key: 'emoney',
      product: 'Uang Elektronik',
      benefit: 'Top up saldo e-money lebih mudah',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_uangelektronik_dope_regular.png',
    },
    {
      key: 'transfer',
      product: 'Kirim Uang',
      benefit: 'Bisa edit struk dan kirim uang ke 60+ bank',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_kirimuang_dope_regular.png',
    },
    {
      key: 'qris',
      product: 'QRIS',
      benefit: 'Terima pembayaran dari berbagai dompet digital',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_qris_dope_regular.png'
    },
    {
      key: 'pln',
      product: 'Tagihan Listrik PLN',
      benefit: 'Biaya admin murah, komisi hingga Rp2.500/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_tagihanlistrik_dope_regular.png',
    },
    {
      key: 'bpjs',
      product: 'BPJS Kesehatan',
      benefit: 'Biaya admin murah, komisi Rp800/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_bpjs_dope_regular.png',
    },
    {
      key: 'pdam',
      product: 'PDAM',
      benefit: 'Biaya admin murah, komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_pdam_dope_regular.png'
    },
    {
      key: 'telkom',
      product: 'Telkom/Indihome',
      benefit: 'Biaya admin murah, komisi Rp750/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_telkom_dope_regular.png',
    },
    {
      key: 'installment',
      product: 'Angsuran Kredit',
      benefit: 'Biaya admin murah, komisi Rp1.000/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_bukacicilan_dope_regular.png',
    },
    {
      key: 'samsat',
      product: 'e-Samsat',
      benefit: 'Komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_samsat_dope_regular.png',
    },
    {
      key: 'penerimaan_negara',
      product: 'Penerimaan Negara',
      benefit: 'Komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_penerimaannegara_dope.png',
    },
    {
      key: 'emas',
      product: 'Tabungan Emas',
      benefit: 'Dapat komisi tiap transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_tabungan_emas_dope_regular.png',
    },
    {
      key: 'ekspedisi',
      product: 'Agen Ekspedisi/ Bukasend',
      benefit: 'Gratis pickup barang & admin, komisi hingga 26%',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_agenbukasend_dope_regular.png',
    },
    {
      key: 'bpjs_tk',
      product: 'BPJS Ketenagakerjaan',
      benefit: 'Proses mudah dan resmi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_bpjs_tenaga_kerja_dope_regular.png',
    },
    {
      key: 'tv',
      product: 'TV Kabel & Internet',
      benefit: 'Lengkap dan ada komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_tv_kabel_dope_regular.png',
    },
    {
      key: 'pulsa_pascabayar',
      product: 'Pulsa Pascabayar',
      benefit: 'Lengkap dan ada komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_pulsa_pascabayar_dope_regular.png',
    },
    {
      key: 'pbb',
      product: 'PBB',
      benefit: 'Komisi Rp700/transaksi',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_pajak_pbb_dope_regular.png',
    },
    {
      key: 'zakat',
      product: 'Zakat',
      benefit: 'Proses mudah dan lewat lembaga terpercaya',
      route: defaultRoute,
      icon: '/images/benefit-dope/ic_zakat_dope_regular.png',
    },
  ],
  provincePlaceId: {
    'ChIJvcR8UN-bOTARYMogsoCdAwE': 'Aceh',
    ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ: 'Bali',
    ChIJmbkNxNaKQS4R6bMai6ua074: 'Banten',
    ChIJeZLjNx6wNi4R4bGjxGX7HFE: 'Bengkulu',
    ChIJSa0hzCXAcTIRIBr3yvsLAwE: 'Gorontalo',
    ChIJnUvjRenzaS4RILjULejFAAE: 'Jakarta',
    'ChIJ2aytzd2IJS4R8E-Q0103c3Q': 'Jambi',
    ChIJf0dSgjnmaC4Rfp2O_FSkGLw: 'Jawa Barat',
    ChIJ3RjVnJt1ZS4RRrztj53Rd8M: 'Jawa Tengah',
    ChIJxbXun_eToy0RULh8yvsLAwE: 'Jawa Timur',
    ChIJu_7rjBcYBS4RoEghTO3sXM0: 'Kalimantan Barat',
    ChIJRbTSvTm33S0RE8GXt1C2fhQ: 'Kalimantan Selatan',
    ChIJP5a8hrK_4i0Rrmv1g2fV288: 'Kalimantan Tengah',
    ChIJkZxNlhBH8S0R13bjLx2wq8Q: 'Kalimantan Timur',
    ChIJ9wvfNH0GDzIRiLlGaN3wERk: 'Kalimantan Utara',
    ChIJizmlLUMWFy4RuSOEsf04fhI: 'Kepulauan Bangka Belitung',
    ChIJAQuH1E1l2TERvCSFiXW1RnI: 'Kepulauan Riau',
    ChIJpyKsUwF2Oy4RmrCJX8dYO48: 'Lampung',
    ChIJ36EccLq8ES0RUZpkBNvoMF4: 'Maluku',
    ChIJszIkro6uni0RwBr3yvsLAwE: 'Maluku Utara',
    ChIJIe0SGpQNuC0RxXX30MzCZ2k: 'Nusa Tenggara Barat',
    ChIJlzbpqE3yUiwR4Br3yvsLAwE: 'Nusa Tenggara Timur',
    'ChIJc5L_qgQsO2gRc-bvXpxOqes': 'Papua',
    ChIJLQviub0KVC0RYsvHxfjBSVM: 'Papua Barat',
    ChIJdz6xGVhXJy4Rsb21bJQCb4M: 'Riau',
    ChIJCUS7VCTaki0R8nAzLyC_XOo: 'Sulawesi Barat',
    ChIJi75r_YD6DC0R8Br3yvsLAwE: 'Sulawesi Selatan',
    ChIJPS2aZckJiC0RmWLbjP0zbkM: 'Sulawesi Tengah',
    ChIJg3FrC97smC0R7aQEn0N8HWI: 'Sulawesi Tenggara',
    ChIJMZ4GcEJ1hzIRNbgMmBcWiUU: 'Sulawesi Utara',
    ChIJRUJ08Ey51C8RVTvVdblRsXA: 'Sumatera Barat',
    ChIJLeo1PXWLEC4Rz8QB4gGB_Bg: 'Sumatera Selatan',
    'ChIJhxxy61r51y8RC8vXCYE-p6w': 'Sumatera Utara',
    ChIJxWtbvYdXei4R8LPIyrKSG20: 'Yogyakarta',
  },
});

export const getters = {
  landingFeatures: s => s.features,
  landingTestimonies: s => s.testimonies,
  landingBenefits: s => s.benefits,
  getProvincePlaceId: s => s.provincePlaceId,
};
