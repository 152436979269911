<template lang="pug">
  .bl-timeline
    .bl-timeline__container.mr-24(
      v-for="(item, index) in timeline"
      :key="index"
    )
      BlTextAv.bl-timeline__title_margin.mb-4(v-html="item.year" variation="body-16" weight="bold")
      .bl-timeline__dot.bl-timeline__dot--active.ml-4
      .bl-timeline__container_text
        BlTextAv.bl-timeline__title_bottom(v-html="item.title" variation="body-14" weight="regular")
</template>

<script>
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";

export default {
  name: 'BlTimelineMv',
  components: {
    BlTextAv,
  },
  props: {
    timeline: {
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style lang="scss">
@import '~@bukalapak/bazaar-token/dist/tokens.scss';
$container-text-max-width: 216px;
$container-text-height: 160px;
$bl-timeline-left: 82px;

.bl-timeline {
  position: relative;
  left: $bl-timeline-left;

  &__container {
    display: inline-block;
    vertical-align: text-bottom;
    max-width: $container-text-max-width;

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  &__dot {
    position: absolute;
    top: $space-40;
    width: $space-12;
    height: $space-12;
    background-color: $background-low;
    margin: auto;
    border-radius: $border-radius-circle;
    z-index: $z-index-default;

    &--active {
      background-color: #fff;
      transform: scale(1.75);
      box-shadow: inset 0 0 0 2px $brand-01;

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 70%;
        height: 70%;
        margin: auto;
        background-color: $border-inverse;
        border-radius: $border-radius-circle;
        content: '';
      }
    }
  }

  &__title_margin {
    line-height: $line-height-20;
    text-align: left;
    position: relative;

    &:before {
      position: absolute;
      top: 45px;
      width: calc(100% + 26px);
      height: $space-2;
      margin: auto;
      background-color: #fff;
      content: ''
    }
  }

  &__title_bottom {
    line-height: $line-height-22;
    text-align: left;
  }

  &__container_text {
    padding-top: $space-40;
    height: $container-text-height;
    white-space: normal;
  }
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .bl-timeline {
    left: $space-20;
  }
}
</style>
