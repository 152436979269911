<template lang="pug">
.c-testimony-mobile
  .c-testimony__content
    h2.u-txt--lighter.u-align-center.u-mrgn-bottom--6 Apa Kata Mereka
      br
      | Tentang Mitra Bukalapak
    .testimony-map(:class="{ active: testimonyOpen }")
      .testimony-map__maps
        .testimony-map__previews
          .testimony-map__preview(
            v-for="(testimony, index) in landingTestimonies"
            :key="testimony.image"
            @click="openTestimony($event, index)"
          )
            BLBPicture.testimony-map__preview--image(
              :src="testimony.image"
              :lazy="true"
              :title="trimHTML(`Foto ${testimony.name}`)"
            )
            .testimony-map__preview--text.o-flag
              span.o-flag__body.u-4of4
                p.u-txt--tiny.u-mrgn-bottom--1(v-html="testimony.name")
                p.u-txt--tiny.u-mrgn--0 &plusmn; {{ testimony.revenue | monefy }}/bulan
              span.o-flag__body
                SFIcon(icon="ico_chevron_right_minor")
        BLBPicture.testimony-map__previews--image(
          :src="`/images/landing/maps/${area}.png`"
          :imgStyle="{ width: '100%' }"
          :lazy="true"
          title="Peta lokasi mitra"
        )
      .testimony-map__testimonials
        SFIcon.c-icon--close(icon="ico_cross_minor" ref="flipcardsCloseButton" @click.native="closeTestimony")
        BLFlipCards(dir="horizontal" ref="BLFlipCardsComponent" @onFlip="closeButtonOnFlip")
          .card(v-for="(testimony, index) in landingTestimonies" :key="testimony.image")
            h6.u-txt--bold Testimoni
            BLBPicture.testimony-card.u-mrgn-top--6.u-mrgn-bottom--4(
              :src="testimony.image"
              :lazy="true"
              :title="trimHTML(`Foto ${testimony.name}`)"
            )
            .testimony-card--text
              .u-mrgn-bottom--2(v-html="testimony.name")
              .u-txt--lighter.u-mrgn-bottom--8 &plusmn; {{ testimony.revenue | monefy }}/bulan
              p.u-txt--small.u-txt--lighter &ldquo;{{ testimony.content }}&rdquo;
</template>

<script>
import { mapGetters } from 'vuex';
import SFIcon from '@bukalapak/storefront-icon';
import BLFlipCards from '~/components/shared/BLFlipCards';
import BLBPicture from '~/components/shared/base/BLBPicture';
import { trimHTML } from '~/utils/string-utils';

// mixins
import moneyFilter from '~/mixins/money-filter';
import tracker from '~/mixins/tracker';

export default {
  name: 'TestimonySectionMobile',
  components: {
    BLBPicture,
    BLFlipCards,
    SFIcon,
  },
  mixins: [moneyFilter, tracker],
  data: () => ({
    alternative: 'old',
    location: 'Jakarta',
    testimonyOpen: false,
    geocoder: null,
  }),
  computed: {
    ...mapGetters({
      landingTestimonies: 'landing/landingTestimonies',
      getProvincePlaceId: 'landing/getProvincePlaceId',
    }),
    area() {
      return this.location.toLowerCase().replace(/\s+/g, '-');
    },
  },
  async mounted() {
    // init geocoder
    await this.$gmapApiPromiseLazy();
    this.geocoder = new window.google.maps.Geocoder();
    await this.getLocation();
  },
  methods: {
    trimHTML,
    async getLocation() {
      try {
        const coords = await this.$store.dispatch('user-address/getUserCurrentGeolocation', {
          highAccuracy: false,
        });

        if (this.geocoder && coords) this.parseLocation(coords);
      } catch (err) {
        // should have log error to honeybadger or any other logger
        console.warn(err);
      }
    },
    parseLocation(coords) {
      const latlng = new window.google.maps.LatLng(coords.latitude, coords.longitude);
      this.geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status === 'OK') {
          const state = results.filter(result => result.types.indexOf('administrative_area_level_1') > -1);
          if (state.length > 0) {
            const placeId = state[0].place_id;
            this.location = this.getProvincePlaceId[placeId] || this.location;
          }
        }
      });
    },
    openTestimony($event, index) {
      this.trackLandingSection($event, 'testimony', this.location);
      this.$refs.BLFlipCardsComponent.flipToExact(index);
      this.testimonyOpen = true;
    },
    closeTestimony() {
      this.testimonyOpen = false;
    },
    closeButtonOnFlip() {
      const flipcardsCloseButton = this.$refs.flipcardsCloseButton.$el.classList;
      flipcardsCloseButton.add('onFlip');
      setTimeout(() => {
        flipcardsCloseButton.remove('onFlip');
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
$flip-time: 150ms;
.c-testimony__content {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px 8px;
  overflow-x: hidden;
}
.testimony-map {
  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding-top: calc(360 / 288 * 100%);
  position: relative;
  &__maps,
  &__testimonials {
    transition: all 250ms ease-in;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__maps {
    box-shadow: 2px 2px 8px 0 rgba(187, 187, 187, 0.5);
    transform: translateX(0);
    opacity: 1;
    z-index: 2;
    border-radius: 8px;
    overflow: hidden;
    .testimony-map__previews--image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .testimony-map__previews {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 16px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-shadow: 2px 2px 8px 0 rgba(187, 187, 187, 0.5);
    }
    .testimony-map__preview {
      &:active {
        box-shadow: none;
        &:after {
          box-shadow: none;
        }
      }
      cursor: pointer;
      position: relative;
      padding: 8px;
      border-radius: 4px;
      background-color: #fff;
      font-size: 10px;
      width: 75%;
      min-width: 220px;
      max-width: 320px;
      box-shadow: 2px 2px 8px 0 rgba(187, 187, 187, 0.5);
      display: flex;
      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 10px;
        height: 10px;
        margin-left: -5px;
        transform: rotate(45deg);
        background-color: #fff;
      }
      &:nth-child(1) {
        align-self: flex-end;
      }
      &:nth-child(2) {
        align-self: flex-start;
      }
      &:nth-child(3) {
        align-self: flex-end;
      }
      &--image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: #ccc;
        margin-right: 8px;
      }
      &--text {
        flex: 1;
      }
    }
  }
  &__testimonials {
    opacity: 0;
    transform: translateX(50%);
    .c-icon--close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      z-index: 2;
      transition: opacity $flip-time ease-out;
      transform: translateZ(1px);
      &.onFlip {
        transition: none;
        opacity: 0;
      }
    }
  }
  &.active {
    .testimony-map__maps {
      z-index: 1;
      opacity: 0;
      transform: translateX(-50%);
    }
    .testimony-map__testimonials {
      z-index: 2;
      opacity: 1;
      transform: translateX(0);
    }
  }
}
.card {
  text-align: center;
  padding: 16px 40px;
  box-shadow: 2px 2px 8px 0 rgba(187, 187, 187, 0.5);
}
</style>
