<template lang="pug">
  .c-tentang-kami
    .tentang-kami-page-hero.u-position-relative
      .tentang-kami-page-hero__wrapper__top
        .tentang-kami-page-hero__content
          breadcrumb(:data="breadcrumbItems" )
    .tentang-kami-page-hero__wrapper__middle
      .tentang-kami-page-hero__content.container-hero
        BlFlexContainerMv.tentang-kami-page-hero--flex-container-hero(alignItems="center")
          .tentang-kami-page-hero--title-description
            .background-image-mobile
              ul.pr-20
                li.hero-image-mobile-container.pt-16(style='list-style-type: none')
                  BLBPicture(
                    :lazy="false"
                    :webp="false"
                    src="/images/tentang-kami/hero_image_mobile.png"
                    :img-style="{ width: '132px', height: '82px' }"
                    title="Mengenal Mitra Bukalapak"
                  )
                li(style='list-style-type: none')
                  BlTextAv.tentang-kami-page-hero--title(tag="h1" weight="bold") Mengenal
                li.padding-title(style='list-style-type: none')
                  BlTextAv.tentang-kami-page-hero--title-other(tag="h1" weight="bold") Mitra Bukalapak
                li(style='list-style-type: none')
                  BlTextAv.tentang-kami-page-hero--subtitle(tag="h2" :ellipsis='4' weight="semi-bold") Dikembangkan mulai tahun 2017, Mitra Bukalapak adalah sebuah platform digital untuk mendukung para pelaku UMKM, terutama warung kecil di berbagai kota di seluruh Indonesia untuk menambah penghasilan mereka.
                li(style='list-style-type: none')
                  BlTextAv.tentang-kami-page-hero--description(tag="h6" :ellipsis='5') Di bawah perusahaan PT Buka Mitra Indonesia, anak usaha dari PT Bukalapak.com, Tbk, Mitra Bukalapak bertujuan untuk mendukung dan memperluas bisnis masyarakat, khususnya usaha kecil mikro, kecil, dan menengah (UMKM). Fokus utama kami adalah membantu warung, kios tradisional, dan agen individu agar tetap kompetitif dalam era perdagangan digital melalui model bisnis O2O (online to offline).
          .tentang-kami-page-hero--image
            BLBPicture(
              :lazy="false"
              :webp="false"
              src="/images/tentang-kami/hero-image.png"
              :img-style="{ width: '100%' }"
              title="Mengenal Mitra Bukalapak"
            )
  
</template>

<script>
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import Breadcrumb from "~/components/TentangKamiPage/Breadcrumb.vue";
import BLBPicture from "~/components/shared/base/BLBPicture.vue";

export default {
  name: "TentangKamiPageHero",
  components: {
    BlTextAv,
    BLBPicture,
    Breadcrumb,
    BlFlexContainerMv,
  },
  data: () => ({
    breadcrumbItems: [
      { path: { name: "agenlite_landing_root" }, text: "Home" },
      { path: { name: "agenlite_tentang_kami" }, text: "Tentang Kami" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$container-hero-content-margin-left: 84px;
$container-hero-content-margin-right: 84px;
$container-hero-title-line-height: 40px;
$container-hero-subtitle-line-height: 122px;
$hero-image-mobile-container-width: 132px;
$hero-image-mobile-container-height: 82px;

.container-hero {
  margin-left: auto;
  width: 100%;
}

.tentang-kami-page-hero {
  width: 100%;

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .breadcrumb {
    margin-left: $space-2;
  }

  .breadcrumb li {
    color: $the-foreground;
    margin-left: $space-2;
  }

  &__wrapper {
    &__top {
      position: absolute;
      width: 100%;
    }

    &__middle {
      width: 100%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__content {
    position: relative;
    margin-left: $container-hero-content-margin-left;
    z-index: 2;
    color: black;

    &--wrapper {
      width: 100%;
    }
  }

  &--flex-container-hero {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    padding-top: $space-60;
  }

  &--title-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  &--title {
    font-size: $font-size-60;
    color: $border-foreground;
    line-height: $container-hero-title-line-height;
  }

  &--title-other {
    font-size: $font-size-60;
    color: $brand-01;
    line-height: $container-hero-title-line-height;
  }

  &--subtitle {
    line-height: $line-height-28;
    font-weight: $font-weight-bold !important;
    margin-bottom: $space-20 !important;
    font-size: $font-size-24;
    margin-top: $space-20 !important;
  }

  &--description {
    font-size: $font-size-16;
    line-height: $font-size-24;
  }

  &--image {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
}

ul {
  margin-left: 0;
}

.hero-image-mobile-container {
  display: none;
}


.padding-title {
  padding-top: $space-12;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .tentang-kami-page-hero {
    padding-bottom: $space-20;

    &__content {
      margin-left: unset;
    }

    &--image {
      display: none;
    }

    &__wrapper {
      &__middle {
        padding-top: unset;
        top: 220px;
      }
    }

    &--title {
      font-size: $font-size-32;
      line-height: $line-height-36;
      font-weight: $font-weight-medium;
    }

    &--title-other {
      font-size: $font-size-32;
      line-height: $line-height-36;
      font-weight: $font-weight-medium;
    }

    &--subtitle {
      font-size: $font-size-14;
      line-height: $line-height-22;
      margin-top: $space-16;
      margin-bottom: $space-16;
    }

    &--description {
      font-size: $font-size-14;
      line-height: $line-height-22;
    }

    &--flex-container-hero {
      padding-top: $space-20;
    }
  }

  .padding-title {
    padding-top: 0;
  }

  .background-image-mobile {
    background:
      radial-gradient(60% 30% at 70% 35%, #FFD4D6 0%, rgba(255, 212, 214, 0)),
      radial-gradient(50% 50% at 100% 60%, #FADFC5 0%, rgba(250, 223, 197, 0));
  }

  .container-hero {
    padding-left: $space-20;
  }

  .tentang-kami-page-hero.u-position-relative {
    display: none;
  }

  .hero-image-mobile-container {
    display: block;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .tentang-kami-page-hero { 
    &--title {
      font-size: $font-size-32;
      color: $border-foreground;
      line-height: $container-hero-title-line-height;
    }

    &--title-other {
      font-size: $font-size-32;
      color: $brand-01;
      line-height: $container-hero-title-line-height;
    }

    &--subtitle {
      line-height: $line-height-28;
      font-weight: $font-weight-bold !important;
      margin-bottom: $space-20 !important;
      font-size: $font-size-16;
      margin-top: $space-20 !important;
    }

    &--description {
      font-size: $font-size-12;
      line-height: $font-size-24;
    }
  }
  .padding-title {
    padding-top: 0;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .tentang-kami-page-hero { 
    &--title {
      font-size: $font-size-42;
      color: $border-foreground;
      line-height: $container-hero-title-line-height;
    }

    &--title-other {
      font-size: $font-size-42;
      color: $brand-01;
      line-height: $container-hero-title-line-height;
    }

    &--subtitle {
      line-height: $line-height-28;
      font-weight: $font-weight-bold !important;
      margin-bottom: $space-20 !important;
      font-size: $font-size-20;
      margin-top: $space-20 !important;
    }

    &--description {
      font-size: $font-size-16;
      line-height: $font-size-24;
    }
  }
  .padding-title {
    padding-top: 0;
  }
}

</style>
