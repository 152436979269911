<template lang="pug">
  .tentang-kami-page-pencapaian-mitra
    .tentang-kami-page-pencapaian-mitra__content.container-pencapaian-mitra 
      ul.pencapaian-mitra-text
        li(style='list-style-type: none')
          BlTextAv.pencapaian-mitra-title.pb-8(variation="heading-42" weight="medium") Pencapaian Mitra
        li(style='list-style-type: none')
          BlTextAv.pencapaian-mitra-subtitle(variation="body-16" weight="regular" :color="'subdued'") Seiring berkembangnya Mitra Bukalapak, kami telah membantu jutaan
        li(style='list-style-type: none')
          BlTextAv.pencapaian-mitra-subtitle(variation="body-16" weight="regular" :color="'subdued'") mitra mengembangkan bisnisnya
      BlFlexContainerMv.pencapaian-mitra-flex-container      
        .pencapaian-mitra-item-container
          .pencapaian-mitra-img-container.pb-16
            BLBPicture.pencapaian-mitra-img(
              :lazy="false"
              :webp="false"
              img-class="pencapaian-mitra-img__pic"
              src="/images/tentang-kami/trophy.png"
              title="Pencapaian Mitra trophy"
            )
          BlTextAv.pencapaian-mitra-item.pb-8(weight="bold" variation="heading-24") 17++ Juta Mitra
          BlTextAv.pencapaian-mitra-description(weight="regular" variation="body-14") Mitra Bukalapak meningkatkan pendapatan dengan mendigitalisasikan warung tradisional, kios, dan mitra individual
        .pencapaian-mitra-item-container
          .pencapaian-mitra-img-container.pb-16
            BLBPicture.pencapaian-mitra-img(
              :lazy="false"
              :webp="false"
              img-class="pencapaian-mitra-img__pic"
              src="/images/tentang-kami/star.png"
              title="Pencapaian Mitra star"
            )
          BlTextAv.pencapaian-mitra-item.pb-8(weight="bold" variation="heading-24") 57% Market Share
          BlTextAv.pencapaian-mitra-description(weight="regular" variation="body-14") Perusahaan enabler warung terbesar dengan market share 57% di Indonesia
        .pencapaian-mitra-item-container     
          .pencapaian-mitra-img-container.pb-16
            BLBPicture.pencapaian-mitra-img(
              :lazy="false"
              :webp="false"
              img-class="pencapaian-mitra-img__pic"
              src="/images/tentang-kami/money.png"
              title="Pencapaian Mitra money"
            )
          BlTextAv.pencapaian-mitra-item.pb-8(weight="bold" variation="heading-24") 3X Peningkatan Pendapatan
          BlTextAv.pencapaian-mitra-description(weight="regular" variation="body-14") Warung yang bermitra dengan Mitra Bukalapak mengalami 3x peningkatan pendapatan atau +/- transaksi meningkat 6x dalam sehari
        .pencapaian-mitra-item-container  
          .pencapaian-mitra-img-container.pb-16
            BLBPicture.pencapaian-mitra-img(
              :lazy="false"
              :webp="false"
              img-class="pencapaian-mitra-img__pic"
              src="/images/tentang-kami/link.png"
              title="Pencapaian Mitra link"
            )
          BlTextAv.pencapaian-mitra-item.pb-8(weight="bold" variation="heading-24") Komunitas Mitra Warung Terbesar di Indonesia
          BlTextAv.pencapaian-mitra-description(weight="regular" variation="body-14") Membangun kemitraan 150.000 warung di seluruh penjuru Indonesia dalam Komunitas Juwara
                      
</template>

<script>
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BLBPicture from "~/components/shared/base/BLBPicture.vue";

export default {
  name: "TentangKamiPagePencapaianMitra",
  components: {
    BlFlexItemAv,
    BlFlexContainerMv,
    BlTextAv,
    BLBPicture,
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$pencapaian-mitra-item-container-height: 208px;
$pencapaian-mitra-text-width: 557px;
$pencapaian-mitra-text-height: 104px;

.container-pencapaian-mitra {
  width: 100%;
}

.pencapaian-mitra-title {
  line-height: $space-48;
  letter-spacing: $space-0;
}

.pencapaian-mitra-subtitle {
  line-height: $space-24;
  letter-spacing: $space-0;
}

.pencapaian-mitra-description {
  line-height: $space-20;
  letter-spacing: $space-0;
}

.pencapaian-mitra-text {
  gap: $space-8;
  margin-left: 0;
}

.pencapaian-mitra-item {
  line-height: $line-height-28;
  letter-spacing: $space-0;
  text-align: left;
  color: $background-playful-01;
}

.pencapaian-mitra-flex-container {
  padding-top: $space-40;
  grid-gap: $space-24 $space-24;
  justify-content: space-between;
  flex-direction: row;
}

.pencapaian-mitra-img {
  width: $space-48;
  height: $space-48;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .pencapaian-mitra-flex-container {
    flex-direction: column;
  }

  .container-pencapaian-mitra {
    width: 100%;
  }

  .pencapaian-mitra-text {
    text-align: center;
  }

  .pencapaian-mitra-title {
    font-size: $font-size-28;
    line-height: $line-height-32;
    font-weight: $font-weight-bold;
  }

  .pencapaian-mitra-subtitle {
    font-size: $font-size-14;
    line-height: $line-height-22;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .pencapaian-mitra-title {
    font-size: $font-size-32;
  }
  .pencapaian-mitra-subtitle {
    font-size: $font-size-14;
    line-height: $line-height-22;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .pencapaian-mitra-title {
    font-size: $font-size-42;
  }
  .pencapaian-mitra-subtitle {
    font-size: $font-size-14;
    line-height: $line-height-22;
  }
}


</style>
