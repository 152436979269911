const ALERT_TYPES = ['info', 'success', 'warning'];

export const state = () => ({
  isDisplayed: false,
  message: '',
  type: 'info',
  hideClose: false,
});

export const getters = {
  getStatus: s => s.isDisplayed,
  getMessage: s => s.message,
  getType: s => {
    const { type } = s;
    return ALERT_TYPES.includes(type) ? type : ALERT_TYPES[0];
  },
  hideClose: s => s.hideClose,
};

export const mutations = {
  showFlashAlert(s, { message, type, hideClose = false }) {
    s.isDisplayed = true;
    s.message = message;
    s.type = type;
    s.hideClose = hideClose;
  },
  hideFlashAlert(s) {
    s.isDisplayed = false;
    s.message = '';
  },
};

export const actions = {
  showFlashAlert({ commit, state: s }, { message, type = 'info', hideClose = false }) {
    if (s.isDisplayed) {
      commit('hideFlashAlert');
    }
    setTimeout(() => commit('showFlashAlert', { message, type, hideClose }), 200);
  },
  hideFlashAlert({ state: s, commit }) {
    if (s.isDisplayed) {
      commit('hideFlashAlert');
    }
  },
};
