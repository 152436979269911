import { mapGetters, mapActions } from 'vuex';
import { getSlugType } from '~/utils/article-utils';

// components
import BannerFixed from '~/components/BannerFixed';

// mixins
import userAgent from '~/mixins/user-agent';

const INSTALL_APP_BANNER_LABEL = 'install_app_banner';

const state = {
  components: {
    BannerFixed,
  },
  mixins: [userAgent],
  data: () => ({
    installAppBanner: {
      image: '/images/banners/install-app/post-ramadan-banner.png',
      campaign: 'banner-general',
      title: 'Install aplikasi Mitra Bukalapak',
    },
  }),
  computed: {
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
      homeDownloadLink: 'global/homeDownloadLink',
      isLandingClosed: 'install-app-banner/isLandingClosed',
      isFAQClosed: 'install-app-banner/isFAQClosed',
      isTermsClosed: 'install-app-banner/isTermsClosed',
      isHomepageClosed: 'install-app-banner/isHomepageClosed',
      isArticleClosed: 'install-app-banner/isArticleClosed',
    }),
  },
  mounted() {
    const localStorageItem = this.getItem();
    if (localStorageItem === null) {
      this.setItem('landing', false);
      this.setItem('faq', false);
      this.setItem('homepage', false);
      this.setItem('article', false);
    } else {
      if (this.isLandingClosed !== localStorageItem.landing) this.setItem('landing', localStorageItem.landing);
      if (this.isFAQClosed !== localStorageItem.faq) this.setItem('faq', localStorageItem.faq);
      if (this.isTermsClosed !== localStorageItem.terms) this.setItem('terms', localStorageItem.terms);
      if (this.isHomepageClosed !== localStorageItem.homepage) this.setItem('homepage', localStorageItem.homepage);
      if (this.isArticleClosed !== localStorageItem.article) this.setItem('article', localStorageItem.article);
    }
  },
  methods: {
    ...mapActions({
      setCloseState: 'install-app-banner/setCloseState',
    }),
    getItem() {
      return JSON.parse(localStorage.getItem(INSTALL_APP_BANNER_LABEL));
    },
    setItem(page, isClosed) {
      this.setCloseState({ page, isClosed });
      localStorage.setItem(
        INSTALL_APP_BANNER_LABEL,
        JSON.stringify({
          landing: this.isLandingClosed,
          faq: this.isFAQClosed,
          terms: this.isTermsClosed,
          homepage: this.isHomepageClosed,
          article: this.isArticleClosed,
        })
      );
    },
    closeInstallAppBanner(e, page) {
      this.setItem(page, true);
    },
    getInstallAppBannerUrl(from) {
      const { slug } = this.$route.params;
      const referrer = (!process.server && document && document.referrer) || '';
      if (slug) {
        const slugType = getSlugType(slug);
        if (slugType.type === 'detail') {
          return `${this.homeDownloadLink}?campaign=${slug}&adgroup=${from}&creative=${referrer}`;
        }
      } else if (this.$route.name === 'agenlite_landing_root') {
        return `${this.homeDownloadLink}?campaign=mitra-web-landingpage&adgroup=${from}&creative=${referrer}`;
      }
      return `${this.downloadAppLink}?campaign=${from}-${this.installAppBanner.campaign}`;
    },
    shouldShowInstallAppBanner(pageCloseState) {
      return !this.isBLMitraWebView && !pageCloseState;
    },
  },
};

export default state;
