import urlJoin from 'url-join';
import {
  HOMEPAGE,
  FAQ,
  AGENT_GROSIR,
  AGENT_PPOB,
  AGENT_TABUNGAN_EMAS,
  AGENT_DROPSHIP,
  AGENT_PENGIRIMAN,
  BERANDA,
  DAFTAR_HARGA_PULSA,
  DAFTAR_HARGA_PAKET_DATA,
  DAFTAR_HARGA_TOKEN_LISTRIK,
  PRODUK
} from '~/constants/labels';

export function generateJsonLdScript(schemas) {
  try {
    const contents = JSON.parse(schemas);

    if (Array.isArray(contents)) {
      return contents.map(el => ({ hid: 'ld', type: 'application/ld+json', json: JSON.parse(el) }));
    }

    return [{ hid: 'ld', type: 'application/ld+json', json: contents }];
  } catch (err) {
    return [];
  }

}

export const getDefaultMetaAttributes = routeName => {
  const meta = {
    [HOMEPAGE]: {
      title: 'Mitra Bukalapak: Semua Bisa Untung Hingga Jutaan!',
      description:
        'Daftar jadi Mitra Bukalapak dan beli barang grosir untuk kebutuhan warung dengan mudah dan cepat. Nikmati banyak keuntungan dari jualan pulsa, voucher game, token listrik, dan beragam produk virtual.',
    },
    [FAQ]: {
      title: 'Mitra Bukalapak: Semua Bisa Untung Hingga Jutaan!',
      description:
        'Daftar jadi Mitra Bukalapak dan beli barang grosir untuk kebutuhan warung dengan mudah dan cepat. Nikmati banyak keuntungan dari jualan pulsa, voucher game, token listrik, dan beragam produk virtual.',
    },
    [AGENT_GROSIR]: {
      title: 'Peluang Usaha Toko Kelontong & warung sembako terlengkap',
      description:
        'Buka usaha warung sembako dan toko kelontong kini mudah dan praktis. Dengan menjadi mitra, kamu bisa mendapatkan berbagai keuntungan, mulai dari supply stok produk yang lengkap, harga grosir termurah, gratis ongkir, produk virtual hingga layanan sehari sampai dari Mitra Bukalapak.',
    },
    [AGENT_PPOB]: {
      title: 'Peluang Usaha Agen Pembayaran PPOB Online Terlengkap',
      description:
        'Buka usaha agen pembayaran PPOB kini mudah dan praktis. Dengan menjadi mitra, kamu bisa mendapatkan berbagai keuntungan mulai dari komisi agen dari tiap transaksi sampai promo menarik dari Mitra Bukalapak',
    },
    [AGENT_TABUNGAN_EMAS]: {
      title: 'Peluang Usaha Jualan Emas Terlengkap dan Terpercaya',
      description:
        'Buka usaha jualan emas online kini mudah dan praktis. Dengan menjadi mitra, kamu bisa mendapatkan berbagai keuntungan mulai dari komisi agen sampai promo menarik dari Mitra Bukalapak',
    },
    [AGENT_DROPSHIP]: {
      title: 'Peluang Usaha Dropship Terlengkap & Terpercaya',
      description:
        'Buka usaha dropship online kini mudah dan praktis. Dengan menjadi mitra, kamu bisa mendapatkan berbagai keuntungan mulai dari komisi agen sampai promo menarik dari Mitra Bukalapak',
    },
    [AGENT_PENGIRIMAN]: {
      title: 'Peluang Usaha Agen Ekspedisi & Multi Kurir Terlengkap',
      description:
        'Buka usaha agen ekspedisi dengan multi kurir kini mudah dan praktis. Dengan menjadi mitra, kamu bisa mendapatkan berbagai keuntungan mulai dari komisi agen dari tiap pengiriman barang sampai promo menarik dari Mitra ',
    },
    [BERANDA]: {
      title: 'Mitra Bukalapak : Jualan Produk Digital Murah Untung Berlimpah',
      description: 'Bergabunglah dengan Mitra Bukalapak dan dapatkan keuntungan untuk kebutuhan usaha warungmu dengan beli produk grosir online serta jualan beragam produk berkualitas hanya di Bukalapak',
    },
    [DAFTAR_HARGA_PULSA]: {
      title: 'Mitra Bukalapak: Jualan Pulsa Elektrik Murah',
      description:
        'Jualan isi ulang pulsa elektrik online semua operator Telkomsel, Indosat, Simpati, Smartfren, XL, Axis, Bolt dari Mitra Bukalapak. Dapatkan berbagai promo menarik dengan harga termurah.',
    },
    [DAFTAR_HARGA_PAKET_DATA]: {
      title: 'Mitra Bukalapak: Jualan Paket Data Internet Murah',
      description:
        'Jualan paket kuota internet / paket data Telkomsel, Indosat, Simpati, Smartfren, XL, Axis, Bolt dari Mitra Bukalapak. Murah harganya, lancar untungnya, mulus jualannya.',
    },
    [DAFTAR_HARGA_TOKEN_LISTRIK]: {
      title: 'Mitra Bukalapak: Jualan Token Listrik Dapat Potongan Harga',
      description:
        'Jualan token listrik PLN Online dengan Mitra Bukalapak. Dapatkan harga murah dan penawaran menarik. Raih untung berlimpah dengan berjualan token listrik dari Mitra Bukalapak.',
    },
    [PRODUK]: {
      title: 'Mitra Bukalapak: Semua Bisa Untung Hingga Jutaan!',
      description:
        'Daftar jadi Mitra Bukalapak dan beli barang grosir untuk kebutuhan warung dengan mudah dan cepat. Nikmati banyak keuntungan dari jualan pulsa, voucher game, token listrik, dan beragam produk virtual.',
    },
    other: {
      title: 'Mitra Bukalapak: Semua Bisa Untung Hingga Jutaan!',
      description:
        'Daftar jadi Mitra Bukalapak dan beli barang grosir untuk kebutuhan warung dengan mudah dan cepat. Nikmati banyak keuntungan dari jualan pulsa, voucher game, token listrik, dan beragam produk virtual.',
    },
  };
  return meta[routeName] || meta.other;
};

export const getSocialMediaMeta = ({ url, title, description }) => [
  { hid: 'og:url', property: 'og:url', content: url },
  { hid: 'og:type', property: 'og:type', content: 'website' },
  { hid: 'og:title', property: 'og:title', content: title },
  { hid: 'og:description', property: 'og:description', content: description },
  {
    hid: 'og:image',
    property: 'og:image',
    content: urlJoin(process.env.ASSETS_URL, '/images/logos/square-red-brand/logo@3x.png'),
  },
  { hid: 'twitter:title', property: 'twitter:title', content: title },
  { hid: 'twitter:description', property: 'twitter:description', content: description },
  {
    hid: 'twitter:image',
    property: 'twitter:image',
    content: urlJoin(process.env.ASSETS_URL, '/images/logos/square-red-brand/logo@3x.png'),
  },
];
