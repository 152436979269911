<template lang="pug">
.hello
  BlCellMv
    BlTextAv(
      slot="text"
      variation="body-16"
      weight="semi-bold"
      tag="h1"
    ) Mitra Bukalapak
    a(slot="iconLeft" style="display: flex" @click="$router.back()")
      BlIconAv(name="ico_back_android" color="active-darker")
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlCellMv from '@bukalapak/bazaar-mweb/dist/components/BlCellMv';
import { BlIconAv } from '@bukalapak/bazaar-visual';

export default {
  name: 'MainHeader',
  components: {
    BlCellMv,
    BlTextAv,
    BlIconAv,
  },
};
</script>

<style lang="scss" scoped>

</style>
