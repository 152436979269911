import { mapState } from 'vuex';
import axios from 'axios';
import tracker from '~/mixins/tracker';
import { generateBrowserID } from '~/utils/platform-utils';

export default {
  mixins: [tracker],
  computed: {
    ...mapState({
      identity: state => state.auth.identity,
    })
  },
  mounted() {
    // References:
    // - https://nuxtjs.org/api/context
    // - https://github.com/bukalapak/mothership/blob/ff741b2663eb17a3ec95aaa2ac03efcb753ee6be/app/assets/javascripts/siburung.es6
    if (process.server) return;
    this.$store.dispatch('generateJourneyId');

    const { app } = this.$router;
    const route = this.$route;

    const referrerActions = [
      {
        expr: () => app.$pageReferrer?.name,
        value: () => ({ name: app.$pageReferrer.name, url: app.$pageReferrer.url, type: app.$pageReferrer.type }),
      },
      {
        expr: () => !document.referrer && route.query.from,
        value: () => ({ name: route.query.from, url: '', type: 'param_from' }),
      },
      {
        expr: () => (document.referrer !== '') && (!document.referrer.includes(window.location.origin)),
        value: () => ({ name: 'referred', url: document.referrer, type: 'external_link' }),
      },
      {
        expr: () => (document.referrer !== '') && document.referrer.includes(window.location.origin),
        value: () => ({ name: 'other', url: document.referrer, type: 'internal_link' }),
      },
    ];
    const referrer = referrerActions.find(item => item.expr())?.value() ?? { name: 'organic', url: '', type: 'direct' };

    app.$pageReferrer = { name: route.name, url: route.path, type: 'internal_link' };

    if (['organic', 'referred'].includes(referrer.name)) {
      const userId = this.$store.getters['auth/getCurrentUserId36'];
      const url = process.env.SIBURUNG_TRACKING_URL;
      if (url !== '') {
        const formData = {
          action: 'visit',
          client_id: generateBrowserID(),
          identity: this.identity,
          landing_page_url: window.location.href,
          referrer_url: referrer.url,
          user_id: userId && userId !== '0' ? userId : undefined,
        };
        if (process.env.NODE_ENV !== 'production') {
          console.warn('Tracker: ', url, formData);
        } else {
          axios.post(url, formData);
        }
      }
      this.trackOpenedSession({ route, referrer });
    }
    this.trackScreenVisit({ route, referrer });
  },
};
