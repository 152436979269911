<template lang="pug">
  .c-tab-content(:class="{ 'u-pad--4' : padded }")
    h2.u-txt--large
      slot(name="title")
    slot(name="content")
</template>

<script>
export default {
  name: 'TabContent',
  props: {
    padded: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
