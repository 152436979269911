<template lang="pug">
  .tentang-kami-page-perjalanan-mitra
    .tentang-kami-page-perjalanan-mitra__content.container-perjalanan-mitra
      .perjalanan-mitra-text
        BlTextAv.perjalanan-mitra-title(weight="medium" variation="heading-42") Perjalanan Mitra Bukalapak
      .perjalanan-mitra-timeline.content-perjalanan-mitra
        BlTimelineMv(
          :timeline="timeline"
        )
          
</template>

<script>
import BlTimelineMv from "~/components/shared/compound/BlTimelineMv/BlTimelineMv.vue";
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";

export default {
  name: 'TentangKamiPagePerjalananMitra',
  components: {
    BlTimelineMv,
    BlTextAv,
    BlFlexContainerMv,
  },
  data: () => ({
    timeline: [
      {
        year: '2010',
        title: '<span class="u-txt--bolder">Bukalapak</span> dibentuk sebagai platform marketplace',
      },
      {
        year: '2016',
        title: '<span class="u-txt--bolder">Juragan Pulsa Bukalapak</span> adalah cikal bakal Mitra Bukalapak yang menjual produk virtual',
      },
      {
        year: '2017',
        title: 'Juragan Pulsa Bukalapak berganti nama menjadi <span class="u-txt--bolder">Agen Bukalapak</span>',
      },
      {
        year: '&nbsp;',
        title: '<span class="u-txt--bolder">Agen Bukalapak</span> tidak hanya menjual produk virtual tetapi juga berbagai barang yang ada di Bukalapak',
      },
      {
        year: '&nbsp;',
        title: 'Merekrut <span class="u-txt--bolder">Koordinator Agen</span> untuk mengakuisisi warung offline dan menjadikannya sebagai Agen Bukalapak',
      },
      {
        year: '2018',
        title: '<span class="u-txt--bolder">Bukalapak</span> meluncurkan bisnis O2O untuk memberikan solusi bagi pedagang warung, kios, dan individu yaitu <span class="u-txt--bolder">Agen Bukalapak</span>',
      },
      {
        year: '&nbsp;',
        title: 'Agen Bukalapak <span class="u-txt--bolder">mengakuisisi</span> lebih banyak <span class="u-txt--bolder">warung offline</span> di seluruh kota besar di Indonesia',
      },
      {
        year: '&nbsp;',
        title: 'Agen Bukalapak mulai memperkaya <span class="u-txt--bolder">produk grosir</span> Bukalapak di wilayah Jakarta',
      },
      {
        year: '&nbsp;',
        title: 'Agen Bukalapak <span class="u-txt--bolder">mengembangkan cakupan area</span> Grosir ke wilayah timur <span class="u-txt--bolder">Pulau Jawa, Yogyakarta dan Palembang</span>',
      },
      {
        year: '&nbsp;',
        title: '<span class="u-txt--bolder">Agen Bukalapak</span> berubah nama menjadi <span class="u-txt--bolder">Mitra Bukalapak</span> untuk menegaskan brand valuenya di bidang O2O',
      },
      {
        year: '2019',
        title: '<span class="u-txt--bolder">Mitra Bukalapak</span> terus meningkatkan jumlah produk virtual seperti <span class="u-txt--bolder">kirim uang, game voucher, dan tabungan emas</span>',
      },
      {
        year: '2020',
        title: 'Jumlah <span class="u-txt--bolder">mitra aktif</span> yang bergabung bertambah menjadi <span class="u-txt--bolder">lebih dari 7 juta</span> di seluruh Indonesia',
      },
      {
        year: '2021',
        title: 'Bukalapak menjadi unicorn Indonesia pertama yang melantai di Bursa Efek Indonesia (BEI) dengan <span class="u-txt--bolder">Mitra Bukalapak</span> sebagai anak perusahaannya',
      },
      {
        year: '2022',
        title: 'Jumlah <span class="u-txt--bolder">mitra aktif</span> yang bergabung bertambah menjadi <span class="u-txt--bolder">13,1 juta</span> di seluruh Indonesia',
      },
      {
        year: '2023',
        title: '<span class="u-txt--bolder">Mitra Bukalapak</span> berhasil menjadi market leader kategori O2O di Indonesia dengan tingkat penetrasi 57% dan jumlah mitra aktif lebih dari 17 juta di seluruh Indonesia',
      }
    ]
  }),
};
</script>


<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$perjalanan-mitra-text-padding-left: 82px;

.perjalanan-mitra-timeline {
  width: 100%;
}

.container-perjalanan-mitra {
  height: 100%;
  background: $background-washedout;
}

.perjalanan-mitra-title {
  line-height: $line-height-48;
}

.content-perjalanan-mitra {
  overflow: scroll;
  white-space: nowrap;
  padding-top: $space-20;
  padding-bottom: $space-20;
  gap: $space-40;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.perjalanan-mitra-text {
  padding-top: $space-40;
  padding-bottom: $space-40;
  padding-left: $perjalanan-mitra-text-padding-left;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .perjalanan-mitra-text {
    padding-left: $space-20;
    text-align: center;
  }

  .perjalanan-mitra-title {
    font-size: $font-size-28;
    font-weight: $font-weight-bold;
    line-height: $line-height-34;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .perjalanan-mitra-title {
    font-size: $font-size-32;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .perjalanan-mitra-title {
    font-size: $font-size-42;
  }
}

</style>
