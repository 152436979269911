import { render, staticRenderFns } from "./CategoryBar.vue?vue&type=template&id=58691aea&scoped=true&lang=pug&"
import script from "./CategoryBar.vue?vue&type=script&lang=js&"
export * from "./CategoryBar.vue?vue&type=script&lang=js&"
import style0 from "./CategoryBar.vue?vue&type=style&index=0&id=58691aea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58691aea",
  null
  
)

export default component.exports