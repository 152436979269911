import { mapGetters, mapState } from 'vuex';
import responsive from './responsive';
import { generateBrowserID } from '~/utils/platform-utils';

const PREFIX = 'agenlite_';
const PREFIX_SEO = 'agenlite_seo_';

const trackerMixin = {
  mixins: [responsive],
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
    }),
    ...mapState({
      idenity: state => state.auth.identity,
    })
  },
  methods: {
    getMitraStatus() {
      if (!this.isUserLoggedIn) {
        return 'non-login';
      }
      return 'login-user';
    },
    generateVisitParams({ referrer }) {
      const isFirstPage = !referrer.url || window.history.length <= 1;
      const params = {
        is_first_journey: isFirstPage,
        user_type: this.getMitraStatus(),
      };
      return Object.keys(params)
        // TODO: fix this confusing later
        // eslint-disable-next-line no-confusing-arrow
        .map(k => Number.isInteger(params[k]) || !!params[k] ? `${k}=${params[k]}` : null)
        .filter(p => !!p)
        .join('&');
    },
    trackActions(data) {
      data.ui = this.$store.getters['auth/getCurrentUserId36'];
      data.identity = generateBrowserID();
      this.$tracker.event(data);
    },
    trackClick(event, eventName, referrerUrl = '') {
      // this.$ga.event('click', eventName, this.$route.name, 0);
      this.trackActions({
        evn: `${PREFIX}click`,
        click_source: eventName,
        referrer: this.$route.name,
        referrer_url: referrerUrl
      });
    },
    trackLandingSection(event, section, location) {
      this.trackActions({
        evn: `${PREFIX}landing_${section}`,
        client_id: this.identity,
        device: this.device,
        location,
      });
    },
    trackArticle(event, type = 'click', title = '', currentUrl = '', referrer = {}) {
      const trackingData = {
        evn: `${PREFIX}artikel_page_clicked`,
        referrer_name: referrer.name,
        referrer_url: referrer.url,
        url: currentUrl,
        title,
      };
      if (type !== 'click') trackingData.evn = `${PREFIX}artikel_page_${type}_button`;

      this.trackActions(trackingData);
    },

    // New Tracker Data
    newTrackActions(data) {
      const { name, path } = this.$route;
      const { getJourneyId, 'auth/getCurrentUserId36': getCurrentUserId36 } = this.$store.getters;
      const trackingData = {
        ...data,
        referrer: name,
        referrer_url: path,
        journey_id: getJourneyId,
        ui: getCurrentUserId36,
        identity: generateBrowserID()
      };
      this.$tracker.event(trackingData);
    },
    async trackOpenedSession({ route, referrer }) {
      const { getJourneyId, 'auth/getCurrentUserId36': getCurrentUserId36 } = this.$store.getters;
      const payload = {
        evn: `${PREFIX}opened_session`,
        current_page: route.name,
        referrer_screen: referrer.name,
        referrer_url: referrer.url,
        referrer_type: referrer.type,
        source: referrer.type,
        journey_id: getJourneyId,
        ui: getCurrentUserId36,
        identity: generateBrowserID(),
      };
      this.$tracker.event(payload);
    },
    async trackScreenVisit({ route, referrer }) {
      const { getJourneyId, 'auth/getCurrentUserId36': getCurrentUserId36 } = this.$store.getters;
      const payload = {
        evn: `${PREFIX}screen_visit`,
        current_screen: route.name,
        referrer_screen: referrer.name,
        referrer_url: referrer.url,
        journey_id: getJourneyId,
        ui: getCurrentUserId36,
        identity: generateBrowserID(),
      };
      payload.params = this.generateVisitParams({ referrer });
      this.$tracker.event(payload);
    },
    async trackEventClick(action, { targetScreen, value } = {}) {
      const { getJourneyId, 'auth/getCurrentUserId36': getCurrentUserId36 } = this.$store.getters;
      const payload = {
        evn: `${PREFIX}event_click`,
        current_screen: this.$route.name,
        click_source: action,
        journey_id: getJourneyId,
        ui: getCurrentUserId36,
        identity: generateBrowserID(),
      };
      if (targetScreen) payload.target_screen = targetScreen;
      if (value) payload.click_source_status = value; // success, wrong password, etc...
      this.$tracker.event(payload);
    },
    trackRegister(event, source, url = '/register', action = 'click') {
      this.newTrackActions({
        evn: `${PREFIX}register`,
        source,
        action,
        current_page: url,
      });
    },
    trackAdjustEventRegister({ deeplink, activityKind = 'register' }) {
      const payload = {
        evn: 'adjust_mitra_raw_register',
        activity_kind: activityKind,
        is_organic: 1,
        deeplink,
        adgroup_name: '',
        network_name: '',
        campaign_name: 'mitra_pwa',
        creative_name: '',
        impression_time: 0,
        impression_based: '',
        ui: '0',
        adid: '',
        city: '',
        idfa: '',
        ads_id: '',
        region: '',
        gps_adid: '',
        android_id: '',
        installed_at: 0,
        agent_id: '',
        click_time: Date.now(),
        created_at: Date.now().toString(),
        modified_at: Date.now().toString(),
      };
      this.$tracker.event(payload);
    },
    trackLogin(event, source, url = '/login', action = 'click') {
      this.newTrackActions({
        evn: `${PREFIX}login`,
        source,
        action,
        current_page: url,
      });
    },
    trackGoToHomepage(event, source, url = '/beranda') {
      this.newTrackActions({
        evn: `${PREFIX_SEO}started`,
        source,
        current_page: url,
      });
    },
    trackHomepageProduct(event, source, url = '', type = '') {
      this.newTrackActions({
        evn: `${PREFIX_SEO}homepage_product`,
        source,
        current_page: url,
        product_type: type,
        // Product Type
        // Virtual Product  : phone-credit, data-plan, electricity-prepaid, etc
        // Wholesale        : wholesale-minuman, wholesale-mie-instant, wholesale-rokok, wholesale-all
      });
    },
    trackWholesaleProductList(event, source, url = '', id = '', category = '', subcategory = '') {
      this.newTrackActions({
        evn: `${PREFIX_SEO}wholesale_add_to_cart`,
        source,
        current_page: url,
        product_id: id,
        product_category: category,
        product_subcategory: subcategory,
      });
    },
    trackMenu(event, source, url = '') {
      this.newTrackActions({
        evn: `${PREFIX_SEO}menu`,
        source,
        current_page: url,
      });
    },
    trackHomepageBanner(event, campaignId, campaignName, url) {
      this.newTrackActions({
        evn: `${PREFIX}banner_homepage_clicked`,
        campaign_id: campaignId,
        campaign_name: campaignName,
        url,
      });
    },
    trackPromoDetail(evn, data) {
      const trackingData = {
        ...data,
        evn,
        ui: this.$store.getters['auth/getCurrentUserId36'] || '',
        t: Math.floor(new Date().getTime() / 1000),
        identity: generateBrowserID()
      };
      this.$tracker.event(trackingData);
    },
    getLoadTime() {
      const origin = window.performance?.timeOrigin;
      return origin ? Date.now() - origin : -1;
    },
    async trackWebLoadTime() {
      const payload = {
        evn: 'web_load_time',
        load_time: this.getLoadTime(),
        url: window.location.href,
        is_login: this.isUserLoggedIn,
        is_bot: false,
        connection_type: global.navigator.connection.effectiveType,
        type: 'screen',
        version: 'hermes',
        ui: this.$store.getters['auth/getCurrentUserId36'] || '',
      };
      this.$tracker.event(payload);
    },
  },
};

export default trackerMixin;
