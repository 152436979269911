<template lang="pug">
 .c-new-footer
    FooterDesktop(:downloadCampaign='downloadCampaign')
</template>

<script>
import FooterDesktop from '~/components/NewFooter/Desktop/index.vue';

export default {
  name: 'FooterDekstop',
  components: {
    FooterDesktop,
  },
  props: {
    downloadCampaign: {
      type: String,
      default: '',
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.c-new-footer {
  padding-top: $space-100;
}
</style>
