<template lang="pug">
  BLCRemodal.u-align-justify(name="terms-of-use-modal", title="Aturan Penggunaan Bukalapak.com")
    strong Informasi Umum
    br
    br
    ul.u-align-justify
      li
        | Bukalapak sebagai sarana penunjang bisnis berusaha menyediakan berbagai fitur dan layanan untuk menjamin keamanan dan kenyamanan para penggunanya.
      li
        | Bukalapak tidak berperan sebagai Pelapak barang, melainkan sebagai perantara antara Pelapak dan Pembeli, untuk mengamankan setiap transaksi yang berlangsung di dalam platform Bukalapak melalui mekanisme Bukalapak Payment System. Adanya biaya ekstra (termasuk pajak dan biaya lainnya) atas segala transaksi yang terjadi di Bukalapak berada di luar kewenangan Bukalapak sebagai perantara, dan akan diurus oleh pihak-pihak yang bersangkutan (baik Pelapak atau pun Pembeli) sesuai ketentuan yang berlaku di Indonesia.
      li
        | Bukalapak hanya mengizinkan jual beli barang yang bisa dikirim melalui jasa pengiriman (jasa ekspedisi), sehingga jasa dan kerjasama dagang (franchise) tidak dapat diperdagangkan melalui Bukalapak terkecuali ada kerja sama resmi dengan pihak Bukalapak.
      li
        | Barang-barang yang dapat diperdagangkan di Bukalapak merupakan barang yang tidak tercantum di daftar “Barang Terlarang”.

</template>

<script>
export default {
  name: 'TermsOfUseModal',
};
</script>

<style lang="scss" scoped>
</style>
