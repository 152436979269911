import retrieveSeoProps from '~/api/seo';
import { ROUTE_PREFIX } from '~/constants/prefix';
import { logStdout } from '~/server/helpers/metrics-helper';

async function getSeoProps ({ path, store, redirect }) {
  try {
    // Fetch API
    const pageKey = path === '/' ? '/homepage' : path.replace(ROUTE_PREFIX, '');
    const { data: seoPropsRes } = await retrieveSeoProps({ pageKey });
    const { data: seoProps } = seoPropsRes;

    // Redirect if Necessary
    if (seoProps.redirect_to) return redirect(301, `/${seoProps.redirect_to}`);

    // Mutation State
    store.commit('seo-props/setPageSeoContent', seoProps);

    // Return Seo Props
    return seoProps;
  } catch (e) {
    logStdout('/_internal/seo-properties/:page_key', e);
    return {};
  }
};

export default getSeoProps;
