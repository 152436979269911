// import Honeybadger from '~/modules/honeybadger';

// private function
const reportError = (error, message = '') => {
  if (process.env.NODE_ENV === 'production') {
    // Honeybadger.instance.notify(error, {
    //   name: 'sentinel_pwa',
    //   error_message: message,
    // });
  }
};
const getErrorMessage = (error = {}, message = '') => {
  const matcher = [
    {
      cond: error.message === 'Failed to fetch',
      message: 'Pastikan koneksi internet kamu tidak terganggu.',
    },
    {
      cond: error.message === 'REQUEST LIMITED',
      message: 'Pastikan koneksi internet kamu tidak terganggu.',
    },
    { cond: error instanceof Error, message: '' },
    { cond: !!error.message, message: error.message },
    { cond: error.errors?.length, message: error.errors?.[0]?.message ?? '' },
    { cond: true, message: '' },
  ];
  const errorMessage = matcher.find(match => match.cond).message;
  return `${message} ${errorMessage}`;
};

const checkLoginError = (error, commit) => {
  if (
    error &&
    error.meta &&
    error.meta.http_status === 401 &&
    error.errors[0] &&
    !error.errors[0].code
  ) {
    commit('setLogout');
  }
};

export const state = () => ({
  needLogout: false,
});

export const getters = {
  isAppNeedLogout: state => state.needLogout,
};

export const mutations = {
  setLogout(state) {
    state.needLogout = true;
  },
};

export const actions = {
  isValidResponse(store, response) {
    return (
      (response.status && response.status === 'OK') ||
      (response.meta && response.meta.http_status === 200)
    );
  },
  catchError({ commit }, { error, message, hideAppLoading, noFlashAlert }) {
    checkLoginError(error, commit);
    if (noFlashAlert) {
      reportError(error);
    } else {
      this.dispatch('flashalert/showFlashAlert', {
        message: getErrorMessage(error, message),
        type: 'warning',
      });
    }
    if (hideAppLoading) this.dispatch('loading/hideAppLoading');
  },
};
