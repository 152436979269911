<template lang="pug">
.c-footer__scan-qr
  h5.u-txt--large.u-txt--light.u-mrgn-bottom--4 Atau Scan QR Code
  BLBPicture(
    src="/images/qr/qr-code.png",
    :imgStyle="{ width: '120px', height: '120px' }"
    title="QR code untuk download aplikasi"
  )
</template>

<script>
import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'FooterScanQRDesktop',
  components: {
    BLBPicture,
  },
};
</script>
