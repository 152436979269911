<template lang="pug">
  .c-footer__content
    .u-align-center.u-pad-h--4
      CTA.u-mrgn-bottom--8(:utmSource="utmSource")
      Download.u-mrgn-bottom--6(:link="link")
      FindUs(:utmSource="utmSource", :hideLabel="findUsHideLabel")
</template>

<script>
import { mapGetters } from 'vuex';
import CTA from './CTA';
import Download from './Download';
import FindUs from './FindUs';

export default {
  name: 'FooterMobile',
  components: {
    CTA,
    Download,
    FindUs,
  },
  props: {
    utmSource: {
      type: String,
      default: 'mobile_footer',
    },
    downloadCampaign: {
      type: String,
      required: true,
    },
    findUsHideLabel: {
      type: Boolean,
      default: false,
    },
    downloadLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      downloadAppLink: 'global/downloadAppLink',
    }),
    referrer() {
      return (!process.server && document && document.referrer) || '';
    },
    link() {
      return this.$route.name === 'agenlite_landing_root' ?
        `${this.downloadLink}?campaign=mitra-web-landingpage&adgroup=${this.downloadCampaign}&creative=${this.referrer}` :
        `${this.downloadAppLink}?campaign=${this.downloadCampaign}`;
    },
  },
};
</script>
