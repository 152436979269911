const adjustHost = 'https://app.adjust.com/';
const downloadLink = new URL('hi0izwq', adjustHost);
const registerLink = new URL('i5rtzw7', adjustHost);
const homeDownloadLink = new URL('41bfhie', adjustHost);
const registerParams = {
  label: 'register',
  deeplink: 'https://mitra.bukalapak.com/register',
  fallback: `${process.env.WEB_HOST}/register`,
};
Object.entries(registerParams).forEach(([param, value]) => registerLink.searchParams.append(param, value));

export const state = () => ({
  registerLink: registerLink.href,
  downloadAppLink: downloadLink.href,
  homeDownloadLink: homeDownloadLink.href,
  findUsLink: {
    facebook: 'https://www.facebook.com/Mitra-Bukalapak-449910405536673',
    youtube: 'https://www.youtube.com/playlist?list=PLzMtIVEHDtNoZJrPsUSDQ51ScZgsnTH39',
    instagram: 'https://www.instagram.com/mitrabukalapak',
    tiktok: 'https://www.tiktok.com/@mitrabukalapak',
  },
});

export const getters = {
  findUsLink: data => data.findUsLink,
  registerLink: data => data.registerLink,
  downloadAppLink: data => data.downloadAppLink,
  homeDownloadLink: data => data.homeDownloadLink,
};
