<template lang="pug">
.c-search-bar
  input.c-search-bar__input(
    placeholder="Apa yang ingin anda cari?"
    @keyup.enter="search"
    v-model="searchInput"
    ref="searchInput"
  )
  BlIconAv(name="ico_search_minor" color="#8D8D8D" @click.native="search")
</template>

<script>
import { BlIconAv } from '@bukalapak/bazaar-visual';

import eventBus from '~/components/articles/eventBus';

export default {
  name: 'SearchArticle',
  components: {
    BlIconAv,
  },
  data() {
    return {
      searchInput: '',
    };
  },
  watch: {
    $route(to, from) {
      this.syncSearchInput();
    },
  },
  async mounted() {
    await this.$nextTick();
    this.syncSearchInput();
  },
  created() {
    eventBus.$on('inputSearch', () => {
      this.$refs.searchInput.focus();

    });
  },
  methods: {
    syncSearchInput() {
      this.searchInput = '';
      if (this.$route.path === '/artikel/cari') {
        this.searchInput = this.$route.query.keywords;
        this.$refs.searchInput.focus();
      }
    },
    search() {
      const query = this.searchInput.trim();
      if (!query || query === this.$route.query.keywords) return;
      if (this.$route.path === '/artikel/cari') {
        this.$router.replace(`/artikel/cari?keywords=${query}`);
      } else {
        this.$router.push(`/artikel/cari?keywords=${query}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-search-bar {
  flex: 1;
  padding: 6px 16px;
  border-radius: 2px;
  background-color: #f5f5f5;
  display: flex;
  height: 39px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $global-breakpoint) {
    margin: 0 100px;
  }
  @media (max-width: $global-breakpoint) {
    margin: 8px 0 0 60px;
    // 76px = 60px burger menu width + 12px burger menu horizontal margin
    width: calc(100% - 76px);
    position: absolute;
  }

  &__input {
    font-size: 14px;
    color: #999;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.4;
    background: none;
    outline: none;
    border: none;
    flex: 1;
  }
}
</style>
