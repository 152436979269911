import axios from 'axios';
import { generateRequestContext } from './helpers/request-context-helper';

const DEFAULT_TOKEN_LIFESPAN = 7200;

const loadToken = req => req?.session?.token ?? null;

const saveToken = (req, data) => {
  req.session = {
    ...(req?.session ?? {}),
    token: data,
  };
};

const isTokenExpired = token => {
  if (token) {
    if (!token.created_at) {
      return true;
    }

    const tokenLifespan = token.expires_in || DEFAULT_TOKEN_LIFESPAN;
    const expiredAt = (token.created_at + tokenLifespan) * 1000;
    const now = Date.now();
    return expiredAt < now;
  }
  return true;
};

const requestPublicToken = async req => {
  const payload = {
    grant_type: 'client_credentials',
    client_id: process.env.HERMES_CLIENT_ID,
    client_secret: process.env.HERMES_CLIENT_SECRET,
    scope: 'public',
  };
  const config = {
    headers: {
      accept: 'application/json',
      host: process.env.API_ACCOUNTS_ORIGIN,
      'user-agent-original': req?.headers?.['user-agent-original'] || '',
      ...generateRequestContext(req),
    },
  };
  const { data } = await axios.post(`${process.env.API_ACCOUNTS_HOST}/oauth/token`, payload, config);
  data.login = false;
  return data;
};

export const setToken = async (req = {}) => {
  let token = loadToken(req);
  if (isTokenExpired(token)) {
    token = await requestPublicToken(req);
    saveToken(req, token);
  }
  return token;
};

export const setTokenHeader = req => {
  const userId = req.get('x-bl-userid');
  const isLogin = !!req.get('x-bl-userid');
  const auth = req.get('authorization') || '';
  const accessToken = auth.replace('Bearer ', '');
  const deviceId = req.get('x-device-ad-id');
  if (!accessToken || !isLogin) return;
  saveToken(req, {
    login: isLogin,
    access_token: accessToken,
    device_id: deviceId,
    expired_in: DEFAULT_TOKEN_LIFESPAN,
    created_at: Date.now() / 1000,
    userId,
  });
};
