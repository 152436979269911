<template lang="pug">
  .c-testimony
    TestimonySectionDesktop.desktop
    TestimonySectionMobile.mobile
</template>

<script>
import TestimonySectionDesktop from './desktop';
import TestimonySectionMobile from './mobile';

export default {
  name: 'TestimonySection',
  components: {
    TestimonySectionMobile,
    TestimonySectionDesktop,
  },
};
</script>
