import { getPromoDetail } from '~/api/promo';

export const state = () => ({
  banner: {
    image: '',
    title: '',
  },
  highlights: [],
  howTo: '',
  tnc: '',
  cta: {
    text: '',
    url: '',
  },
});

export const getters = {
  getBanner: state => state.banner,
  getHighlights: state => state.highlights || [],
  getVouchers: state => {
    const { highlights } = state;
    if (!highlights.length) return [];
    const filters = highlights.filter(data => data.key === 'vouchers');
    return filters.length ? filters[0].value : [];
  },
  getHowTo: state => state.howTo,
  getTnc: state => state.tnc,
  getCta: state => state.cta,
};

export const mutations = {
  setPromoDetail(state, data) {
    state.banner.title = data.title;
    state.banner.image = data.images && data.images[0].url;
    state.highlights = data.highlights;
    state.howTo = data.brief;
    state.tnc = data.term_condition;
    state.cta.text = data.cta_text;
    state.cta.url = data.cta_url;
  },
};

export const actions = {
  fetchPromoDetail({ commit }, promoId) {
    return new Promise((resolve, reject) => {
      getPromoDetail(promoId)
        .then(response => {
          commit('setPromoDetail', response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
