export const state = () => ({
  forDesktop: false,
  device: 'mobile',
});

export const getters = {
  getForDesktop: state => state.forDesktop || false,
  getDevice: state => state.device || 'mobile',
};

export const mutations = {
  setForDesktop(state, data) {
    state.forDesktop = data;
    state.device = data ? 'desktop' : 'mobile';
  },
};

export const actions = {
  saveForDesktop({ commit }, forDesktop) {
    commit('setForDesktop', forDesktop);
  },
};
