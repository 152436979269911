<template lang="pug">
  footer.c-footer
    FooterDesktop.desktop(:utmSource="`desktop_${utmSource}`", :downloadCampaign="downloadCampaign")
    FooterMobile.mobile(
      :utmSource="`mobile_${utmSource}`"
      :downloadCampaign="downloadCampaignMobile"
      :findUsHideLabel="mobileFindUsHideLabel"
      :downloadLink="downloadLink"
    )
</template>

<script>
import FooterDesktop from './desktop';
import FooterMobile from './mobile';

export default {
  name: 'Footer',
  components: {
    FooterDesktop,
    FooterMobile,
  },
  props: {
    utmSource: {
      type: String,
      default: 'footer',
    },
    downloadCampaign: {
      type: String,
      required: true,
    },
    landingCampaign: {
      type: String,
      default: '',
    },
    downloadLink: {
      type: String,
      default: '',
    },
    mobileFindUsHideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    downloadCampaignMobile() {
      return this.landingCampaign || this.downloadCampaign;
    },
  },
};
</script>
