import { choose, score } from '~/api/splitter';

export default ({ store, req }, inject) => {
  const Splitter = {
    participant: null,

    init() {
      this.participant = store.getters['auth/getUserId'] || store.state.auth.identity || req?.session?.identity;
    },

    /**
     * https://github.com/bukalapak/splitter/wiki/Choosing-Alternative
     *
     * @param {String} experiment
     * @param {Boolean} override
     */
    async choose(experiment, defaultValue = '', override = null) {
      this.init();
      if (experiment) {
        const requestPayload = { participant: this.participant };
        if (override) {
          requestPayload.override = override;
        }
        try {
          const response = await choose(experiment, requestPayload);
          return response.data.alternative;
        } catch (error) {
          console.error('[ERROR CHOOSING EXPERIMENT]', error.message);
        }
      }
      return defaultValue;
    },

    /**
     * https://github.com/bukalapak/splitter/wiki/Scoring-Metrics
     *
     * @param {String} experiment
     * @param {String} metric
     * @param {Number} value
     */
    async score(experiment, metric, value = 1) {
      this.init();
      if (experiment && metric) {
        const requestPayload = { metric, value };
        try {
          const response = await score(experiment, this.participant, requestPayload);
          return response;
        } catch (error) {
          console.error('[ERROR SCORING EXPERIMENT]', error.message);
        }
      }
      return null;
    },
  };

  inject('splitter', Splitter);
};
