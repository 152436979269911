const editDistance = (str1, str2) => {
  const s1 = str1.toLowerCase();
  const s2 = str2.toLowerCase();
  const costs = new Array();
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else if (j > 0) {
        let newValue = costs[j - 1];
        if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

export const similarity = (s1, s2) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
};

export const sanitize = str => {
  if (!str) return '';
  const newStr = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, '');
  return newStr.trim();
};

/**
 *  trimHTML
 *  Trims one-liner "rich text format" html into text only
 *
 * @export
 * @param {String} htmlString
 * @returns {String} Trimmed HTML
 */
export function trimHTML(htmlString) {
  return htmlString.replace(/<\/?([^>]+)>/g, '');
}
