const ProductItems = [
  {
    title: 'Agen Grosir',
    url: '/agen-grosir',
    subMenu: [
      {
        icon: 'agen-sembako.png',
        title: 'Sembako',
        url: '/agen-grosir/sembako',
      },
      {
        icon: 'distributor-minyak-goreng.png',
        title: 'Minyak Goreng',
        url: '/agen-grosir/minyak-goreng',
      },
      {
        icon: 'agen-telur.png',
        title: 'Telur',
        url: '/agen-grosir/telur',
      },
      {
        icon: 'agen-beras.png',
        title: 'Beras',
        url: '/agen-grosir/beras',
      },
      {
        icon: 'distributor-gula-pasir.png',
        title: 'Gula Pasir',
        url: '/agen-grosir/gula',
      },
      {
        icon: 'jualan-sayur.png',
        title: 'Sayur',
        url: '/agen-grosir/sayur',
      },
      {
        icon: 'jualan-buah.png',
        title: 'Buah',
        url: '/agen-grosir/buah',
      },
      {
        icon: 'jualan-ayam.png',
        title: 'Ayam',
        url: '/agen-grosir/ayam',
      },
      {
        icon: 'agen-makanan.png',
        title: 'Makanan',
        url: '/agen-grosir/makanan',
      },
      {
        icon: 'agen-minuman.png',
        title: 'Minuman',
        url: '/agen-grosir/minuman',
      },
    ]
  },
  {
    title: 'Agen PPOB',
    url: '#',
    subMenu: [
      {
        icon: 'ic_pulsa_dope.png',
        title: 'Pulsa dan Paket Data',
        url: '/agen-ppob/pulsa',
      },
      {
        icon: 'ic_tokenlistrik_dope.png',
        title: 'Token Listrik',
        url: '/agen-ppob/token-listrik',
      },
      {
        icon: 'ic_games_dope.png',
        title: 'Voucher Game',
        url: '/agen-ppob/voucher-games',
      },
      {
        icon: 'ic_bukacicilan_dope.png',
        title: 'Angsuran Kredit',
        url: '#',
      },
    ]
  },
  {
    title: 'Agen Ekspedisi',
    url: '#',
    subMenu: [
      {
        icon: 'jne.png',
        title: 'JNE',
        url: '#',
      },
      {
        icon: 'sicepat.png',
        title: 'SiCepat',
        url: '#',
      },
      {
        icon: 'jnt.png',
        title: 'J&T Express',
        url: '#',
      },
      {
        icon: 'ninja.png',
        title: 'Ninja Express',
        url: '#',
      },
      {
        icon: 'lionparcel.png',
        title: 'Lion Parcel',
        url: '#',
      },
    ]
  },
  {
    title: 'Agen Emas',
    url: '/agen-emas',
    subMenu: [
      {
        icon: 'ic_tabungan_emas_dope.png',
        title: 'Tabungan Emas',
        url: '/agen-emas',
      },
    ]
  },
  {
    title: 'Produk Lainnya',
    url: '/produk',
    subMenu: [
      {
        icon: 'ic_groupbuying_dope.png',
        title: 'Belanja Bareng',
        url: '/produk/belanja-bareng',
      },
      {
        icon: 'ic_bayarbukalapak_dope.png',
        title: 'Bayar Virtual Account',
        url: '#',
      },
      {
        icon: 'ic_qriscode_dope.png',
        title: 'QRIS',
        url: '#',
      },
      {
        icon: 'ic_kirimuang_dope.png',
        title: 'Kirim Uang',
        url: '#',
      },
      {
        icon: 'ic_bayarnanti_dope.png',
        title: 'Bayar Tempo',
        url: '#',
      },
    ]
  }
];

const MainMenu = [
  {
    title: 'FAQ',
    url: '/tentang-mitra/faq',
  },
  {
    title: 'Artikel',
    url: '/artikel',
  },
  {
    title: 'Tentang Kami',
    url: '/tentang-kami',
  }
];

export { ProductItems, MainMenu };
