import { getElectricityProducts, getPhoneTypeProducts } from '~/api/vp';

export const actions = {
  async retrievePhoneCreditsData() {
    try {
      const res = await getPhoneTypeProducts({ group: 'agent', category: 'topup' });
      const phoneCredits = res.data.reduce(reducePhoneType, []);

      return phoneCredits;
    } catch (e) {
      return [];
    }
  },

  async retrieveDataPlansData() {
    try {
      const res = await getPhoneTypeProducts({ group: 'agent', category: 'package' });
      const dataPlans = res.data.reduce(reducePhoneType, []);

      return dataPlans;
    } catch (e) {
      return [];
    }
  },

  async retrieveElectricitiesData() {
    try {
      const res = await getElectricityProducts({ group: 'agent' });
      const electricities = res.data;

      return electricities;
    } catch (e) {
      return [];
    }
  },
};

export const reducePhoneType = (current, next) => {
  const idx = current.findIndex(c => c.operator === next.operator.name.toLowerCase());

  if (idx !== -1) {
    current[idx].items.push({
      ...next.partner_package.package,
      nominal: next.nominal
    });
  } else {
    current.push({
      operator: next.operator.name.toLowerCase(),
      items: [{
        ...next.partner_package.package,
        nominal: next.nominal
      }]
    });
  }

  return current;
};
