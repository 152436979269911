let client;

export function setClient(newclient) {
  client = newclient;
}

// Request helpers
const reqMethods = [
  '$request', '$delete', '$get', '$head', '$options', // url, config
  '$post', '$put', '$patch', // url, data, config
];

const service = {};

reqMethods.forEach(method => {
  service[method] = function (...args) {
    if (!client) throw new Error('Client not installed');
    return client[method].apply(null, args).then(resp => ({ ...resp, ...resp.data }));
  };
});

export default service;
