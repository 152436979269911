<template lang="pug">
  .c-app-onboarding
    .desktop
      AppOnboardingDesktop(:steps="steps", :init="init")
    .mobile
      AppOnboardingMobile(:steps="steps", :init="init")
</template>

<script>
import AppOnboardingDesktop from './desktop';
import AppOnboardingMobile from './mobile';

export default {
  name: 'AppOnboarding',
  components: {
    AppOnboardingDesktop,
    AppOnboardingMobile,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    init: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
