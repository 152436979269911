import { render, staticRenderFns } from "./BannerFixed.vue?vue&type=template&id=08013424&scoped=true&lang=pug&"
import script from "./BannerFixed.vue?vue&type=script&lang=js&"
export * from "./BannerFixed.vue?vue&type=script&lang=js&"
import style0 from "./BannerFixed.vue?vue&type=style&index=0&id=08013424&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08013424",
  null
  
)

export default component.exports