<template lang="pug">
header.c-article-page-header(v-tid="$id.article.article_header")
  AppHeader.mobile
    template(v-if="isSearchBarOpened")
      template(slot="action")
        a.c-header__action__btn(@click="isSearchBarOpened = false")
          SFIcon.c-header__action__btn__icon(icon="ico_cross")
      template(slot="search")
        SearchArticle
    template(v-else)
      template(slot="title")
        a(:href="webHost")
          BLBPicture(
            v-tid="$id.article.article_header_logo"
            src="/images/logos/mitra-bl.svg"
            title="Mitra Bukalapak"
            :webp="false"
            :imgStyle="{ width: '106px' }"
          )
      template(slot="action2")
        a.c-header__action__btn(@click="isSearchBarOpened = true")
          SFIcon.c-search-bar__icon(icon="ico_search_minor")

  .o-container.desktop.pl-0
    a(:href="webHost")
      BLBPicture(
        src="/images/logos/mitra-bl.svg"
        title="Mitra Bukalapak"
        :webp="false"
        :imgStyle="{ width: '136px' }"
      )
    SearchArticle
    a.c-btn.c-btn--red(
      ref="startButton"
      :href="buttonLink"
      @click.native="handleClickMenu($event, 'Mulai', buttonLink)"
    ) Mulai
</template>

<script>
import { mapGetters } from 'vuex';
import SFIcon from '@bukalapak/storefront-icon';
import AppHeader from '~/components/AppHeader';
import BLBPicture from '~/components/shared/base/BLBPicture';
import SearchArticle from '~/components/SearchArticle';

// mixins
import tracker from '~/mixins/tracker';

import eventBus from '~/components/articles/eventBus';

export default {
  name: 'ArticlePageHeader',
  components: {
    AppHeader,
    BLBPicture,
    SearchArticle,
    SFIcon,
  },
  mixins: [tracker],
  data: () => ({
    webHost: process.env.WEB_HOST,
    isSearchBarOpened: false,
  }),
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
    }),
    buttonLink() {
      return this.isUserLoggedIn ? '/' : '/beranda';
    },
  },
  created() {
    eventBus.$on('inputSearch', () => {
      this.isSearchBarOpened = true;
    });
  },
  methods: {
    handleClickMenu(e, label, url) {
      const btnName = label.toLowerCase().replace(/\s+/g, '_');
      this.trackGoToHomepage(e, `button_${btnName}_article_page_menu`, url);
    },
  },
};
</script>

<style lang="scss" scoped>
// desktop
@media (min-width: $global-breakpoint) {
  .o-container {
    &.desktop {
      display: flex;
      align-items: center;
      height: 62px;
      max-width: $desktop-container-width;
    }
  }

  .header-sticky__spacer {
    flex: 1;
  }
}

// mobile
@media (max-width: $global-breakpoint) {
  .mobile {
    display: block !important;
    .c-header__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

// in between
@media (min-width: #{$global-breakpoint + 1}) and (max-width: #{$desktop-container-width - 1}) {
  .o-container {
    &.desktop {
      display: flex;
      align-items: center;
      height: 62px;
      max-width: $global-breakpoint;
    }
  }
}
</style>
