<template lang="pug">
BlTabsMv.flex-fit.sticky--top(
  :tabs="routeList"
  :initialTab="initialTab"
  :activeTabName.sync="selectedTab"
  :fixedWidthTabs="fixedTabs"
)
  template(v-for="(value, name) in pageList")
    component.max-boundary(
      :slot="`tab-nav-${name}`"
      v-bind="adaptLink(name, value)"
    )
      BlTabNavMv(:title="value.title")
        template(v-if="value.icon")
          BlIconAv(:name="value.icon" :alt="value.title" slot="iconTabNav")
</template>

<script>
import { BlIconAv } from '@bukalapak/bazaar-visual';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlTabContentMv from '@bukalapak/bazaar-mweb/dist/components/BlTabContentMv';
import BlTabNavMv from '@bukalapak/bazaar-mweb/dist/components/BlTabNavMv';
import BlTabsMv from '@bukalapak/bazaar-mweb/dist/components/BlTabsMv';

export default {
  name: 'MainNavigation',
  props: {
    pageList: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BlTabsMv,
    BlTabNavMv,
    BlTabContentMv,
    BlTextAv,
    BlIconAv,
  },
  computed: {
    routeList() {
      return Object.keys(this.pageList);
    },
    fixedTabs() {
      /* andaikan pipeline operator dah stage 3, bisa jadi,
       * this.pageList
       *  |> Object.entries
       *  .filter(([_, value]) => value.fixed)
       *  .map(([key, _]) => key)
       *  |> (bisa chain lagi disini)
       */
      return Object.entries(this.pageList)
        .filter(([_, value]) => value.fixed)
        .map(([key, _]) => key);
    },
    initialTab() {
      return Object.entries(this.pageList).find(([_, value]) => value.target === this.$route.name)?.[0] ?? 'home';
    },
  },
  data() {
    return {
      selectedTab: 'home',
    };
  },
  watch: {
    selectedTab(value) {
      this.$emit('onTabChanged', value, this.pageList[value]);
    },
    routeList() {
      this.selectedTab = this.initialTab;
    },
    $route() {
      this.selectedTab = this.initialTab;
    },
  },
  methods: {
    adaptLink(name, value) {
      const componentMatcher = [
        {
          condition: value.target.indexOf('http', 0) === 0 || value.target.indexOf('/', 0) === 0,
          component: 'a',
          props: { href: value.target },
        },
        { condition: value.target.includes('__'), component: 'div', props: { id: 'menu' } },
        { condition: true, component: 'nuxt-link', props: { to: { name: value.target } } },
      ];
      const matchedProp = componentMatcher.find(component => component.condition);
      return {
        is: matchedProp.component,
        ...matchedProp.props,
      };
    },
  },
};
</script>

<style lang="scss">
.flex-fit {
  .bl-tab__nav {
    overflow-x: hidden;
    text-transform: uppercase;
  }

  .bl-tab__action {
    flex-grow: 1;
    padding: 12px 8px;
  }
}
.max-boundary {
  width: 100%;
}

%sticky {
  position: sticky;
  z-index: 2;
}

.sticky {
  &--top {
    @extend %sticky;
    top: 0;
  }
  &--bottom {
    @extend %sticky;
    bottom: 0;
  }
}
</style>
