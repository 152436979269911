import Vue from 'vue';
import Tracker, { VueTracker } from '@bukalapak/tracker-js';
import { getTrackerPlatform } from '~/utils/platform-utils';

const isDev = process.env.NODE_ENV !== 'production';
const host = isDev ? `http://www.${process.env.WEB_DOMAIN}:9909` : 'https://t.bukalapak.com';

export default (ctx, inject) => {
  let platform = 'mitralite_hermes';
  if (process.client) {
    platform = getTrackerPlatform();
  }

  Tracker.config({
    baseUrl: host,
    dev: isDev || process.env.DEV_DEBUG_TRACKER,
    platform,
  });

  // Inject tracker instance as $tracker
  ctx.$tracker = Tracker;

  if (isDev) {
    ctx.$tracker.event = data => {
      console.warn('Tracker: ', data);
    };
  }
  inject('tracker', Tracker);

  // Register plugin
  Vue.use(VueTracker);
};
