import { retrieveCategory, retrieveArticleSSR, retrieveArticlesSSR } from '~/api/article';

export function getSlugType (paramSlug) {
  const splitDash = paramSlug.split('-');
  const id = splitDash[splitDash.length - 1];
  const slug = isNaN(Number(id)) ?  paramSlug : splitDash.slice(0, -1).join('-');
  return isNaN(Number(id)) ? { slug, id: null, type: 'category' } : { slug, id,  type: 'detail' };
};

export async function ssrRetrieveArticlesByCategory ({ paramSlug, query, store, redirect }) {
  try {
    // Fetch API
    const { data } = await retrieveCategory({ paramSlug });

    // Process Data
    const category = data[0];
    const limit = store.getters['article/getArticlesLimit'];
    const currentPage = query.page || 1;
    const offset = (currentPage - 1) * limit;
    const articles = await retrieveArticlesSSR({ offset, limit, categoryId: category.id });
    const articlesMeta = { ...articles.meta };
    delete articlesMeta.http_status;

    // Return Data
    return {
      articles: articles.data,
      articlesMeta,
      category,
      renderComponent: 'category'
    };
  } catch (e) {
    return redirect({ name: 'article_index' });
  }
};

export async function ssrRetrieveDetail ({ paramId, redirect }) {
  try {
    // Fetch API
    const response = await retrieveArticleSSR(paramId);
    const { body, id, topic } = response.data;

    // Cleanup unused attributes
    const cleanData = {
      ...response.data,
      body: body
      .replace(/\s*id="docs-internal-guid-(.*?)"/g, '')
      .replace(/\s*style="(.*?)"/g, '')
      .replace(/\s*dir="(.*?)"/g, '')
    };

    let relatedArticles = [];
    if (topic) {
      const topicArticlesData = await retrieveArticlesSSR({
          topic,
          limit: 6,
          excludedId: id,
          sort: 'search_volume'
        })
        .then(({ data }) => data)
        .catch(() => []);

      relatedArticles = topicArticlesData;
    }

    // Return Data
    return { currentArticle: cleanData, relatedArticles, renderComponent: 'detail' };
  } catch (e) {
    return redirect({ name: 'article_index' });
  }
};
