import r from '~/utils/render-utils';

const PageDemos = () => import(/* webpackChunkName: 'demos' */ '~/pages/Demos/Demos');
const PageDemoBanners = () => import(/* webpackChunkName: 'demos-banner' */ '~/pages/Demos/DemoBanners');

const demos = [
  {
    path: '/demos/',
    component: r(PageDemos),
    children: [
      { path: 'banners', component: r(PageDemoBanners), name: 'demo_banners' },
    ],
  },
];

export default demos;
