import { mapGetters, mapActions } from 'vuex';

const RESIZE_DELAY = 75;
let timeout = null;

const responsive = {
  created() {
    if (process.server) return;
    window.addEventListener('resize', this.handleResize);
    this.initDevice();
  },
  destroyed() {
    clearTimeout(timeout);
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters({
      forDesktop: 'responsive/getForDesktop',
      device: 'responsive/getDevice',
    }),
  },
  methods: {
    ...mapActions({
      saveForDesktop: 'responsive/saveForDesktop',
    }),
    initDevice() {
      this.saveForDesktop(window.matchMedia('(min-width: 983px)').matches);
    },
    handleResize() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.saveForDesktop(window.matchMedia('(min-width: 983px)').matches);
      }, RESIZE_DELAY);
    },
  },
};

export default responsive;
