/**
 * @module helpers/date-helper
 */

const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
export const listOfMonths = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];
const monthShorts = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Agt',
  'Sep',
  'Okt',
  'Nov',
  'Des',
];

/**
 * Return human readable timestamp format
 *
 * @example
 * humanizeTimestamp('2017-06-23T14:15:53.886+07:00', '%day% %dd% %mmm% %yyyy% - %hh%:%mi% WIB')
 * // => Jumat 23 Jun 2017 - 14:15 WIB
 *
 * @param  {String} timestamp timestamp format
 * @param  {String} format specific wanted format to humanize the timestamp (in-casesensitive)
 *
 * @return {String}
 */
export function humanizeTimestamp(timestamp, format = '%day%, %dd% %month% %yyyy%') {
  if (!timestamp) return '';
  const dateTime = new Date(timestamp);
  const timeMapping = {
    '%hh%': `0${dateTime.getHours()}`.slice(-2),
    '%mi%': `0${dateTime.getMinutes()}`.slice(-2),
    '%dd%': `0${dateTime.getDate()}`.slice(-2),
    '%day%': days[dateTime.getDay()],
    '%mm%': `0${dateTime.getMonth() + 1}`.slice(-2),
    '%mmm%': monthShorts[dateTime.getMonth()],
    '%month%': listOfMonths[dateTime.getMonth()],
    '%yyyy%': dateTime.getFullYear(),
  };
  return format.replace(/%(.*?)%/gi, n => timeMapping[n]);
}

export const prependZero = number => (Number(number) < 10 ? `0${Number(number).toString()}` : Number(number).toString());

/**
 * Time Filter
 * @param value {string} ISO 8601 format, e.g '2017-01-31T02:30:00+07:00'
 * @return hour:minute e.g '02:30'
 */
export const customTime = value => {
  if (value === undefined) {
    throw new Error('value cant be null');
  }

  if (typeof value === 'number') {
    throw new Error('type must be string or date');
  }

  let date = value;
  if (!(value instanceof Date)) {
    date = new Date(value);
  }
  return `${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
};

export function timeDiff(start, end, unit = 'ms') {
  const diff = end - start;

  switch (unit) {
    case 's':
      return diff / 1000;
    default:
      return diff;
  }
}

export function timeDiffSeconds(start, end) {
  return timeDiff(start, end, 's');
}

export function listOfLast100Years() {
  const years = [];
  const currentYear = (new Date()).getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i -= 1) {
    years.push(i);
  }
  return years;
}

export default {
  listOfMonths,
  listOfLast100Years,
  humanizeTimestamp,
  customTime,
  timeDiff,
  timeDiffSeconds,
};
