import externalVisitTrackerJs from '@bukalapak/external-visit-tracker-js';
import { datadogLogs } from '@datadog/browser-logs';
import { generateBrowserID, getPlatform } from '~/utils/platform-utils';

const clientToken = process.env.DATADOG_LOG_ID || '';
const env = /preproduction|local/i.test(window.location.host) ? 'preproduction' : 'production';
const sampleRate = 50;
const service = 'external-visit-tracker-js';

const ExternalVisitTrackerJs = ({ app, store }) => {
  const userId = store.getters['auth/getCurrentUserId36'];
  const axios = app.$axios;
  const clientId = generateBrowserID();
  const platform = getPlatform();
  const identity = store.getters['auth/getIdentity'];
  const cookieDomain = process.env.WEB_DOMAIN;

  datadogLogs.init({
    clientToken,
    env,
    sampleRate,
    service,
  });

  datadogLogs.addLoggerGlobalContext('host', window.location.origin);

  externalVisitTrackerJs.run({
    cookieDomain,
    axios,
    userId,
    clientId,
    platform,
    identity,
    logger: {
      error: (message, messageContext) => datadogLogs && datadogLogs.logger.error(message, messageContext),
    },
  });
};

export default ExternalVisitTrackerJs;
