<template lang="pug">
  .c-footer__content
    .c-footer__content.pb-12.divider
    .c-footer__content.o-flag
      .footer-download
        BLBPicture(
          src="/images/logos/mitra-bl.svg"
          title="Mitra Bukalapak"
          img-class="footer-download-img__pic"
          :webp="false"
          :imgStyle="{ width: '100%', height: '100%' }"
        )
      .c-footer__content.wrapper
        BlFlexContainerMv.flex-container-new-misi.mt-8
          .new-footer-container
            ul
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--title.pb-24 Alamat
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description PT Bukalapak.com Tbk
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description Kantor Pusat | Metropolitan Tower
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description Jl. R.A. Kartini Kav. 14, RT 10/RW 4,
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description Cilandak Barat, Cilandak
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description Jakarta Selatan, 12430
              li.pt-20(style='list-style-type:none')
                BlTextAv.c-footer__content--alamat-description Telepon: +62 21 50982008
          .new-footer-container
            ul
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--title.pb-24 Jelajah Website
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--jelajah-description(href="/tentang-kami") Tentang Kami
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--jelajah-description(href="/tentang-mitra/faq")  FAQ
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--jelajah-description(href="/artikel") Blog
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--jelajah-description(href="/terms") Syarat dan ketentuan
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--jelajah-description(href="/terms?tab=kebijakan-privasi") Kebijakan Privasi
          .new-footer-container
            ul 
              li(style='list-style-type:none')
                BlTextAv.c-footer__content--title.pb-24 Temukan kami di
              li(style='list-style-type:none') 
                a.u-mrgn-right--4(
                  :href="`${findUsLink.instagram}?utm_source=desktop_footer_landing`"
                  target="_blank"
                  @click="trackEventClick('instagram_desktop_footer_landing')"
                )
                  SFIcon.c-footer__content--icon(icon="ico_instagram")
                a.u-mrgn-right--4(
                  :href="`${findUsLink.facebook}?utm_source=desktop_footer_landing`"
                  target="_blank"
                  @click="trackEventClick('facebook_desktop_footer_landing')"
                )
                  SFIcon.c-footer__content--icon(icon="ico_facebook")
                a(
                  :href="`${findUsLink.tiktok}?utm_source=desktop_footer_landing`"
                  target="_blank"
                  @click="trackEventClick('tiktok_desktop_footer_landing')"
                )
                  IconTiktok 
          .new-footer-container
            ul
              li(style='list-style-type:none') 
                BlTextAv.c-footer__content--title.pb-24 Install sekarang
              li(style='list-style-type:none')
              .container-footer
                .container-image-footer
                  a(:href="downloadLink", target="_blank", @click="trackEventClick('download_playstore_aplikasi_mitra')")
                    BLBPicture(
                      :lazy="false"
                      :webp="false"
                      img-class="google-play"
                      src="/images/footer/google_play.png"
                      :img-style="{ width: '146px', height: '46px' }"
                      title="Google play"
                    )

                .container-image-footer.mt-24
                  BLBPicture(
                    :lazy="false"
                    :webp="false"
                    img-class="qr-bl"
                    src="/images/footer/qr_bl.png"
                    :img-style="{ width: '120px', height: '120px' }"
                    title="QR Bukalapak"
                  )
    .copyright
      BlTextAv.copyright-text.pt-20.pb-20 © 2024 Hak Cipta Terpelihara PT Bukalapak.com  
</template>

<script>
import { mapGetters } from 'vuex';
import BlLinkAv from '@bukalapak/bazaar-mweb/dist/components/BlLinkAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BLBPicture from '~/components/shared/base/BLBPicture';
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import SFIcon from '@bukalapak/storefront-icon';
import tracker from '~/mixins/tracker';
import IconTiktok from '~/components/NewFooter/IconTiktok.vue';

export default {
  name: 'FooterDesktop',
  components: {
    BlTextAv,
    BLBPicture,
    BlFlexItemAv,
    BlFlexContainerMv,
    SFIcon,
    IconTiktok,
    BlLinkAv,
  },
  mixins: [tracker],
  props: {
    downloadLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      findUsLink: 'global/findUsLink',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$margin-left-footer: 84px;
$margin-right-footer: 84px;

.c-footer__content {
  &--title {
    font-weight: $font-weight-bold;
    font-size: $font-size-28;
    line-height: $line-height-36;
  }

  &--alamat-description {
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
  }

  &--alamat-description-padding {
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
    padding-bottom: $space-20;
  }

  &--jelajah-description {
    font-weight: $font-weight-regular;
    line-height: $line-height-24;
    font-size: $font-size-16;
    color: $border-foreground !important;
  }

  &--icon {
    width: $space-24;
    height: $space-24;
  }
}

ul {
  margin: 0;
}

ul>li,
ol>li {
  padding-left: 0;
}

.footer-download {
  width: 192px;
  height: 96px;
  margin-left: $space-60;
  margin-right: $space-60;
}

.divider {
  border-top: 1px solid $border-medium;
}

.wrapper {
  margin-left: $margin-left-footer;
  margin-right: $margin-right-footer;
}

.copyright {
  margin-top: $space-60;
  height: $space-60;
  background-color: $background-washedout;
}

.copyright-text {
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  line-height: $line-height-20;
  margin-left: $margin-left-footer;
}

.container-footer {
  display: flex;
  flex-direction: column;
}

.container-image-footer {
  display: flex;
  flex-direction: row;
}

.flex-container-new-misi {
  flex-direction: row;
  justify-content: space-between;
  gap: $space-20;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .flex-container-new-misi {
    flex-direction: column;
    gap: $space-40;
  }

  .wrapper {
    margin-left: $space-20;
    margin-right: $space-20;
  }

  .copyright-text {
    margin-left: $space-20;
    font-size: $font-size-14;
  }

  .footer-download {
    padding-left: 0 !important;
    margin-left: 0;
    margin-right: 0;
  }


  .c-footer__content {
    &--title {
      font-weight: $font-weight-medium;
      font-size: $font-size-18;
      line-height: $line-height-22;
      padding-bottom: $space-12 !important;
    }

    &--alamat-description {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: $line-height-22;
    }

    &--alamat-description-padding {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: $line-height-22;
      padding-bottom: $space-20;
    }

    &--jelajah-description {
      font-weight: $font-weight-regular;
      line-height: $line-height-22;
      font-size: $font-size-14;
      color: $border-foreground !important;
    }
  }
}
</style>
