<template lang="pug">
  .tentang-kami-page-video-pilihan
    .tentang-kami-page-video-pilihan__content.container-video-pilihan
      .tentang-kami-page--title-container
        BlTextAv.tentang-kami-page--title Video Pilihan
      .tentang-kami-page--flex-container-video-pilihan.mt-3(alignItems="center")      
        .tentang-kami-page--video-pilihan
          video.video-pilihan(width="100%", height="100%", controls)
            source(src=`${process.env.ASSETS_URL}/tentang-kami/berkawan_dengan_zaman.mp4`, type="video/mp4")
        .tentang-kami-page--video-pilihan
          video.video-pilihan(width="100%", height="100%", controls)
            source(src=`${process.env.ASSETS_URL}/tentang-kami/mengenal_aplikasi_mitra_bukalapak.mp4`, type="video/mp4")
        .tentang-kami-page--video-pilihan
          video.video-pilihan(width="100%", height="100%", controls)
            source(src=`${process.env.ASSETS_URL}/tentang-kami/kaleidoskop_spesial_kumpul_juwara.mp4`, type="video/mp4")
</template>


<script>
import BlFlexItemAv from "@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv";
import BlFlexContainerMv from "@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv";
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";

export default {
  name: "TentangKamiPageVideoPilihan",
  components: {
    BlFlexItemAv,
    BlFlexContainerMv,
    BlTextAv,
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

$tentang-kami-page-margin-top: 120px;
$video-pilihan-height: 232px;
$video-pilihan-width: 130px;


.container-video-pilihan {
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.video-pilihan {
  border-radius: $space-12;
  box-sizing: border-box;
}

.tentang-kami-page {
  margin-bottom: $space-20;
  margin-top: $tentang-kami-page-margin-top;

  &--title {
    margin-bottom: $space-24;
    font-size: $font-size-42;
    line-height: $line-height-48;
    font-weight: $font-weight-medium;
    color: $border-foreground;
  }

  &--flex-container-video-pilihan {
    gap: $space-8;
    display: flex;
    flex-direction: row;
    justify-self: space-between;
  }
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .tentang-kami-page {
    margin-bottom: $space-20;
    margin-top: $tentang-kami-page-margin-top;

    &--title {
      font-size: $font-size-28;
      line-height: $line-height-34;
      font-weight: $font-weight-bold;
    }

    &--title-container {
      text-align: center;
    }
  }

  .video-pilihan {
    width: $video-pilihan-height;
    height: $video-pilihan-width;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .tentang-kami-page {
    &--title {
      font-size: $font-size-32;
      line-height: $line-height-34;
    }
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .tentang-kami-page {
    &--title {
      font-size: $font-size-42;
      line-height: $line-height-34;
    }
  }
}


</style>
