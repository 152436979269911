// article page
import r from '~/utils/render-utils';
import { sanitize } from '~/utils/string-utils';

const PageArticle = () => import(/* webpackChunkName: 'article' */ '~/pages/ArticlePage');
const PageArticleIndex = () => import(/* webpackChunkName: 'article' */ '~/pages/Article/index');
const PageArticleToday = () => import(/* webpackChunkName: 'article' */ '~/pages/Article/today');
const PageArticleSearch = () => import(/* webpackChunkName: 'article' */ '~/pages/Article/search');
const PageArticleSlug = () => import(/* webpackChunkName: 'article' */ '~/pages/Article/slug');

const article = [
  {
    path: '/artikel/',
    component: r(PageArticle),
    children: [
      { path: '', component: r(PageArticleIndex), name: 'article_index' },
      { path: 'hari-ini', component: r(PageArticleToday), name: 'article_today' },
      {
        path: 'cari',
        component: r(PageArticleSearch),
        name: 'article_search',
        props: route => ({
          keywords: sanitize(route.query.keywords),
        }),
      },
      { path: ':slug', component: r(PageArticleSlug), name: 'article_slug' },
    ],
  },
];

export default article;
