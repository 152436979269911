export const state = () => ({
  isAppLoading: false,
});

export const getters = {
  isAppLoading: s => s.isAppLoading,
};

export const mutations = {
  setAppLoading(s, value) {
    s.isAppLoading = value;
  },
};

export const actions = {
  showAppLoading({ commit, state: s }) {
    if (!s.isAppLoading) commit('setAppLoading', true);
  },
  hideAppLoading({ commit, state: s }) {
    if (s.isAppLoading) commit('setAppLoading', false);
  },
};
