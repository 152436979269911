/* istanbul ignore file */

// service worker is untestable
export default async (_, inject) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Skipping service worker installation due development mode.');
    return;
  }
  try {
    const reg = await navigator.serviceWorker?.register(`${process.env.WEB_HOST}/sw.js`);
    // eslint-disable-next-line no-console
    console.log('Worker installed for ', reg.scope);
    inject('sw', reg);
  } catch (error) {
    console.warn('Service worker is unexpectedly closed.', error);
  }
};
