<template lang="pug">
nav.main-menu
  template(v-for="menu in menuList")
    component(v-bind="adaptLink(menu.link)")
      BlCellMv(:text="menu.title" hasBorder="bottom")
        BlIconAv(slot="iconLeft" :name="menu.icon" color="active-darker")
        BlIconAv(
          slot="iconRight"
          name="ico_chevron_right_minor"
          color="active-darker"
        )
</template>

<script>
import { BlIconAv, BlIllustrationAv } from '@bukalapak/bazaar-visual';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlCellMv from '@bukalapak/bazaar-mweb/dist/components/BlCellMv';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';

export default {
  name: 'Menu',
  components: {
    BlIconAv,
    BlIllustrationAv,
    BlCellMv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTextAv,
  },
  computed: {
    registerLink() {
      const targetUrl = new URL(`${process.env.WEB_HOST}/register`);
      targetUrl.searchParams.append('from', 'menu');
      return targetUrl.href;
    },

    menuList() {
      return [
        { title: 'Daftar', icon: 'ico_user', link: this.registerLink },
        { title: 'Tentang Mitra', icon: 'ico_info', link: { name: 'agenlite_landing_root' } },
        { title: 'FAQ', icon: 'ico_headset', link: { name: 'agenlite_faq' } },
        { title: 'Artikel', icon: 'ico_blog', link: { name: 'article_index' } },
      ];
    },
  },
  methods: {
    adaptLink(url) {
      const isRouterLink = !!url?.name;
      return {
        is: isRouterLink ? 'nuxt-link' : 'a',
        ...(!isRouterLink && { href: url }),
        ...(isRouterLink && { to: url }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
