import axios from 'axios';

const retrieveSeoProps = ({ pageKey }) => {
  const user = process.env.TRENDQWORD_BASIC_USER;
  const password = process.env.TRENDQWORD_BASIC_PASSWORD;
  const Authorization = `Basic ${new Buffer(`${user}:${password}`).toString('base64')}`;

  return axios.get(`${process.env.TRENDQWORD_HOST}/_internal/seo-properties${pageKey}?subdomain=mitra`, {
    headers: { Authorization }
  });
};

export default retrieveSeoProps;
