<template lang="pug">
.landing-page-hero--desktop.u-position-relative
  .landing-page-hero__image
    BLBPicture(
      datatest-id="hero-image-mobile"
      :imgStyle="{ width: '100%' }"
      src="/images/landing/jagoan/landing-desktop.png"
      title="Semua ada semua bisa - Gabung Mitra Bukalapak raih untung jutaan!"
    )
  .landing-page-hero__wrapper
    .landing-page-hero__content.o-container
      .landing-page-hero__content--wrapper
        bl-text-av.mb-32(variation="heading-42" tag="h1" color="inverse") Siapa pun bisa jualan dengan untung jutaan!
        bl-text-av.mb-32(variation="body-long-16" tag="h5" color="inverse") Buat gabung jadi Mitra Bukalapak, kamu gak perlu warung, kios atau toko. Siapa pun bisa mulai jualan! Dari jualan aneka produk virtual, hingga belanja stok warung, semuanya bisa bikin kamu untung-tung-tung! Yuk, gabung sekarang.
        .landing-page-hero--cta
          bl-flex-container-mv(v-if="!isUserLoggedIn" alignItems="center")
            bl-flex-item-av
              a#landing-desktop-cta-google-play(:href="googlePlayUrl" target="_blank" rel="noopener")
                BLBPicture(
                  src="/images/3rd-parties/google-play-button.png"
                  :imgStyle="{ height: '46px' }"
                  title="Download aplikasi via Google Play Store"
                )
            bl-flex-item-av
              bl-button-av#landing-desktop-cta-register.ml-16(
                variation="outline"
                size="large"
                :href="registerHeroLanding"
                @click.stop="handleClickRegister($event, registerHeroLanding)"
              ) Daftar Sekarang
</template>

<script>
import { mapGetters } from 'vuex';
import SFIcon from '@bukalapak/storefront-icon';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BLBPicture from '~/components/shared/base/BLBPicture';
import tracker from '~/mixins/tracker';
import urlHelper from '~/mixins/url-helper';

export default {
  name: 'LandingPageHeroDesktop',
  components: {
    BlTextAv,
    BLBPicture,
    BlButtonAv,
    BlFlexItemAv,
    BlFlexContainerMv,
    SFIcon,
  },
  mixins: [tracker, urlHelper],
  data: () => ({
    homeUrl: '/',
    loginUrl: '/login',
    registerUrl: '/register',
    registerUtm: 'registration_source',
    campaignUrl: '',
    campaignUtm: 'utm_campaign',
    sourceUrl: '',
    sourceUtm: 'utm_source',
    mediumUrl: '',
    mediumUtm: 'utm_medium',
    desktopHeroLandingUrl: 'desktop_landing_page_hero',
    referrer: '',
  }),
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isUserLoggedIn',
      downloadAppLink: 'global/downloadAppLink',
      homeDownloadLink: 'global/homeDownloadLink',
    }),
    googlePlayUrl() {
      if (this.$route.name === 'agenlite_landing_root') {
        return `${this.homeDownloadLink}?campaign=mitra-web-landingpage&adgroup=google-play-button-main-banner&creative=${this.referrer}`;
      }

      return `${this.downloadAppLink}?campaign=landing-hero-banner`;
    },
    hasCampaignUTM() {
      return (
        this.getUTMVariable(this.campaignUtm) &&
        this.getUTMVariable(this.sourceUtm) &&
        this.getUTMVariable(this.mediumUtm)
      );
    },
    homeHeroLanding() {
      return `${this.homeUrl}${this.utmString()}`;
    },
    loginHeroLanding() {
      return `${this.loginUrl}${this.utmString()}`;
    },
    registerHeroLanding() {
      return `${this.registerUrl}?${this.registerUtm}=${this.desktopHeroLandingUrl}${this.utmString('&')}`;
    },
  },
  mounted() {
    this.setReferrer();
    this.setUrls();
  },
  methods: {
    setReferrer() {
      // Somehow, we can't get the referrer when it's called directly via computed
      this.referrer = (!process.server && document && document.referrer) || '';
    },
    goToBenefit($event) {
      document.querySelector('#benefit').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      this.trackClick($event, 'button_learnmore_cta_landing_page_hero');
    },
    setUrls() {
      if (!this.hasCampaignUTM) return;
      this.campaignUrl = this.getUTMVariable(this.campaignUtm);
      this.sourceUrl = this.getUTMVariable(this.sourceUtm);
      this.mediumUrl = this.getUTMVariable(this.mediumUtm);
    },
    utmString(prefix = '?') {
      return this.hasCampaignUTM ?
        `${prefix}utm_campaign=${this.campaignUrl}&utm_source=${this.sourceUrl}&utm_medium=${this.mediumUrl}` :
        '';
    },
    handleClickRegister(e, url) {
      this.trackRegister(e, `button_register_landing_page_hero`, url);
    },
  },
};
</script>

<style lang="scss" scoped>
$break-small: $global-breakpoint;
$break-med: $global-breakpoint;

@mixin respond-to($media) {
  @if $media == smalldesktop {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == mediumdesktop {
    @media only screen and (min-width: $break-small) and (max-width: $break-med) {
      @content;
    }
  } @else if $media == largedesktop {
    @media only screen and (min-width: $break-med) {
      @content;
    }
  }
}

@keyframes bounce {
  0%,
  40%,
  80% {
    transform: translate3d(0, 0, 0);
  }
  20%,
  60% {
    transform: translate3d(0, -12px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.landing-page-hero {
  &__wrapper {
    position: absolute;
    top: 25%;
    width: 100%;
  }

  &__content {
    position: relative;
    min-width: $global-breakpoint;
    max-width: 1170px;
    margin: 0 auto;
    z-index: 2;
    color: #fff;
    @include respond-to(smalldesktop) {
      min-width: auto;
    }

    &--wrapper {
      width: 100%;
      max-width: 450px;

      @include respond-to(smalldesktop) {
        max-width: 100%;
      }
    }
  }

  &--title {
    @include respond-to(smalldesktop) {
      font-size: 1.3rem;
      margin-bottom: 12px !important;
    }
  }

  &--subtitle {
    line-height: 1.67;
    @include respond-to(smalldesktop) {
      font-size: 1rem;
      margin-bottom: 12px !important;
    }
  }

  &__scroll {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;

    .scroll-wrap {
      &--outer {
        z-index: 3;
        box-sizing: border-box;
        width: 182px;
        height: 182px;
        border-radius: 182px;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.56), rgba(203, 210, 246, 0.56));
        padding: 19px;
        transition: all 250ms ease-in;

        @include respond-to(smalldesktop) {
          transform: scale(0);
          opacity: 0;
        }

        @include respond-to(mediumdesktop) {
          transform: scale(0);
          opacity: 0;
        }
      }

      &--inner {
        cursor: pointer;
        width: 144px;
        height: 144px;
        border-radius: 144px;
        background-color: rgba(255, 255, 255, 0.66);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #333;

        &:hover {
          .icon-wrap {
            animation: bounce 1000ms ease-in infinite alternate;
          }
        }

        .icon-wrap {
          width: 32px;
          height: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .c-icon {
            line-height: 0;

            &:first-child {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
