<template lang="pug">
.c-footer__find-us.u-flex.u-flex--center
  h6.u-txt--lighter.u-mrgn-right--3(v-if="!hideLabel") Temukan kami di:
  a(
    :href="`${findUsLink.facebook}?utm_source=${utmSource}`"
    target="_blank"
    @click.stop="trackClick($event, `facebook_${utmSource}`)"
    aria-label="Tombol Facebook Mitra Bukalapak"
    rel="noopener"
  )
    SFIcon.ico(icon="ico_facebook")
  a(
    v-if="!hideYoutube"
    :href="findUsLink.youtube"
    target="_blank"
    @click.stop="trackClick($event, `youtube_${utmSource}`)"
    aria-label="Tombol Youtube Mitra Bukalapak"
    rel="noopener"
  )
    SFIcon.ico(icon="ico_youtube")
  a(
    :href="`${findUsLink.instagram}?utm_source=${utmSource}`"
    target="_blank"
    @click.stop="trackClick($event, `instagram_${utmSource}`)"
    aria-label="Tombol Instagram Mitra Bukalapak"
    rel="noopener"
  )
    SFIcon.ico(icon="ico_instagram")
</template>

<script>
import { mapGetters } from 'vuex';
import SFIcon from '@bukalapak/storefront-icon';

// mixins
import tracker from '~/mixins/tracker';

export default {
  name: 'FooterFindUsMobile',
  components: {
    SFIcon,
  },
  mixins: [tracker],
  props: {
    utmSource: {
      type: String,
      default: 'mobile_footer',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideYoutube: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      findUsLink: 'global/findUsLink',
    }),
  },
};
</script>

<style lang="scss" scoped>
.c-footer {
  &__find-us {
    line-height: 0;
    white-space: nowrap;

    h6 {
      line-height: 1.38;
    }

    a:not(:first-of-type) {
      margin-left: 8px;
    }

    .ico {
      fill: #000;
      font-size: 32px;
      padding: 4px;
      border-radius: 32px;
      background-color: white;
    }
  }
}
</style>
