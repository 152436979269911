<template lang="pug">
.c-footer-find-us
  bl-flex-container-mv(
    direction="row"
    :justifyContent="forDesktop? 'flex-start' : 'center'"
    alignItems="center"
    wrap="nowrap"
  )
    bl-flex-item-av.mr-8
      bl-text-av(
        v-if="showLabel"
        tag="h5"
        variation="body-16"
      ) Temukan kami di
    bl-flex-item-av.mr-8
      a.c-footer-find-us-logo(
        :href="`${findUsLink.facebook}?utm_source=${utmSource}`"
        target="_blank"
        @click.stop="trackClick($event, `facebook_${utmSource}`)"
        aria-label="Tombol Facebook Mitra Bukalapak"
        rel="noopener"
      )
        bl-icon-av(name="ico_facebook" color="#2E2E2E" :style="{ height: '100%' }")
    bl-flex-item-av.mr-8
      a.c-footer-find-us-logo(
        :href="`${findUsLink.instagram}?utm_source=${utmSource}`"
        target="_blank"
        @click.stop="trackClick($event, `instagram_${utmSource}`)"
        aria-label="Tombol Instagram Mitra Bukalapak"
        rel="noopener"
      )
        bl-icon-av(name="ico_instagram" color="#2E2E2E" :style="{ height: '100%' }")
    bl-flex-item-av
      a.c-footer-find-us-logo(
        v-if="!hideYoutube"
        :href="findUsLink.youtube"
        target="_blank"
        @click.stop="trackClick($event, `youtube_${utmSource}`)"
        aria-label="Tombol Youtube Mitra Bukalapak"
        rel="noopener"
      )
        bl-icon-av(name="ico_play_circle" color="#2E2E2E" :style="{ height: '100%' }")
</template>

<script>
import { mapGetters } from 'vuex';

import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';

import { BlIconAv } from '@bukalapak/bazaar-visual';

// mixins
import responsive from '~/mixins/responsive';
import tracker from '~/mixins/tracker';

export default {
  name: 'FooterFindUsDesktop',
  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlIconAv,
    BlTextAv,
  },
  mixins: [responsive, tracker],
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    utmSource: {
      type: String,
      default: 'desktop_footer',
    },
    hideYoutube: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      findUsLink: 'global/findUsLink',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/@bukalapak/bazaar-token/dist/tokens.scss';

.c-footer {
  &-find-us {
    &-logo {
      width: $space-40;
      height: $space-40;
      background-color: $system-white;
      display: block;
      text-align: center;
      border-radius: $border-radius-circle;

      svg {
        height: 100%;
      }
    }
  }
}
</style>
