<template lang="pug">
ul.breadcrumb
  li(v-for="(item, index) in data" :key="index")
    a.parent(
      v-if="index != data.length - 1" 
      :href="typeof item.path === 'object' ? $router.resolve(item.path).href : item.path"
    ) {{ item.text }}
    a.current(
      v-else 
      :href="typeof item.path === 'object' ? $router.resolve(item.path).href : item.path"
    ) {{ item.text }}
    span.separator(v-if="index != data.length - 1") {{ separator }}
</template>

<script>
export default {
  name: 'Breadcrumb',
  components: {},
  props: {
    separator: {
      type: String,
      default: '/',
    },
    data: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 0;
  margin: 0;
  margin-top: 25px;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  li {
    padding: 0;
    display: inline;
    font-size: 14px;
    color: black;
    a {
      color: inherit;
    }
  }

  .parent {
   opacity: .6; 
  }

  .separator {
    opacity: .6; 
    padding: 0 0.5em;
  }
}
</style>
