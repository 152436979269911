<template lang="pug">
  .tentang-kami-page-map-mitra 
    .tentang-kami-page-map-mitra__content.container-map-mitra 
      BlFlexContainerMv.map-mitra-flex-container
        .map-mitra-title 
          BlTextAv.map-mitra-title(weight="medium" variation="heading-42") Sebaran Juragan 
          BlTextAv.map-mitra-title(weight="medium" variation="heading-42") Mitra Bukalapak di Indonesia
        .map-mitra-img 
          BLBPicture.pencapaian-mitra-img(
            :lazy="false"
            :webp="false"
            img-class="map-mitra-img__pic"
            src="/images/tentang-kami/map_mitra.png"
            title="Map Mitra"
            :imgStyle="{ width: '100%'}"
          )
</template>

<script>
import BlTextAv from "@bukalapak/bazaar-mweb/dist/components/BlTextAv";
import BLBPicture from "~/components/shared/base/BLBPicture.vue";

export default {
  name: "TentangKamiPagePencapaianMitra",
  components: {
    BlTextAv,
    BLBPicture,
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.map-mitra-flex-container {
  flex-direction: column;
}

.container-map-mitra {
  width: 100%;
}

.map-mitra-title {
  line-height: $line-height-48;
  letter-spacing: $space-0;
  text-align: center;
}

@media only screen and (min-width: 360px) and (max-width: 680px) {
  .map-mitra-title {
    font-size: $font-size-28;
    line-height: $line-height-34;
    font-weight: $font-weight-bold;
  }
}

@media only screen and (min-width: 681px) and (max-width: 800px) {
  .map-mitra-title {
    font-size: $font-size-32;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1023px) {
  .map-mitra-title {
    font-size: $font-size-42;
  }
}

</style>
