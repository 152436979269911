export const actions = {
  getUserCurrentGeolocation(store, { highAccuracy = true } = {}) {
    return new Promise((resolve, reject) => {
      const geoOptions = {
        enableHighAccuracy: highAccuracy,
        maximumAge: 120000,
        timeout: 120000,
      };

      if (navigator && navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          position => {
            if (!highAccuracy || position.coords.accuracy <= 50) {
              navigator.geolocation.clearWatch(watchId);
              this.dispatch('flashalert/hideFlashAlert');
              resolve(position.coords);
            }
          },
          error => {
            let errorMessage = '';
            if (error) {
              switch (error.code) {
                case 1: // PERMISSION_DENIED
                  errorMessage = 'Aktifkan layanan lokasi untuk melanjutkan. Buka Menu > Setelan/Pengaturan > Pengaturan Situs > Lokasi.';
                  break;
                case 2: // POSITION_UNAVAILABLE
                  errorMessage = 'Aktifkan GPS untuk melanjutkan.';
                  break;
                case 3: // TIMEOUT
                default:
                  errorMessage = '';
              }
            }
            this.dispatch('flashalert/showFlashAlert', {
              message: errorMessage,
              type: 'info',
            });
            reject(error);
          },
          geoOptions,
        );
      } else {
        this.dispatch('flashalert/showFlashAlert', {
          message: 'Update aplikasi Chrome melalui Play Store untuk melanjutkan.',
          type: 'info',
        });
      }
    });
  },
};
